import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import firebase from "firebase/app";
import { RevAppUser } from "../interfaces/rev-app-user";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userStatic: firebase.User;
  user: BehaviorSubject<firebase.User | null> = new BehaviorSubject<firebase.User | null>(
    null
  );

  revAppUserStatic: RevAppUser;
  revAppUser: BehaviorSubject<RevAppUser | null> = new BehaviorSubject<RevAppUser | null>(
    null
  );

  constructor() {}

  setUser(user: firebase.User) {
    this.user.next(user);
    this.userStatic = user;
  }

  getUser(): Observable<firebase.User | null> {
    return this.user.asObservable();
  }

  getUserStatic(): firebase.User {
    return this.userStatic;
  }

  setRevAppUser(revAppUser: RevAppUser) {
    this.revAppUser.next(revAppUser);
    this.revAppUserStatic = revAppUser;
  }

  getRevAppUser(): Observable<RevAppUser | null> {
    return this.revAppUser.asObservable();
  }

  getRevAppUserStatic(): RevAppUser {
    return this.revAppUserStatic;
  }
}
