<div class="pricing-container">
  <div
    *ngIf="!prevListings.length"
    class="no-previous-listing-container text-center pb-150"
  >
    <p class="msg font-montserrat-extrabold">
      You don’t have any properties yet.
    </p>
    <div class="img-container-bg"></div>
    <p class="msg font-montserrat-extrabold">Start importing your properties</p>
  </div>

  <ng-container *ngIf="prevListings.length">
    <section class="section-area section-area-1 pb-200 pt-50">
      <div class="inner-area inner-area-1 wide-center-container">
        <div class="box box-1 n-mx-15 display-flex flex-wrap">
          <div
            class="outer-property-container px-15"
            *ngFor="let item of prevListings"
          >
            <revapp-property-item [user]="user" [propertyItem]="item">
            </revapp-property-item>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
