import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PricingResult } from "functions/src/interfaces/PricingResultInterface";
import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { CommonDialogType } from "src/app/components/common-dialog/common-dialog.interface";
import { CommonDialogService } from "src/app/components/common-dialog/common-dialog.service";
import { calcDaysDiff } from "src/app/constants/utils";
import { IDateLimit } from "src/app/interfaces/calendar-model";
import { 
  DateAndPriceParameters, 
  DateAndPriceParametersClass 
} from "src/app/interfaces/date-and-price-parameters";
import { PropertyCRUDService } from "src/app/services/property-crud.service";
import {
  PropertyAttributes,
  PropertyAttributesClass,
} from "src/app/interfaces/property-attributes";
import {
  FormService,
  GenericDataSavingStatus,
  PricingStatus,
} from "src/app/services/form.service";
import { StateService } from "src/app/services/state.service";
import { UserService } from "src/app/services/user.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { WebHotelierInputDialogSyncComponent } from "../home/web-hotelier-input-dialog-sync/web-hotelier-input-dialog-sync.component";
import { WebHotelierInputDialogAutoSyncComponent } from "../home/web-hotelier-input-dialog-autosync/web-hotelier-input-dialog-autosync.component";
import { PriceTiersAdvancedMenuComponent } from "../check-prices/price-tiers-advanced-menu/price-tiers-advanced-menu.component"
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { RevAppUser } from "src/app/interfaces/rev-app-user";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "revapp-check-prices",
  templateUrl: "./check-prices.component.html",
  styleUrls: ["./check-prices.component.scss"],
})
export class CheckPricesComponent implements OnInit, OnDestroy {
  public algorithmStatus$: Observable<GenericDataSavingStatus> = this.formService.getBasePriceStatus();
  public calculationStatus$: Observable<PricingStatus> = this.formService.getAlgorithmStatus();
  propertyItem: PropertyAttributes = new PropertyAttributesClass();
  activePropertyItem: Observable<PropertyAttributes> = this._stateService.getActivePropertyAttributes();
  currentDateAndPriceParams: DateAndPriceParametersClass = new DateAndPriceParametersClass();
  algorithmRunParamsMatch: boolean = false;
  currenciesList = [
    { value: "AUD", label: "AUD" },
    { value: "CAD", label: "CAD" },
    { value: "CHF", label: "CHF" },
    { value: "CNY", label: "CNY" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
    { value: "INR", label: "INR" },
    { value: "JPY", label: "JPY" },
    { value: "MXN", label: "MXN" },
    { value: "RUB", label: "RUB" },
    { value: "SAR", label: "SAR" },
    { value: "SEK", label: "SEK" },
    { value: "SGD", label: "SGD" },
    { value: "USD", label: "USD" },
  ];
  riskStrategiesList = [
    { value: "0.2", label: "Highly aggresive" },
    { value: "0.1", label: "Moderately aggresive" },
    { value: "0", label: "Neutral" },
    { value: "-0.1", label: "Moderately conservative" },
    { value: "-0.2", label: "Highly conservative" },
  ];
  tierPriceStrategiesList = [
    { value: "none", label: "None", title: "No adjustment based on the occupancy level of the property will be applied" },
    { value: "default", label: "RevApp Mode", title: "The final price will be adjusted based on the occupancy level of the property per RevApp suggestion" },
    { value: "advanced", label: "User Mode", title: "The final price will be adjusted based on the occupancy level of the property. User will choose the number of tiers and the adjustments per tier" },
  ];
  checkPricesForm: FormGroup;
  dateLimits: IDateLimit = null;
  destroy$ = new Subject<void>();
  propHasExtRateId: boolean = false;

  propHasActiveResult: Observable<boolean> = this._stateService
    .getActiveResults()
    .pipe(
      map(
        (pricingResult: PricingResult) =>
          pricingResult && Object.keys(pricingResult).length > 0
      )
    );

  algorithmSuggestedBasePrice$: Observable<number> = this._stateService.getActiveSuggestedBasePrice();

  algoBasePrice: boolean = false;
  advancedPriceAlgoStrategies: boolean = false;
  tierPriceStrategyEnabled: boolean = false;
  tierPriceStrategySelection: string = "none";
  tierPriceStrategyPercentAbsolute: boolean = false;
  tierPriceStrategyArray: Array<number> = [];
  autoSyncEnabled: boolean = false;
  user: RevAppUser;

  constructor(
    private _crudSrv: PropertyCRUDService,
    private _router: Router,
    private _fb: FormBuilder,
    private formService: FormService,
    private _stateService: StateService,
    private _userService: UserService,
    private _commonDialogSrv: CommonDialogService,
    public dialog: MatDialog
  ) {
    this.user = this._userService.getRevAppUserStatic();
  }

  ngOnInit(): void {
    this._initForm();
    this._initSubscriptions();
    this.propertyItem = this._stateService.getActivePropertyAttributesStatic();
    this.propHasExtRateId = !(
      this.propertyItem.extRateId === undefined ||
      this.propertyItem.extRateId === null ||
      this.propertyItem.extRateId === ""
    );
    this.autoSyncEnabled = this.propertyItem.autosync && this.propertyItem.autosync.status;
    if (!this.propertyItem.numberOfRooms) {
      this.checkPricesForm.get("tierPriceControl").disable();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._stateService.setActiveResults(null);
    this._stateService.setActiveSuggestedBasePrice(null);
  }

  private _initForm(): void {
    this.checkPricesForm = this._fb.group({
      basePriceControl: [null],
      currencyControl: [null, [Validators.required]],
      minimumPriceControl: [null, [Validators.required, Validators.min(3)]],
      maximumPriceControl: [null, [Validators.required]],
      startDateControl: [null, [Validators.required]],
      endDateControl: [null, [Validators.required]],
      strategyControl: [null],
      tierPriceControl: [null],
    });
    this.checkPricesForm.get("currencyControl").patchValue("EUR");
    this.checkPricesForm.get("currencyControl").disable();
    this.checkPricesForm.get("strategyControl").patchValue("0");
    this.checkPricesForm.get("tierPriceControl").patchValue("none");
  }

  private _initSubscriptions(): void {
    this._stateService
      .getActiveResults()
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe((pricingResult: PricingResult) => {
        this.dateLimits = {
          fromDate: this.checkPricesForm.get("startDateControl").value as Date,
          toDate: this.checkPricesForm.get("endDateControl").value as Date,
          pricingResult,
          basePrice: this.currentDateAndPriceParams.basePrice,
          currency: this.checkPricesForm.get("currencyControl").value,
        };
        const calendarContainerElement = document.querySelector(
          ".calendar-outer-container"
        ) as HTMLElement;
        calendarContainerElement.scrollIntoView({ behavior: "smooth" });
        this.dateAndPriceParametersChanges();
      });

    this._stateService
      .getActiveCustomResults()
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe((customPricingResult: PricingResult) => {
        this.dateLimits = {
          ...this.dateLimits,
          customPricingResult,
        };
      });

      this._stateService
      .getActiveSuggestedBasePrice()
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe((suggerestedBasePrice: number) => {
        this.dateAndPriceParametersChanges();
      });

      this._stateService
      .getActivePropertyAttributes()
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe((propertyAttributes: PropertyAttributes) => {
        this.propertyItem = propertyAttributes;
        this.autoSyncEnabled = propertyAttributes.autosync && propertyAttributes.autosync.status;
      });
  }
  
  public dateAndPriceParametersChanges(){

    if(this.checkPricesForm.invalid)
      return;

    const dateAndPriceParameters = this.constructDateAndPriceParameters();
    this.algorithmRunParamsMatch = (   (dateAndPriceParameters.basePrice    == this.currentDateAndPriceParams.basePrice)
                                    && (dateAndPriceParameters.minimumPrice === this.currentDateAndPriceParams.minimumPrice)
                                    && (dateAndPriceParameters.maximumPrice === this.currentDateAndPriceParams.maximumPrice)
                                    && (dateAndPriceParameters.startDate    === this.currentDateAndPriceParams.startDate)
                                    && (dateAndPriceParameters.endDate      === this.currentDateAndPriceParams.endDate)
                                    && (dateAndPriceParameters.algoRiskStrategy === this.currentDateAndPriceParams.algoRiskStrategy)
                                    && (dateAndPriceParameters.tierPriceStrategyEnabled === this.currentDateAndPriceParams.tierPriceStrategyEnabled)
                                    && (dateAndPriceParameters.tierPriceStrategySelection === this.currentDateAndPriceParams.tierPriceStrategySelection)
                                    && (dateAndPriceParameters.tierPriceStrategyPercentAbsolute === this.currentDateAndPriceParams.tierPriceStrategyPercentAbsolute)
                                    && (dateAndPriceParameters.tierPriceStrategyArray.length === this.currentDateAndPriceParams.tierPriceStrategyArray.length)
                                    && ((dateAndPriceParameters.tierPriceStrategyArray.length > 0 
                                        && dateAndPriceParameters.tierPriceStrategyArray.every((val, idx) => val === this.currentDateAndPriceParams.tierPriceStrategyArray[idx])))
                                    );
  }

  private constructDateAndPriceParameters(): DateAndPriceParametersClass {
    const startDateVal = this.checkPricesForm.get("startDateControl").value
    const endDateVal = this.checkPricesForm.get("endDateControl").value

    let displayPrecision = this._userService.getRevAppUserStatic().displayPrecision
    if(displayPrecision === null || displayPrecision === undefined)
    {
      displayPrecision = 2
    }
    
    let currentTierPriceStrategyEnabled = false;
    let currentTierPriceStrategyPercentAbsolute = true;
    let currentTierPriceStrategyArray = [];

    if(this.tierPriceStrategySelection === "default") {
      currentTierPriceStrategyEnabled = true;
      currentTierPriceStrategyPercentAbsolute = true;
      currentTierPriceStrategyArray = [0, 0.1, 0.2, 0.3 ];
    } else if(this.tierPriceStrategySelection === "advanced") {
      currentTierPriceStrategyEnabled = this.tierPriceStrategyEnabled;
      currentTierPriceStrategyPercentAbsolute = this.tierPriceStrategyPercentAbsolute;
      currentTierPriceStrategyArray = this.tierPriceStrategyArray;
    }

    let dateAndPriceParameters = new DateAndPriceParametersClass();
    dateAndPriceParameters = {
      ...dateAndPriceParameters,
      isAlgoBasePrice: this.algoBasePrice,
      basePrice:
        this.algoBasePrice &&
        this._stateService.getActiveSuggestedBasePriceStatic()
          ? this._stateService.getActiveSuggestedBasePriceStatic()
          : this.checkPricesForm.get("basePriceControl").value,
      minimumPrice: this.checkPricesForm.get("minimumPriceControl").value,
      maximumPrice: this.checkPricesForm.get("maximumPriceControl").value,
      singleDay: 
        startDateVal.getTime() === endDateVal.getTime(),
      startDate: (startDateVal instanceof Date)? startDateVal.toDateString():startDateVal,
      endDate: (endDateVal instanceof Date)? endDateVal.toDateString():endDateVal,
      oneDate:
        startDateVal.getTime() === endDateVal.getTime()
          ? this.checkPricesForm.get("endDateControl").value
          : null,
      numberOfDays:
        startDateVal.getTime() === endDateVal.getTime()
          ? 1
          : calcDaysDiff(startDateVal as Date, endDateVal as Date) + 1,
      displayPrecision: displayPrecision,
      algoRiskStrategy: this.advancedPriceAlgoStrategies ? this.checkPricesForm.get("strategyControl").value : 0,
      tierPriceStrategyEnabled: this.advancedPriceAlgoStrategies ? currentTierPriceStrategyEnabled : false, 
      tierPriceStrategySelection: this.advancedPriceAlgoStrategies ? this.tierPriceStrategySelection : "none", 
      tierPriceStrategyPercentAbsolute: this.advancedPriceAlgoStrategies ? currentTierPriceStrategyPercentAbsolute : false, 
      tierPriceStrategyArray: this.advancedPriceAlgoStrategies ? Object.assign([], currentTierPriceStrategyArray) : [], 
    };

    console.log("|-- tierPriceStrategy Enabled [", currentTierPriceStrategyEnabled, "] -- ", (currentTierPriceStrategyPercentAbsolute ? "Percent":"Absolute"), "-- Array --|> ", currentTierPriceStrategyArray);

    if (dateAndPriceParameters.oneDate) {
      dateAndPriceParameters.oneDate = new Date(dateAndPriceParameters.oneDate);
      dateAndPriceParameters.oneDate = this.getFormattedDate(
        dateAndPriceParameters.oneDate
      );
    }
    if (dateAndPriceParameters.startDate) {
      dateAndPriceParameters.startDate = new Date(
        dateAndPriceParameters.startDate
      );
      dateAndPriceParameters.startDate = this.getFormattedDate(
        dateAndPriceParameters.startDate
      );
    }
    if (dateAndPriceParameters.endDate) {
      dateAndPriceParameters.endDate = new Date(dateAndPriceParameters.endDate);
      dateAndPriceParameters.endDate = this.getFormattedDate(
        dateAndPriceParameters.endDate
      );
    }
    return dateAndPriceParameters;
  }

  public onCheckDateDiff(): void {
    const { startDateControl, endDateControl } = this.checkPricesForm.controls;

    const dateFrom = startDateControl.value as Date;
    const dateTo = endDateControl.value as Date;
    const d = new Date();
    const today = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);

    if (dateTo && dateFrom && today > dateFrom) {
      startDateControl.setErrors({ datePastError: true });
      this._commonDialogSrv.open(
        CommonDialogType.WARNING,
        "Warning",
        "Start date cannot be in the past!"
      );
      return;
    }

    if (dateTo && dateFrom && dateTo < dateFrom) {
      startDateControl.setErrors({ dateLessThanError: true });
      endDateControl.setErrors({ dateLessThanError: true });
      this._commonDialogSrv.open(
        CommonDialogType.WARNING,
        "Warning",
        "Start date cannot be greater than End date!"
      );
      return;
    }

    if (calcDaysDiff(dateFrom, dateTo) > 365) {
      startDateControl.setErrors({ dateDiff: true });
      endDateControl.setErrors({ dateDiff: true });
      this._commonDialogSrv.open(
        CommonDialogType.WARNING,
        "Warning",
        "The number of days between Start date and End date cannot be more than 365!"
      );
      return;
    }

    if (dateTo && dateFrom && dateTo > dateFrom) {
      startDateControl.setErrors(null);
      endDateControl.setErrors(null);
      this.dateAndPriceParametersChanges();
      return;
    }
  }

  public onFormSubmit(): void {
    const dateAndPriceParameters:DateAndPriceParameters = this.constructDateAndPriceParameters();

    this.currentDateAndPriceParams = dateAndPriceParameters;
    this._stateService.setActiveCheckPriceParameters(dateAndPriceParameters);
    console.log("|-- dateAndPriceParameters --|", dateAndPriceParameters);
    this.formService.saveDateAndPriceParametersToFirebaseAndRunPricingAlgo(
      dateAndPriceParameters,
      this.propertyItem.propertyId,
      this.propertyItem.userId
    );
  }

  useBasePrice(event: MatSlideToggleChange) {
    this.algoBasePrice = event.checked;
    if (event.checked) {
      this.proposeBasePrice();
    }
    this.dateAndPriceParametersChanges();
  }

  useAdvancedStrategies(event: MatSlideToggleChange) {
    this.advancedPriceAlgoStrategies = event.checked;
    this.dateAndPriceParametersChanges();
  }

  tierPriceStrategiesSelectionChanged(event: MatSelectChange) {
    this.tierPriceStrategiesChanged(event.value);
  }

  tierPriceStrategiesSelectionClicked(selected: boolean) {
    this.tierPriceStrategySelection  = this.checkPricesForm.get("tierPriceControl").value;
    this.tierPriceStrategiesChanged(this.tierPriceStrategySelection );
  }

  tierPriceStrategiesChanged(selection: string) {
    if(selection === "advanced") {
      if(!this.tierPriceStrategyEnabled) {
        this.tierPriceStrategyEnabled = true;
        this.tierPriceStrategyPercentAbsolute = true;
        this.tierPriceStrategyArray = [0, 0, 0, 0];
      }
      this.openPriceTiersAdvancedMenuModal();
    }
    
    this.dateAndPriceParametersChanges();
  }

  autoSyncEnable(updateToggle:boolean): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.width = "500px";
    const _dialogRef = this.dialog.open(
      WebHotelierInputDialogAutoSyncComponent,
      config
    ).afterClosed()
    .subscribe(response => {
      if(updateToggle && (!response || response.data==='cancel')){
        this.autoSyncEnabled = !this.autoSyncEnabled;
      }
    });
  }

  autoSyncDisable():void {
    const dialogresult = this._commonDialogSrv.open(
      CommonDialogType.WARNING,
      "Warning",
      "You are about to disable Autosync for the property. Do you want to proceed ?"
    );
    this._commonDialogSrv.close().subscribe(confirmed => {
      if (confirmed) {  
        let newPropertyItem: PropertyAttributes = 
          {
            ...this.propertyItem,
            autosync: {
                status: false
              },
            timestamp: Date.now()
          };
          
        const message: string ='Autosync functionality has been updated!';
        this._crudSrv.saveUpdatedProperty(newPropertyItem, this.propertyItem.userId, message, true);
      } else {
        this.autoSyncEnabled = !this.autoSyncEnabled;
      }
    });
  }

  autoSyncPrice(event: MatSlideToggleChange) { 

    // do not let change until property is actually changed
    this.autoSyncEnabled = event.checked;
    if (event.checked) {
      this.autoSyncEnable(true);
    } else {
      this.autoSyncDisable();
    }
  }

  public proposeBasePrice(): void {
    const dateAndPriceParameters = this.constructDateAndPriceParameters();

    console.log("|-- dateAndPriceParameters --|", dateAndPriceParameters);
    this.formService.runPricingAlgoToSuggestBasePrice(
      dateAndPriceParameters,
      this.propertyItem.propertyId,
      this.propertyItem.userId
    );
  }

  public goToListPage(): void {
    this._router.navigate(["/home"]);
  }

  openWebhotelierRateSyncModal(): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.width = "500px";
    const _dialogRef = this.dialog.open(
      WebHotelierInputDialogSyncComponent,
      config
    );
  }

  openPriceTiersAdvancedMenuModal(): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.width = "500px";
    config.data = {
      tierPricePercentAbsolute: this.tierPriceStrategyPercentAbsolute,
      selectedTierValues: this.tierPriceStrategyArray,
    };
    const _dialogRef = this.dialog.open(
      PriceTiersAdvancedMenuComponent,
      config
    ).afterClosed()
    .subscribe(response => {
      if(response !== undefined && response.data.status === 'success'){
        this.tierPriceStrategyEnabled = response.data.tierPriceStrategyEnabled;
        this.tierPriceStrategyArray = response.data.tierPriceStrategyArray;
        this.tierPriceStrategyPercentAbsolute = response.data.tierPricePercentAbsolute;
        this.dateAndPriceParametersChanges();
      }
    });
  }

  updateAutosyncParameters(): void {
    const dialogresult = this._commonDialogSrv.open(
      CommonDialogType.WARNING,
      "Warning",
      "You are about to update the Autosync parameters (period, min/max/base prices) for the property. Do you want to proceed ?"
    );
    this._commonDialogSrv.close().subscribe(confirmed => {
      if (confirmed) {  
        this.autoSyncEnable(false);
      } 
    })
  }

  syncButtonClicked(): void {
    if (this.autoSyncEnabled) {
      this.updateAutosyncParameters();
    } else {
      this.openWebhotelierRateSyncModal();
    }
  }

  getFormattedDate(date: Date): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
}
