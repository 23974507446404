<mat-form-field appearance="fill">
  <mat-label>{{ name }}</mat-label>
  <mat-chip-list #chipList [attr.aria-label]="name">
    <mat-chip
      *ngFor="let item of selectedItemsArray"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(item)"
    >
      {{ item }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      [formControl]="myControl"
      [matAutocomplete]="auto"
      placeholder="Enter new value"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="addAutocomplete($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-chip-list>
</mat-form-field>
