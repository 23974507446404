<div class="grid-container">
  <div class="list grid-item">
    <revapp-rev-list
      [user]="user$ | async"
      [previousListings]="allProperties$ | async"
    ></revapp-rev-list>
  </div>
</div>

<section class="section-area import-properties-section-area">
  <div class="inner-area inner-area-1">
    <div class="box box-1">
      <div class="import-properties-widget">
        <mat-card>
          <div class="msg-container text-center">
            <p class="font-montserrat-semibold">Add new properties</p>
          </div>
          <div class="btn-container">
            <button mat-icon-button (click)="openWebhotelierModal()">
              <fa-icon [icon]="faPlus"></fa-icon>
              <span class="btn-text">AUTOMATICALLY</span>
            </button>
            <span>or</span>
            <button mat-icon-button (click)="onNavigateToForm()">
              <fa-icon [icon]="faPlus"></fa-icon>
              <span class="btn-text">MANUALLY</span>
            </button>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</section>
