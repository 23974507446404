import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
  PropertyAttributes,
  PropertyAttributesClass,
} from "src/app/interfaces/property-attributes";
import { StateService } from "src/app/services/state.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { PropertyCRUDService } from "src/app/services/property-crud.service";
import { Observable } from "rxjs";
import { map, startWith, take } from "rxjs/operators";
import { FormControl } from "@angular/forms";

export interface CompetitorsAndNeighboringAreas {
  competitors: string[];
  neighboringAreas: string[];
}

@Component({
  selector: "revapp-competitors",
  templateUrl: "./competitors.component.html",
  styleUrls: ["./competitors.component.scss"],
})
export class CompetitorsComponent implements OnInit {
  propertyItem: PropertyAttributes = new PropertyAttributesClass();
  neighboringAreasFetchedList: Observable<
    string[]
  > = this.propertyCRUDService.loadNeighboringAreas();
  competitorsFetchedList: Observable<
    string[]
  > = this.propertyCRUDService.loadCompetitors();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  neighboringAreasArray: string[];
  competitorsArray: string[];

  params$: Observable<Params>;

  workInTheBackground: boolean = false;
  errorHappened: boolean = false;
  competitorControl = new FormControl();
  neighborsControl = new FormControl();
  constructor(
    private _router: Router,
    private _stateService: StateService,
    private propertyCRUDService: PropertyCRUDService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.propertyItem = this._stateService.getActivePropertyAttributesStatic();
    this.params$ = this.route.params;
    this.competitorsArray = (this.propertyItem.directCompetitorsList === undefined ? [] : this.propertyItem.directCompetitorsList);
    this.neighboringAreasArray = (this.propertyItem.nearbyNeighborhoodsList === undefined ? [] : this.propertyItem.nearbyNeighborhoodsList);
  }

  updateArray(array: string[], type: "comp" | "neig") {
    if (type === "comp") {
      this.competitorsArray = array;
    }
    if (type === "neig") {
      this.neighboringAreasArray = array;
    }
  }

  public onFormSubmit(propertyId: string, userId: string): void {
    this.workInTheBackground = true;
    this._stateService.setActiveCompetitorsAndNeighboringAreas({
      competitors: this.competitorsArray,
      neighboringAreas: this.neighboringAreasArray,
    });

    this.propertyCRUDService
      .addCompetitorsAndNeighborToProperty(
        {
          competitors: this.competitorsArray,
          neighboringAreas: this.neighboringAreasArray,
        },
        propertyId,
        userId
      )
      .then(() => {
        this.propertyCRUDService
          .getSingleProperty(propertyId, userId)
          .pipe(take(1))
          .subscribe(
            (res: PropertyAttributes) => {
              this._stateService.setActivePropertyAttributes(res);
              this.errorHappened = false;
              this.workInTheBackground = false;
              this._router.navigate(["/check-prices"]);
            },
            (error) => {
              this.workInTheBackground = false;
              this.errorHappened = true;
              console.log("There was an error", error);
            }
          );
      })
      .catch((error) => {
        this.workInTheBackground = false;
        this.errorHappened = true;
        console.log("There was an error", error);
      });
  }

  // add(event: MatChipInputEvent, type: "area" | "comp"): void {
  //   const input = event.input;
  //   const value = event.value;
  //   if ((value || "").trim()) {
  //     if (type === "area") {
  //       this.neighboringAreasArray.push(value.trim());
  //     }
  //     if (type === "comp") {
  //       this.competitorsArray.push(value.trim());
  //     }
  //   }
  //   if (input) {
  //     input.value = "";
  //   }
  // }

  // remove(area: string, type: "area" | "comp"): void {
  //   if (type === "comp") {
  //     const index = this.competitorsArray.indexOf(area);
  //     if (index >= 0) {
  //       this.competitorsArray.splice(index, 1);
  //     }
  //   }
  //   if (type === "area") {
  //     const index = this.neighboringAreasArray.indexOf(area);
  //     if (index >= 0) {
  //       this.neighboringAreasArray.splice(index, 1);
  //     }
  //   }
  // }
}
