import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { CommonDialogComponent } from "./common-dialog.component";
import { CommonDialogData, CommonDialogType, mapDialogCssClass } from "./common-dialog.interface";

@Injectable({
  providedIn: 'root'
})
export class CommonDialogService {

  private _dialogRef: MatDialogRef<CommonDialogComponent>;

  constructor(
    private _dialog: MatDialog
  ) {}

  public open(dialogType: CommonDialogType, dialogTitle: string, dialogContent: string): void {
    const data = {
      dialogTitle,
      dialogType,
      dialogContent,
    };
    const config: MatDialogConfig<CommonDialogData> = new MatDialogConfig();
    config.data = data;
    config.panelClass = mapDialogCssClass[data.dialogType];
    config.width = '500px';
    this._dialogRef = this._dialog.open(CommonDialogComponent, config);
  }

  public close(): Observable<any> {
    return this._dialogRef.afterClosed().pipe(take(1));
  }

}
