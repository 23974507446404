import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

interface Question {
  title: string;
  answerPart1: string;
  answerPart2?: string;
  answerPart3?: string;
  answerPart4?: string;
}

@Component({
  selector: 'revapp-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpCenterComponent implements OnInit {
  questionsLeft: Question[] = [];
  questionsRight: Question[] = [];
  questions: Question[] = [
    {
      title: 'I run a vacation rental company, will RevApp work for me?',
      answerPart1: 'Yes, RevApp is designed to help small and medium sized hotel owners, as well as vacation rental managers to make the best out of their room pricing!'
    },
    {
      title: 'How can I know if RevApp is the right tool for me?',
      answerPart1: 'Wondering, is what brings us closer! For you to familiarize yourself and see RevApp’s credibility  we offer free-trials! Just contact us directly!'
    },
    {
      title: 'I am not tech savvy and do not feel comfortable with playing around with my PMS and Channel Management in order to do the RevApp connection.',
      answerPart1: 'There is no need you do that. We take care of the connections. It is an utterly hassle-free process!'
    },
    {
      title: 'I know that RevApp does the best it can to suggest optimal prices, but can I interfere and manually change prices?',
      answerPart1: 'Yes you can! When we suggest our price we offer you the chance to edit it and make it equal to as much as you want.'
    },
    {
      title: 'Can I change the base, minimum and maximum prices for every month?',
      answerPart1: 'Yes, you can adjust these numbers at your convenience.'
    },
    {
      title: 'Why is RevApp so different from other pricing tools?',
      answerPart1: `Actually for a couple of reasons:`,
      answerPart2: `∙ RevApp has been designed by hoteliers for hoteliers! This gives us a competitive advantage of knowing exactly the pain points of hotel owners and vacation rental operators. Having that in mind we designed a flawless pricing tool, including only the variables which really matter.`,
      answerPart3:`∙ The team consists, apart from industry experts, by data analytics scientists and a university professor who has dedicated his research on pricing optimization.`,
      answerPart4:` ∙ Apart from the factors that we take into account for pricing every room, we have also weighted the user’s behavior. For example, if a user is more conservative with his pricing strategy, we take that into consideration and accordingly adapt our model to reflect his strategy.`
    },
    {
      title: 'What is base price and how does it work?',
      answerPart1: 'Think of base price as the foundations of your property. First you set the foundations and then you start building new floors. Thus, base price is the base that a hotel owner or vacation rental operator starts her/his pricing and then amends it according to the movements of the market. In our case, we do it for you, RevApp uses this base price as a starting point and applies any positive or negative impact the market has.'
    },
    {
      title: 'What are minimum and maximum prices for?',
      answerPart1: `All hoteliers and vacation rental managers have a breakeven price and a maximum price that they feel  comfortable with. Only you know the exact operating expenses your rooms have, for this you do not want to sell a room at a loss because the guest consumes too much heat, for example. This is why you set a minimum limit that you are willing to sell your room (minimum price).`,
      answerPart2:`At the same time, we want to make you feel comfortable with the highest offered prices. The tool reads the whole market and the competitors and might recommend a price outside of your comfort zone. For avoiding this, we ask you to tell us what your upper limit is (maximum price).
      If the calculated price from our tool is below your minimum price, then the suggested price will be equal to your minimum price. Similarly, if the calculated price from our tool is above your maximum price, then the suggested price will be equal to your maximum price.`
    },
  ];
  constructor() { }

  ngOnInit(): void {
    const index = Math.ceil(this.questions.length / 2);
    this.questionsLeft = this.questions.slice(0, index);
    this.questionsRight = this.questions.slice(index);
  }

}
