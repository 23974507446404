export interface CommonDialogData {
  dialogTitle: string;
  dialogType?: string;
  dialogContent: string;
}

export enum CommonDialogType {
  SUCCESS = 'Success',
  WARNING = 'Warning',
  INFO = 'Info',
  DEFAULT = 'Default'
}

export const mapDialogCssClass = {
  'Success': 'success-dialog',
  'Warning': 'warning-dialog',
  'Info': 'info-dialog'
}