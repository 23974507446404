import { Component } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { UserService } from "./services/user.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";
import { AngularFirestore } from "@angular/fire/firestore";
import { RevAppUser } from "./interfaces/rev-app-user";
import { NotificationService } from "./services/notification.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  revAppUserSubscription: Subscription;

  constructor(
    public fauth: AngularFireAuth,
    private router: Router,
    private userService: UserService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private notificationService: NotificationService,
    private domSanitizer: DomSanitizer,
    private afs: AngularFirestore
  ) {
    fauth.onAuthStateChanged((user) => {
      this.userService.setUser(user);
      if (user) {
        if (this.revAppUserSubscription) {
          this.revAppUserSubscription.unsubscribe();
        }

        if (user.emailVerified) {
          this.revAppUserSubscription = this.afs
            .collection("userData")
            .doc(user.uid)
            .get()
            .subscribe((res) => {
              if (res.exists && res.data()) {
                this.userService.setRevAppUser(res.data() as RevAppUser);
              }
            });
        } else {
          this.notificationService.setNotification(
            "We have sent a confirmation email to your email address.\nPlease check your emails and click on the link to verify your email address."
          );
        }
      } else {
        if (this.revAppUserSubscription) {
          this.revAppUserSubscription.unsubscribe();
        }
      }
      this.dialog.closeAll();
      if (user) {
        this.router.navigate(["/home"]);
      }
    });

    this.matIconRegistry.addSvgIcon(
      `linkedin`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/linkedin.svg`)
    );
  }
}
