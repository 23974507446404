<div class="calendar-outer-container">
  <div class="calendar-inner-container">
    <div
      *ngIf="(algorithmStatus$ | async) !== 'ResultsReceivedSuccessfully'"
      class="result-not-ready"
    >
      <ng-container *ngIf="(algorithmStatus$ | async) === 'Inactive'">
        <p>Try putting some values in the form and click Calculate</p>
      </ng-container>
      <ng-container
        *ngIf="
          (algorithmStatus$ | async) === 'SendingDataToFirebase' ||
          (algorithmStatus$ | async) === 'SendingDataToAlgorithm'
        "
      >
        <p>Please wait</p>
        <mat-spinner diameter="48"></mat-spinner>
      </ng-container>
      <ng-container
        *ngIf="
          (algorithmStatus$ | async) === 'ErrorWritingData' ||
          (algorithmStatus$ | async) === 'ErrorReceivingResults'
        "
      >
        <p>There was an error, please try again</p>
      </ng-container>
    </div>

    <ng-container
      *ngIf="(algorithmStatus$ | async) === 'ResultsReceivedSuccessfully'"
    >
      <div class="calendar-week-days">
        <div
          *ngFor="let weekDay of weekDaysShort"
          class="calendar-week-day-name"
        >
          {{ weekDay }}
        </div>
      </div>
      <div class="calendar-container">
        <article
          class="calendar-block"
          *ngFor="let block of calendarBlocks; trackBy: monthId"
        >
          <h1 class="calendar-month-title">
            {{ monthNames[+block.month] }}, {{ block.year }}
          </h1>
          <ul class="calendar-days-container clearfix">
            <li
              *ngFor="let emptyDays of block.emptyDays"
              class="calendar-day-empty"
            ></li>
            <li
              class="calendar-day"
              *ngFor="let dayObj of block.days; trackBy: dayId"
              [class.selected]="
                [dayObj.day, block.month, block.year].join('-') === selectedDay
              "
              [class.changed]="dayObj.changed"
              (click)="onShowCalendarDialog({ block: block, dayObj: dayObj })"
            >
              <div class="inner-wrapper">
                <p class="date-number">{{ dayObj.day }}</p>
                <p class="date-price">
                  <ng-container
                    [ngSwitch]="displayPrecision"
                  >
                    <ng-container *ngSwitchCase="0">
                      {{
                        dayObj.price
                          | currency: selectedCurrency:"symbol-narrow":"1.0-0"
                      }}
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                      {{
                        dayObj.price
                          | currency: selectedCurrency:"symbol-narrow":"1.1-1"
                      }}
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                      {{
                        dayObj.price
                          | currency: selectedCurrency:"symbol-narrow":"1.2-2"
                      }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{
                        dayObj.price
                          | currency: selectedCurrency:"symbol-narrow":"1.2-2"
                      }}
                    </ng-container>
                  </ng-container>
                </p>
              </div>
            </li>
          </ul>
        </article>
      </div>
    </ng-container>
  </div>
</div>
