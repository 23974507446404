<ul>
  <revapp-user-item
    *ngFor="let user of users$ | async"
    [user]="user"
    [roles]="roles"
    [displayPrecisionList]="displayPrecisionList"
    (roleChanged)="roleChanged($event)"
    (precisionChanged)="precisionChanged($event)"
  ></revapp-user-item>
</ul>
