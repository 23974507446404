export interface DateAndPriceParameters {
  isAlgoBasePrice: boolean | null; //??
  basePrice: number | null; //??
  minimumPrice: number | null; //??
  maximumPrice: number | null; //??
  singleDay: boolean | null; //??
  weekResults: boolean | null; //??
  monthResults: boolean | null; //??
  startDate: string | null | Date; //??
  endDate: string | null | Date; //??
  oneDate: string | null | Date; //??
  numberOfDays: number | null; //??
  timestampOfRequest: number | null;
  displayPrecision: number | null;
  algoRiskStrategy: number | null;
  tierPriceStrategyEnabled: boolean | null;
  tierPriceStrategySelection: string | null;
  tierPriceStrategyPercentAbsolute: boolean | null;
  tierPriceStrategyArray: Array<number>;
}

export class DateAndPriceParametersClass implements DateAndPriceParameters {
  isAlgoBasePrice: boolean | null; //??
  basePrice: number | null; //??
  minimumPrice: number | null; //??
  maximumPrice: number | null; //??
  singleDay: boolean | null; //??
  weekResults: boolean | null; //??
  monthResults: boolean | null; //??
  startDate: string | null | Date; //??
  endDate: string | null | Date; //??
  oneDate: string | null | Date; //??
  numberOfDays: number | null; //??
  timestampOfRequest: number | null;
  displayPrecision: number | null;
  algoRiskStrategy: number | null;
  tierPriceStrategyEnabled: boolean | null;
  tierPriceStrategySelection: string | null;
  tierPriceStrategyPercentAbsolute: boolean | null;
  tierPriceStrategyArray: Array<number>;
}
