<div class="full-blog">
  <div class="product-sum-widget">
    <h1>Blog</h1>
  </div>
  <div class="blog-roll" *ngIf="blogPosts | async">
    <ng-container *ngFor="let post of blogPosts | async">
      <revapp-blog-list-item [post]="post"></revapp-blog-list-item>
    </ng-container>
  </div>
</div>
