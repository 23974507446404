import { Component, Inject, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { getCurrencySymbol } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WebhotelierService } from "src/app/services/webhotelier.service";
import { PricingResult, PricingResultClass } from "functions/src/interfaces/PricingResultInterface";
import { AngularFirestore } from "@angular/fire/firestore";
import { StateService } from "src/app/services/state.service";
import { filter, map } from "rxjs/operators";
import { PropertyAttributes, PropertyAttributesClass } from "src/app/interfaces/property-attributes";
import { PropertyCRUDService } from "src/app/services/property-crud.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { FormService } from "src/app/services/form.service";

@Component({
  selector: "revapp-web-hotelier-input-dialog",
  templateUrl: "./web-hotelier-input-dialog-sync.component.html",
  styleUrls: ["./web-hotelier-input-dialog-sync.component.scss"],
})
export class WebHotelierInputDialogSyncComponent implements OnInit {
  webHotelierCredForm: FormGroup;
  webHotelierSyncForm: FormGroup;
  webHotelierBaseRoomForm: FormGroup;
  allProperties$: Observable<PropertyAttributes[]>;
  selectedUserProperties: PropertyAttributes[] = null;
  previousSelectedUserProperties: PropertyAttributes[] = null;
  error: boolean = false;
  loading: boolean = false;
  propertyItem: PropertyAttributes = new PropertyAttributesClass();
  useAsBase: boolean = false;
  syncPropertyName: string;
  selectedCurrency = "EUR";
  currencySymbol = "";

  constructor(
    public afs: AngularFirestore,
    public dialogRef: MatDialogRef<WebHotelierInputDialogSyncComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private _fb: FormBuilder,
    private _stateService: StateService,
    private _propertyCRUDService: PropertyCRUDService,
    private formService: FormService,
    private webhotelierService: WebhotelierService
  ) { }

  ngOnInit(): void {
    this.propertyItem = this._stateService.getActivePropertyAttributesStatic();
    this.allProperties$ = this._propertyCRUDService.getAllProperties().pipe(
      map(properties => properties.filter(property => property.propertyId !== this.propertyItem.propertyId))
    )
    this.webHotelierCredForm = this._fb.group({
      username: [this.propertyItem.propertyUser, [Validators.required]],
      password: [this.propertyItem.propertyPass, [Validators.required]]
    });
    this.webHotelierSyncForm = this._fb.group({
      onePaxPriceAdjustment: [this.propertyItem.onePaxPriceAdjustment, [Validators.max(0), Validators.required]],
      extraAdult: [this.propertyItem.extraAdult, [Validators.min(0), Validators.required]],
      extraChild: [this.propertyItem.extraChild, [Validators.min(0), Validators.required]],
    });
    this.webHotelierBaseRoomForm = this._fb.group(
      {
        baseRoomCheck: [null, [Validators.required]],
        baseRoomAdjustmentType: ["absolute", [Validators.required]],
        selectedUserPropertiesControl: [null]
      }
    )
    this.selectedUserProperties = new Array(0);
    this.previousSelectedUserProperties = new Array(0);
    this.useAsBase = (this.propertyItem.useAsBaseRoomPropertyId.length > 0);
    this.webHotelierBaseRoomForm.controls["baseRoomCheck"].setValue(this.useAsBase?1:0);
    this.webHotelierBaseRoomForm.controls["baseRoomAdjustmentType"].setValue(this.propertyItem.useAsBaseRoomPriceAdjustmeType === undefined || this.propertyItem.useAsBaseRoomPriceAdjustmeType === null || this.propertyItem.useAsBaseRoomPriceAdjustmeType ? "absolute" : "percentage");
    this.currencySymbol = getCurrencySymbol(this.selectedCurrency, "narrow");

    this.allProperties$.pipe(
      map(properties => properties.filter(property => this.propertyItem.useAsBaseRoomPropertyId.indexOf(property.propertyId)>-1))
    ).subscribe(
      (res: PropertyAttributes[]) => {
        this.selectedUserProperties = res;

        for (var i = 0; i < this.propertyItem.useAsBaseRoomPropertyId.length; ++i) {
          const val = Number(this.propertyItem.useAsBaseRoomPriceAdjustme[i].toFixed(this.propertyItem.useAsBaseRoomPriceAdjustmeType? 2 : 4))*(this.propertyItem.useAsBaseRoomPriceAdjustmeType ? 1 : 100);
          this.webHotelierBaseRoomForm.addControl(
            'priceAdjustment_' + this.propertyItem.useAsBaseRoomPropertyId[i],
            this._fb.control(
              { disabled: false, value: val }, Validators.required));
        }
      },
      (error) => {
        console.error("Error loading properties", error);
      }
    );
  }

  public propertyComparisonFunction = function( option: any, value: any ) : boolean {
    return option.propertyId === value.propertyId;
  }

  onPropertySelectionChange(ob) {
    let selectedUserProperties = ob.value;

    // Add controls of selected properties
    for (var i = 0; i < selectedUserProperties.length; ++i) {
      let found = false;
      for (var j = 0; j < this.previousSelectedUserProperties.length; ++j) {
        if (selectedUserProperties[i] === this.previousSelectedUserProperties[j]) {
          found = true;
        }
      }
      if (!found) {
        this.webHotelierBaseRoomForm.addControl(
          'priceAdjustment_' + this.selectedUserProperties[i].propertyId,
          this._fb.control(
            { disabled: false, value: 0 }, Validators.required));
      }
    }

    // Remove controls of unselected properties
    for (var i = 0; i < this.previousSelectedUserProperties.length; ++i) {
      let found = false;
      for (var j = 0; j < selectedUserProperties.length; ++j) {
        if (this.previousSelectedUserProperties[i] === selectedUserProperties[j]) {
          found = true;
        }
      }
      if (!found) {
        this.webHotelierBaseRoomForm.removeControl('priceAdjustment_' + this.previousSelectedUserProperties[i].propertyId);
      }
    }
    this.previousSelectedUserProperties = selectedUserProperties;
    this.selectedUserProperties = selectedUserProperties;
  }

  showBasePropertyList(event:MatCheckboxChange): void {
    if(!event.checked) {
      this.previousSelectedUserProperties = Array(0);
      this.selectedUserProperties = Array(0);
    }
  }

  resetBasePropertyListValues(ob) {
    console.log("resetBasePropertyListValues")
    for (var i = 0; i < this.selectedUserProperties.length; ++i) {
      const priceAdjustmentControlName = "priceAdjustment_" + this.selectedUserProperties[i].propertyId;
      const priceAdjustment = this.webHotelierBaseRoomForm.get(priceAdjustmentControlName).setValue(0);
    }
  }

  submit() {
    if (!this.webHotelierBaseRoomForm.get("baseRoomCheck").value) {
      return this.submitSingle();
    } else {
      return this.submitMulti();
    }
  }

  submitSingle() {

    this.syncPropertyName = this.propertyItem.name
    this.loading = true;
    let pricingResultMerged: PricingResult = {
      ...this._stateService.getActiveResultsStatic(),
      ...this._stateService.getActiveCustomResultsStatic()
    };

    this.webhotelierService
      .setPropertyRoomRatesWebHotelier({
        username: this.webHotelierCredForm.get("username").value,
        password: this.webHotelierCredForm.get("password").value,
        extRateId: this.propertyItem.extRateId,
        minNumberOfGuests: this.propertyItem.minNumberOfGuests,
        onePaxPriceAdjustment: this.webHotelierSyncForm.get("onePaxPriceAdjustment").value,
        extra_adult: this.webHotelierSyncForm.get("extraAdult").value,
        extra_child: this.webHotelierSyncForm.get("extraChild").value,
        pricingResult: pricingResultMerged,
      },
      true)
      .then((res) => {
        this.loading = false;
        let newPropertyItem: PropertyAttributes = new PropertyAttributesClass();
        newPropertyItem = {
          ...this.propertyItem,
          propertyUser: this.webHotelierCredForm.get("username").value,
          propertyPass: this.webHotelierCredForm.get("password").value,
          onePaxPriceAdjustment: this.webHotelierSyncForm.get("onePaxPriceAdjustment").value,
          extraAdult: this.webHotelierSyncForm.get("extraAdult").value,
          extraChild: this.webHotelierSyncForm.get("extraChild").value,
          useAsBaseRoomExtRateId: [],
          useAsBaseRoomPropertyId: [],
          useAsBaseRoomMinGuests: [],
          useAsBaseRoomPriceAdjustme: [],
          useAsBaseRoomPriceAdjustmeType: null,
        }
        // Update property with the extraAdult & extraChild that user input for future use
        this.afs.collection("userData")
          .doc(this.propertyItem.userId)
          .collection("properties")
          .doc(this.propertyItem.propertyId)
          .update(newPropertyItem)
          .then((res2) => {
            this._stateService.setActivePropertyAttributes(newPropertyItem);
          })
          .catch((error) => {
            console.error("Error updating property with extraAdult & extraChild", error);
          });
        if (res) {
          this.dialogRef.close();
          this.error = false;
        } else {
          this.error = true;
        }
      })
      .catch(() => {
        this.loading = false;
        this.error = true;
      });
  }

  submitMulti() {

    this.syncPropertyName = this.propertyItem.name
    this.loading = true;
    let pricingResultMerged: PricingResult = {
      ...this._stateService.getActiveResultsStatic(),
      ...this._stateService.getActiveCustomResultsStatic()
    };

    this.webhotelierService
      .setPropertyRoomRatesWebHotelier({
        username: this.webHotelierCredForm.get("username").value,
        password: this.webHotelierCredForm.get("password").value,
        extRateId: this.propertyItem.extRateId,
        minNumberOfGuests: this.propertyItem.minNumberOfGuests,
        onePaxPriceAdjustment: this.webHotelierSyncForm.get("onePaxPriceAdjustment").value,
        extra_adult: this.webHotelierSyncForm.get("extraAdult").value,
        extra_child: this.webHotelierSyncForm.get("extraChild").value,
        pricingResult: pricingResultMerged,
      },
      false)
      .then(async (res) => {
        this.loading = false;
        let newPropertyItem: PropertyAttributes = new PropertyAttributesClass();
        newPropertyItem = {
          ...this.propertyItem,
          propertyUser: this.webHotelierCredForm.get("username").value,
          propertyPass: this.webHotelierCredForm.get("password").value,
          onePaxPriceAdjustment: this.webHotelierSyncForm.get("onePaxPriceAdjustment").value,
          extraAdult: this.webHotelierSyncForm.get("extraAdult").value,
          extraChild: this.webHotelierSyncForm.get("extraChild").value,
        }
        // Clear exit
        newPropertyItem.useAsBaseRoomExtRateId= new Array(0);
        newPropertyItem.useAsBaseRoomPropertyId= new Array(0);
        newPropertyItem.useAsBaseRoomMinGuests= new Array(0);
        newPropertyItem.useAsBaseRoomPriceAdjustme= new Array(0);
        const adjustmentType = this.webHotelierBaseRoomForm.get("baseRoomAdjustmentType").value === "absolute";
        newPropertyItem.useAsBaseRoomPriceAdjustmeType = adjustmentType;

        // Populate with other properties
        for (var i = 0; i < this.selectedUserProperties.length; ++i) {
          newPropertyItem.useAsBaseRoomExtRateId.push(this.selectedUserProperties[i].extRateId);
          newPropertyItem.useAsBaseRoomPropertyId.push(this.selectedUserProperties[i].propertyId);
          newPropertyItem.useAsBaseRoomMinGuests.push(this.selectedUserProperties[i].minNumberOfGuests);
          const priceAdjustmentControlName = "priceAdjustment_" + this.selectedUserProperties[i].propertyId;
          const priceAdjustment = Number((this.webHotelierBaseRoomForm.get(priceAdjustmentControlName).value / ( adjustmentType ? 1 : 100)).toFixed(adjustmentType? 2 : 4))
          newPropertyItem.useAsBaseRoomPriceAdjustme.push(priceAdjustment);

          this.loading = true;
          this.syncPropertyName = this.selectedUserProperties[i].name;

          const pricingResultMergedAdj = this.adjustPriceResult(pricingResultMerged, priceAdjustment, adjustmentType)

          let customPricingResult:PricingResultClass = await this.formService.getCustomPricingResult(
            this.selectedUserProperties[i].propertyId,
            this.propertyItem.userId,
            pricingResultMerged
          )

          const pricingResultMergedNew = {
            ...pricingResultMergedAdj,
            ...customPricingResult
          }

          await this.webhotelierService
            .setPropertyRoomRatesWebHotelier({
              username: this.webHotelierCredForm.get("username").value,
              password: this.webHotelierCredForm.get("password").value,
              extRateId: this.selectedUserProperties[i].extRateId,
              minNumberOfGuests: this.selectedUserProperties[i].minNumberOfGuests,
              onePaxPriceAdjustment: this.webHotelierSyncForm.get("onePaxPriceAdjustment").value,
              extra_adult: this.webHotelierSyncForm.get("extraAdult").value,
              extra_child: this.webHotelierSyncForm.get("extraChild").value,
              pricingResult: pricingResultMergedNew,
          },
          (i === this.selectedUserProperties.length - 1))
          .catch((error) => {
            console.error("Error syncing prices for property " + this.selectedUserProperties[i].name, error);
            this.loading = false;
            this.error = true;
          });
        }

        // Update property with the extraAdult & extraChild that user input for future use
        this.afs.collection("userData")
          .doc(this.propertyItem.userId)
          .collection("properties")
          .doc(this.propertyItem.propertyId)
          .update(newPropertyItem)
          .then((res2) => {
            this._stateService.setActivePropertyAttributes(newPropertyItem);
          })
          .catch((error) => {
            console.error("Error updating property with extraAdult & extraChild", error);
          });
          
        if (res) {
          this.dialogRef.close();
          this.error = false;
        } else {
          this.error = true;
        }
      })
      .catch((error) => {
        console.error("Error updating property", error);
        this.loading = false;
        this.error = true;
      });
  }

  adjustPriceResult(
    priceResult: PricingResult,
    amount: number,
    type: boolean
  ) : PricingResultClass {
    let newPricingResult:PricingResult = new PricingResultClass;

    for (const date in priceResult) {
      let newPrice: number = 0.0
      if (typeof priceResult[date].price === "number") {
        newPrice = priceResult[date].price as number;
      } else {
        newPrice = parseFloat(priceResult[date].price as string);
      }

      if(type) { // type [1] - Absolute value adjustment
        newPrice = newPrice + amount;
      } else { // type [0] - percentage value adjustment on price
        newPrice = newPrice + (amount * newPrice);
      }
      newPricingResult[date] = { 
        price: newPrice , 
        order: priceResult[date].order 
      };
    }
    return newPricingResult;
  }
}
