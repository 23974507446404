import { CurrencyPipe, getCurrencySymbol } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { amountRoundToOneDecimal, amountRoundToTwoDecimals } from "src/app/constants/currencies";
import { monthNames } from "src/app/constants/monthNames";
import { PropertyCRUDService } from "src/app/services/property-crud.service";

@Component({
  selector: "revapp-calendar-dialog",
  templateUrl: "./calendar-dialog.component.html",
  styleUrls: ["./calendar-dialog.component.scss"],
  providers: [CurrencyPipe],
})
export class CalendarDialogComponent implements OnInit {
  @ViewChild("priceInput") priceInput: ElementRef;

  monthNames = monthNames;
  form: FormGroup;
  marketDynamicsPercentage = this.data.dayObj.trend * 100;
  marketDynamicsAmount = this.data.dayObj.trend * this.data.basePrice;
  
  userAdjustmentPercentage = this.data.dayObj.userPrice
    ? ((this.data.dayObj.userPrice - this.data.dayObj.suggestedPrice) /
        this.data.basePrice) *
      100
    : 0;
  userAdjustmentAmount = this.data.dayObj.userPrice
    ? this.data.dayObj.userPrice - this.data.dayObj.suggestedPrice
    : 0;

  minMaxPriceCorrectionPercentage = this.data.dayObj.correction
    ? (this.data.dayObj.correction / this.data.basePrice) * 100
    : 0;
  minMaxPriceCorrectionAmount = this.data.dayObj.correction;
    
  advancedStrategiesCorrectionPercentage = this.data.dayObj.advancedStrategies
    ? (this.data.dayObj.advancedStrategies / this.data.basePrice) * 100
    : 0;
  advancedStrategiesCorrectionAmount = this.data.dayObj.advancedStrategies;

  currencySymbol = "";

  enableUpdateButton: boolean = false;
  resetFinalPriceEnabled: boolean = false;
  displayPrecisionStr: string;

  constructor(
    private _fb: FormBuilder,
    private _currencyPipe: CurrencyPipe,
    public dialogRef: MatDialogRef<CalendarDialogComponent>,
    private propertyCrudService: PropertyCRUDService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      block: any;
      basePrice?: number;
      currency?: string;
      dayObj: any;
      propertyId: string;
      firestoreDateId: string;
      pricingResultDateId: string;
      displayPrecision: number;
    }
  ) {}

  ngOnInit(): void {
    this._initForm();
    this.currencySymbol = getCurrencySymbol(this.data.currency, "narrow");
  }

  private _initForm(): void {
    this.displayPrecisionStr = "";
    switch (this.data.displayPrecision) {
      case 0: {
        this.displayPrecisionStr = "1.0-0";
        break;
      }
      case 1: {
        this.displayPrecisionStr = "1.1-1";
        break;
      }
      case 2: 
      default:{
        this.displayPrecisionStr = "1.2-2";
        break;
      }
    }


    this.form = this._fb.group({
      finalPriceControl: [
        this._currencyPipe.transform(this.data.dayObj.price, "", "", this.displayPrecisionStr),
        Validators.required,
      ],
      editPriceControl: [false],
    });
    this.form.get("finalPriceControl").disable();
  }

  public onEditPriceChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.form.get("finalPriceControl").enable();
      const inputPrice = this.priceInput.nativeElement as HTMLInputElement;
      inputPrice.focus();
      this.enableUpdateButton = true;
    } else {
      this.form.get("finalPriceControl").disable();
    }
  }

  public onCloseModal(): void {
    this.dialogRef.close();
  }

  public onUpdateEntry(): void {
    this.dialogRef.close({
      finalPrice: this.precisionRound(parseFloat(this.form.get("finalPriceControl").value )),
      resetPrice: this.resetFinalPriceEnabled,
    });
  }

  public precisionRound(num: number): string{
    switch (this.data.displayPrecision) {
      case 0: {
        return Math.round(num).toString();
      }
      case 1: {
        return amountRoundToOneDecimal(num).toFixed(1);
      }
      case 2: 
      default:{
        return amountRoundToTwoDecimals(num).toFixed(2);
      }
    }
  }

  public resetPrice(): void {
    this.resetFinalPriceEnabled = ! this.resetFinalPriceEnabled;
    this.enableUpdateButton = true;
  }
}
