<section
  class="
    section-area section-area-1
    wide-center-container
    pt-70
    pb-200
    min-h-800
  "
>
  <div class="inner-area inner-area-1">
    <div class="box box-1">
      <div class="property-item-wrapper">
        <revapp-property-item
          [editPropertyMode]="true"
          [propertyItem]="activePropertyItem | async"
        >
        </revapp-property-item>
      </div>
    </div>
  </div>
  <div class="inner-area inner-area-2">
    <div class="box box-1 mr-25">
      <div class="form-container">
        <form [formGroup]="checkPricesForm" novalidate>
          <div class="form-row">
            <div class="form-col title-container">
              <h1 class="font-montserrat-extrabold main-title">Check Prices</h1>
            </div>
          </div>

          <!-- Base Price / Currency List -->
          <div class="form-row mb-30 prices-section">
            <div class="form-col form-col-4">
              <mat-form-field
                appearance="fill"
                *ngIf="
                  !algoBasePrice ||
                  (algorithmStatus$ | async) !== 'DataWrittenSuccessfully'
                "
              >
                <mat-label>Base Price</mat-label>
                <input
                  matInput
                  formControlName="basePriceControl"
                  type="number"
                  (input)="dateAndPriceParametersChanges()"
                />
              </mat-form-field>

              <div
                *ngIf="
                  algoBasePrice &&
                  (algorithmStatus$ | async) === 'DataWrittenSuccessfully'
                "
                class="base-price-suggestion"
              >
                <p class="top">Suggested Base Price</p>
                <p class="price">
                  {{ algorithmSuggestedBasePrice$ | async }}
                </p>
              </div>

              <mat-slide-toggle
                (change)="useBasePrice($event)"
                [disabled]="
                  !checkPricesForm.get('startDateControl').value ||
                  !checkPricesForm.get('endDateControl').value ||
                  !propertyItem.nearbyNeighborhoodsList ||
                  (propertyItem.nearbyNeighborhoodsList &&
                    propertyItem.nearbyNeighborhoodsList.length === 0) ||
                  (algorithmStatus$ | async) === 'SendingDataToFirebase' ||
                  (calculationStatus$ | async) === 'SendingDataToFirebase' ||
                  (calculationStatus$ | async) === 'SendingDataToAlgorithm'
                "
                [checked]="algoBasePrice"
                color="primary"
                class="slide-toggle"
              >
                <ng-container
                  *ngIf="(algorithmStatus$ | async) !== 'SendingDataToFirebase'"
                >
                  Check and use our suggested Base Price
                </ng-container>
                <ng-container
                  *ngIf="(algorithmStatus$ | async) === 'SendingDataToFirebase'"
                  ><div class="content-text">
                    <mat-spinner style="margin-right: 10px" diameter="18">
                    </mat-spinner>
                    <span> Please wait... </span>
                  </div>
                </ng-container>
              </mat-slide-toggle>
            </div>

            <div class="form-col form-col-4">
              <mat-form-field appearance="fill">
                <mat-label>Currency</mat-label>
                <mat-select formControlName="currencyControl" required>
                  <mat-option
                    *ngFor="let currency of currenciesList"
                    [value]="currency.value"
                  >
                    {{ currency.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- Minimum / Maximum Price -->
          <div class="form-row mb-30">
            <div class="form-col form-col-4">
              <mat-form-field appearance="fill">
                <mat-label>Minimum Price</mat-label>
                <input
                  formControlName="minimumPriceControl"
                  matInput
                  required
                  type="number"
                  maxlength="10"
                  (input)="dateAndPriceParametersChanges()"
                />
              </mat-form-field>
            </div>

            <div class="form-col form-col-4">
              <mat-form-field appearance="fill">
                <mat-label>Maximum Price</mat-label>
                <input
                  formControlName="maximumPriceControl"
                  matInput
                  required
                  type="number"
                  maxlength="10"
                  (input)="dateAndPriceParametersChanges()"
                />
              </mat-form-field>
            </div>
          </div>

          <!-- Start Date / End Date -->
          <div class="form-row mb-30">
            <div class="form-col form-col-4">
              <mat-form-field appearance="fill">
                <mat-label>Start Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="startDatePicker"
                  required
                  formControlName="startDateControl"
                  readonly
                  (click)="startDatePicker.open()"
                  (dateChange)="onCheckDateDiff()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="form-col form-col-4">
              <mat-form-field appearance="fill">
                <mat-label>End Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="endDatePicker"
                  required
                  formControlName="endDateControl"
                  readonly
                  (click)="endDatePicker.open()"
                  (dateChange)="onCheckDateDiff()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="endDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row mb-30">
            <div class="form-col form-col-4">
              <mat-slide-toggle
                [checked]="advancedPriceAlgoStrategies"
                (change)="useAdvancedStrategies($event)"
                color="primary"
                class="slide-toggle"
              >
                Advanced Settings

              </mat-slide-toggle>
            </div>
          </div>
          <div class="form-row mb-30">
            <div class="form-col form-col-4">   
              <mat-form-field *ngIf="advancedPriceAlgoStrategies" appearance="fill">
                <mat-label>Risk Strategies</mat-label>
                <mat-select 
                  formControlName="strategyControl"
                  (selectionChange)="dateAndPriceParametersChanges()"
                  title="Risk Strategies"
                  >
                  <mat-option
                    *ngFor="let strategy of riskStrategiesList"
                    [value]="strategy.value"
                  >
                    {{ strategy.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field> 
            </div>
            <div class="form-col form-col-4">
              <mat-form-field *ngIf="advancedPriceAlgoStrategies" appearance="fill">
                <mat-label>Occupancy Adjustment</mat-label>
                <mat-select 
                  formControlName="tierPriceControl" 
                  title="Occupancy Adjustment"
                  >
                  <mat-option
                    *ngFor="let strategy of tierPriceStrategiesList"
                    [value]="strategy.value"
                    #matOption (click)="tierPriceStrategiesSelectionClicked(matOption.selected)"
                    [title]="strategy.title"
                  >
                    {{ strategy.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          </div>
        </div>

          <div class="form-row mb-30">
            <div class="form-col form-col-4">
              <button
                style="width: max-content"
                class="blue-button blue-button-small-font"
                mat-icon-button
                [disabled]="
                  checkPricesForm.invalid ||
                  checkPricesForm.pristine ||
                  !propertyItem.nearbyNeighborhoodsList ||
                  (propertyItem.nearbyNeighborhoodsList &&
                    propertyItem.nearbyNeighborhoodsList.length === 0) ||
                  (checkPricesForm.get('basePriceControl').value === null &&
                    !(algorithmSuggestedBasePrice$ | async)) ||
                  (calculationStatus$ | async) === 'SendingDataToFirebase' ||
                  (calculationStatus$ | async) === 'SendingDataToAlgorithm' ||
                  algorithmRunParamsMatch
                "
                (click)="onFormSubmit()"
              >
                Calculate Suggested Final Price
              </button>
            <ng-container
                *ngIf="
                  (calculationStatus$ | async) === 'SendingDataToFirebase' ||
                  (calculationStatus$ | async) === 'SendingDataToAlgorithm'
                "
                ><div class="content-text" style="padding: 10px 10px">
                  <mat-spinner style="margin-right: 10px" diameter="18">
                  </mat-spinner>
                  <span> Please wait... </span>
                </div>
              </ng-container>
            </div>
          </div>
          <p
            style="color: red"
            *ngIf="
              !propertyItem.nearbyNeighborhoodsList ||
              (propertyItem.nearbyNeighborhoodsList &&
                propertyItem.nearbyNeighborhoodsList.length === 0)
            "
          >
            <em>
              You need to provide nearby neighborhoods. Please contact your
              RevApp administrator.</em
            >
          </p>
        </form>
      </div>
    </div>
  </div>
  <div class="inner-area inner-area-3 pt-50">
    <revapp-calendar
      [displayPrecision]="user.displayPrecision"
      [dateLimits]="dateLimits"
      [propertyItemId]="propertyItem.propertyId"
    ></revapp-calendar>
  </div>
  <div class="inner-area inner-area-4">
    <div class="box box-1">
      <div class="button-container bottom-button-container text-center">
        <button
          [disabled]="!propHasExtRateId || !(propHasActiveResult | async)"
          class="blue-button"
          mat-icon-button
          (click)="syncButtonClicked()"
        >
          {{ autoSyncEnabled ? "UPDATE AUTOSYNC" : "SYNC" }}
        </button>

        <mat-slide-toggle
          (change)="autoSyncPrice($event)"
          [disabled]="
            !propHasExtRateId ||
            (!autoSyncEnabled && !(propHasActiveResult | async))
          "
          [checked]="autoSyncEnabled"
          color="primary"
        >
          Autosync prices
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</section>
