import { Component, Inject, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from "firebase/app";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { CommonDialogService } from "../common-dialog/common-dialog.service";
import { CommonDialogType } from "../common-dialog/common-dialog.interface";

export enum LoginSignupDialogStep {
  AUTH_CHOICE,
  CRED_INPUT,
  FORGOT_PASS
}

@Component({
  selector: "revapp-login-signup-dialog",
  templateUrl: "./login-signup-dialog.component.html",
  styleUrls: ["./login-signup-dialog.component.scss"],
})

export class LoginSignupDialogComponent implements OnInit {
  action: string;
  isSignUp: boolean;
  isEmailInputStep: boolean;
  faEnvelope = faEnvelope;
  public LoginSignupDialogStep = LoginSignupDialogStep;
  dialogStep: LoginSignupDialogStep;
  userCredInput: FormGroup;
  forgotPassInput: FormGroup;
  
  constructor(
    public fauth: AngularFireAuth,
    private _commonDialogSrv: CommonDialogService,
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<LoginSignupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: string }
  ) {}

  ngOnInit(): void {
    this.isSignUp = this.data.action === "signup";
    this.isEmailInputStep = false;
    this.dialogStep = LoginSignupDialogStep.AUTH_CHOICE;
    this.userCredInput = this._fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    this.forgotPassInput = this._fb.group({
      email: [null, [Validators.required]],
    });
    
  }

  onGoogleChoice(isSignUp): void {
    console.log("Google - is sign?", isSignUp);
    this.fauth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }
  
  // Send email verfificaiton when new user sign up
  async SendVerificationMail() {
    return (await this.fauth.currentUser).sendEmailVerification()
    // .then(() => {
    //   this.router.navigate(['verify-email-address']);
    // })
  }

  onEmailChoice(isSignUp:boolean, email:string, password:string): void {
    console.log("Email - is sign?", isSignUp);

    if(email.length==0 || password.length==0)
      return;

    if(!isSignUp) {
      this.fauth.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        this._commonDialogSrv.open(
          CommonDialogType.WARNING,
          "Credentials error",
          error.message
        );
      }) 
    } else {
      this.fauth.createUserWithEmailAndPassword(email, password)
        .then((result) => {
          /* Call the SendVerificaitonMail() function when new user sign 
          up and returns promise */
          
          this.SendVerificationMail();
          this._commonDialogSrv.open(
            CommonDialogType.SUCCESS,
            "Success",
            "You have successfully signed up!\n We have sent a confirmation email.\nPlease check your emails and click on the link to verify your email address."
          );
        }).catch((error) => {
          this._commonDialogSrv.open(
            CommonDialogType.WARNING,
            "Sign up error",
            "An error occurred while signing you out!"
          );
        })  

    }
  }
  onForgotPass(email:string) {

    this.fauth.sendPasswordResetEmail(email)
    .then((result) => {

      this._commonDialogSrv.open(
        CommonDialogType.SUCCESS,
        "Success",
        "An email has been sent to the provided email address with further instructions."
      );
      this.dialogRef.close();
    })
    .catch((error) => {
      this._commonDialogSrv.open(
        CommonDialogType.WARNING,
        "Credentials error",
        error.message
      );
    }) 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
