import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { PropertyAttributes } from "src/app/interfaces/property-attributes";
import { StateService } from "src/app/services/state.service";
import { Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { WebHotelierInputDialogComponent } from "./web-hotelier-input-dialog/web-hotelier-input-dialog.component";
import { PropertyCRUDService } from "src/app/services/property-crud.service";
import { PythonAlgoService } from "src/app/services/python-algo.service";
import { PythonPricingAlgoFunctionInput } from "functions/src/interfaces/PricingAlgoInput";
import { RevAppUser } from "src/app/interfaces/rev-app-user";

@Component({
  selector: "revapp-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  user$: Observable<RevAppUser | null>;
  user: RevAppUser;
  allProperties$: Observable<PropertyAttributes[]>;
  faPlus = faPlus;

  constructor(
    private userService: UserService,
    private _stateSrv: StateService,
    public dialog: MatDialog,
    private _propertyCRUDService: PropertyCRUDService,
    private _router: Router,
    private pythonAlgoService: PythonAlgoService
  ) {
    this.user$ = this.userService.getRevAppUser();
    this.user = this.userService.getRevAppUserStatic();
  }

  ngOnInit(): void {
    if (this.user) {
      this.allProperties$ = this._propertyCRUDService.getAllProperties();

      /**
       * This has to go
       */
      // this.allProperties$.subscribe(
      //   (res: PropertyAttributes[]) => {
      //     console.log("All properties", { res });
      //   },
      //   (error) => {
      //     console.error("Error loading properties", error);
      //   }
      // );
    }
  }

  onNavigateToForm(): void {
    this._stateSrv.setActivePropertyAttributes(null);
    this._router.navigate(["/form"]);
  }

  openWebhotelierModal(): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.width = "500px";
    const _dialogRef = this.dialog.open(
      WebHotelierInputDialogComponent,
      config
    );
  }
}
