import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "revapp-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  faCoffee = faCoffee;
  constructor() {}
}
