<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step
    [stepControl]="basicPropertyOptionsForm"
    label="Add Basic Property Options"
  >
    <section
      class="section-area section-area-1 display-flex pt-70 pb-150 min-height-800"
    >
      <div class="flex-col p-0 flex-03">
        <div class="main-title-container">
          <h1 class="main-title font-montserrat-black">
            Tell us about your property
          </h1>
        </div>
      </div>
      <div class="flex-col p-0 flex-07">
        <div class="form-container form-backgrounds step-1-form">
          <form [formGroup]="basicPropertyOptionsForm" novalidate>
            <!-- form title -->
            <div class="flex-container">
              <div class="form-row display-flex">
                <div class="flex-1 flex-col">
                  <h2 class="form-title font-montserrat-extrabold">
                    Basic Property Options
                  </h2>
                </div>
              </div>
            </div>

            <div class="flex-container">
              <!-- form Property / Stars -->
              <div class="form-row display-flex">
                <div class="flex-05 flex-col">
                  <mat-form-field appearance="fill">
                    <mat-label>Type of Property</mat-label>
                    <mat-select
                      formControlName="propertyTypeControl"
                      (selectionChange)="onPropertyTypeSelect($event)"
                    >
                      <mat-option
                        *ngFor="let property of propertyTypeOptions"
                        [value]="property.value"
                      >
                        {{ property.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="flex-05 flex-col">
                  <mat-form-field appearance="fill">
                    <mat-label>Number of Stars</mat-label>
                    <mat-select formControlName="starsControl" required>
                      <mat-option
                        *ngFor="let star of starsList"
                        [value]="star.value"
                      >
                        {{ star.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <!-- form Property / Stars -->
              <div class="form-row display-flex">
                <div class="flex-05 flex-col">
                  <mat-form-field appearance="fill">
                    <mat-label>Location of Property</mat-label>
                    <input
                      data-autocomplete="property-location"
                      matInput
                      required
                      formControlName="locationControl"
                    />
                  </mat-form-field>
                </div>
                <div class="flex-05 flex-col">
                  <mat-form-field appearance="fill">
                    <mat-label>Year Renovated</mat-label>
                    <input
                      formControlName="renovationYearControl"
                      matInput
                      type="number"
                      maxlength="4"
                    />
                  </mat-form-field>
                </div>
              </div>

              <!-- form Guests / Rooms / Floor / Total Score -->
              <div class="form-row display-flex flex-wrap">
                <div class="flex-025 flex-col">
                  <mat-form-field appearance="fill">
                    <mat-label>Number of Guests</mat-label>
                    <input
                      formControlName="guestControl"
                      matInput
                      type="number"
                      maxlength="3"
                    />
                  </mat-form-field>
                </div>
                <div class="flex-025 flex-col">
                  <mat-form-field appearance="fill">
                    <mat-label>Number of Rooms</mat-label>
                    <input
                      formControlName="roomsControl"
                      matInput
                      type="number"
                      maxlength="3"
                    />
                  </mat-form-field>
                </div>
                <div class="flex-025 flex-col">
                  <mat-form-field appearance="fill">
                    <mat-label>Floor</mat-label>
                    <input
                      formControlName="floorControl"
                      matInput
                      type="number"
                      maxlength="3"
                    />
                  </mat-form-field>
                </div>
                <div class="flex-025 flex-col">
                  <mat-form-field appearance="fill">
                    <mat-label>Total Score</mat-label>
                    <mat-select formControlName="totalScoreControl">
                      <mat-option
                        *ngFor="let score of totalScoreList"
                        [value]="score.value"
                      >
                        {{ score.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <!-- form buttons -->
            <div class="flex-container">
              <div class="form-row display-flex">
                <div class="flex-1 flex-col">
                  <div class="button-container text-right pb-30">
                    <button
                      mat-button
                      matStepperNext
                      class="form-btn"
                      [disabled]="
                        basicPropertyOptionsForm.invalid ||
                        basicPropertyOptionsForm.pristine
                      "
                    >
                      Next
                      <span class="icon-container"
                        ><fa-icon [icon]="faArrowRight"></fa-icon
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </mat-step>

  <mat-step label="Select Facilities & Amenities">
    <section class="section-area section-area-2 pt-70 pb-150 min-height-800">
      <div
        class="display-flex forms-wrapper-step-2 form-backgrounds full-cover"
      >
        <div class="flex-col flex-05">
          <form [formGroup]="facilitiesFormGroup" novalidate>
            <!-- form title -->
            <div class="flex-container">
              <div class="form-row display-flex">
                <div class="flex-1 flex-col">
                  <h2 class="form-title font-montserrat-extrabold">
                    Building Facilities
                  </h2>
                </div>
              </div>
            </div>

            <!-- form checkboxes -->
            <div class="flex-container">
              <ul class="form-row display-flex flex-wrap">
                <li
                  class="flex-4-col flex-col"
                  *ngFor="
                    let facility of facilitiesFormArray.controls;
                    let i = index
                  "
                >
                  <label
                    class="circle-checkboxes pointer"
                    formArrayName="facilities"
                    [ngClass]="
                      facilitiesFormArray.at(i).value ? 'checked' : null
                    "
                  >
                    <input type="checkbox" [formControlName]="i" />
                    <span>{{ facilitiesData[i].name }}</span>
                    <div class="img-container">
                      <img
                        [src]="
                          '../../../assets/images/svg/' +
                          facilitiesData[i].value +
                          '.svg'
                        "
                        alt=""
                      />
                    </div>
                  </label>
                </li>
              </ul>
            </div>

            <div class="mt-50">
              <button class="form-btn" mat-button matStepperPrevious>
                <span class="icon-container"
                  ><fa-icon [icon]="faArrowLeft"></fa-icon
                ></span>
                Back
              </button>
            </div>
          </form>
        </div>
        <div class="flex-col flex-05">
          <form [formGroup]="amenitiesFormGroup" novalidate>
            <!-- form title -->
            <div class="flex-container">
              <div class="form-row display-flex">
                <div class="flex-1 flex-col">
                  <h2 class="form-title font-montserrat-extrabold">
                    Room / Property Amenities
                  </h2>
                </div>
              </div>
            </div>

            <!-- form checkboxes -->
            <div class="flex-container">
              <ul class="form-row display-flex flex-wrap">
                <li
                  class="flex-4-col flex-col"
                  *ngFor="
                    let amenity of amenitiesFormArray.controls;
                    let i = index
                  "
                >
                  <label
                    class="circle-checkboxes pointer"
                    formArrayName="amenities"
                    [ngClass]="
                      amenitiesFormArray.at(i).value ? 'checked' : null
                    "
                  >
                    <input type="checkbox" [formControlName]="i" />
                    <span>{{ amenitiesData[i].name }}</span>
                    <div class="img-container">
                      <img
                        [src]="
                          '../../../assets/images/svg/' +
                          amenitiesData[i].value +
                          '.svg'
                        "
                        alt=""
                      />
                    </div>
                  </label>
                </li>
              </ul>
            </div>

            <div class="text-right mt-50">
              <button
                class="form-btn"
                mat-button
                matStepperNext
              >
                Next
                <span class="icon-container"
                  ><fa-icon [icon]="faArrowRight"></fa-icon
                ></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </mat-step>

  <mat-step label="Image & Preview Options">
    <section class="section-area section-area-3 pt-70 pb-150 min-height-800">
      <div class="forms-wrapper-step-2 form-backgrounds full-cover">
        <form [formGroup]="previewFormGroup" novalidate>
          <div class="display-flex step-3">
            <div class="flex-col flex-05 step-3">
              <!-- form title -->
              <div class="flex-container">
                <div class="form-row">
                  <div class="title-container"></div>
                </div>
              </div>

              <!-- Image -->
              <div class="flex-container">
                <div class="form-row">
                  <div class="outer-cloud-block-container">
                    <label class="cloud-block">
                      <div class="img-upload-container">
                        <img [src]="imgUploadSrc" />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Preview Col -->
            <div class="flex-col flex-05 step-3">
              <div class="flex-container">
                <div class="form-row mt-70">
                  <mat-form-field appearance="fill">
                    <mat-label>Property Name</mat-label>
                    <input
                      matInput
                      required
                      formControlName="propertyNameControl"
                    />
                  </mat-form-field>
                </div>

                <div class="form-row mt-70">
                  <mat-form-field appearance="fill">
                    <mat-label>Property Image URL</mat-label>
                    <input matInput formControlName="propertyImageUrlControl" />
                  </mat-form-field>
                </div>

                <div class="form-row">
                  <p class="property-summary">{{ propertySummary }}</p>
                </div>

                <div class="form-row">
                  <div
                    class="chosen-amenities"
                    *ngIf="selectedAmenities.length"
                  >
                    <h4 class="font-montserrat-semibold">Amenities:</h4>

                    <ul class="form-row mt-30">
                      <li
                        class="icon-container display-inline-block flex-col"
                        *ngFor="let amenity of selectedAmenities"
                      >
                        <div class="inner-area circle-checkboxes">
                          <span>{{ amenity.name }}</span>
                          <div class="img-container">
                            <img
                              [src]="
                                '../../../assets/images/svg/' +
                                amenity.value +
                                '.svg'
                              "
                              alt=""
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="chosen-facilities mt-30"
                    *ngIf="selectedFacilities.length"
                  >
                    <h4 class="font-montserrat-semibold">Facilities:</h4>
                    <div class="icon-container">
                      <ul class="form-row display-inline-block mt-30">
                        <li
                          class="icon-container display-inline-block flex-col"
                          *ngFor="let facility of selectedFacilities"
                        >
                          <div class="inner-area circle-checkboxes">
                            <span>{{ facility.name }}</span>
                            <div class="img-container">
                              <img
                                [src]="
                                  '../../../assets/images/svg/' +
                                  facility.value +
                                  '.svg'
                                "
                                alt=""
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-container">
            <div class="display-flex j-space-between mt-70">
              <button class="form-btn" mat-button matStepperPrevious>
                <span class="icon-container"
                  ><fa-icon [icon]="faArrowLeft"></fa-icon
                ></span>
                Back
              </button>
              <button
                class="form-btn"
                mat-button
                [disabled]="previewFormGroup.invalid"
                (click)="onSave()"
              >
                Save
              </button>
            </div>
          </div>
        </form>

        <div
          class="button-container display-flex j-space-between bottom-buttons"
        >
          <button mat-icon-button [routerLink]="['/home']">
            MY PROPERTIES
          </button>
          <button
            [disabled]="isCheckPricesBtnDisabled"
            mat-icon-button
            [routerLink]="['/check-prices']"
          >
            CHECK PRICES
          </button>
        </div>
      </div>
    </section>
  </mat-step>
</mat-horizontal-stepper>
