import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";
import { MatSelectChange } from "@angular/material/select";
import { RevAppUser } from "src/app/interfaces/rev-app-user";

@Component({
  selector: "revapp-user-item",
  templateUrl: "./user-item.component.html",
  styleUrls: ["./user-item.component.scss"],
})
export class UserItemComponent implements OnInit {
  @Input() user: RevAppUser;
  @Input() roles: string[];
  @Input() displayPrecisionList: number[];

  @Output() roleChanged: EventEmitter<{
    event: MatRadioChange;
    uid: string;
  }> = new EventEmitter();

  @Output() precisionChanged: EventEmitter<{
    event: MatSelectChange;
    uid: string;
  }> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
