import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

interface ITeamMember {
  imgPath: string;
  imgAlt: string;
  description: string;
  name: string;
  role: string;
  linkedin: string;
}

@Component({
  selector: "revapp-team",
  templateUrl: "./team.component.html",
  styleUrls: ["./team.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamComponent implements OnInit {
  memberList: ITeamMember[] = [
    {
      imgPath: "../../../assets/images/alexandros.jpg",
      imgAlt: "Toulias",
      description:
        "Alexandros Toulias, before establishing RevApp, has worked for top tier finance and management consulting firms; as well as developed his own apartment-hotels. He holds a Bachelor and MEng in Civil Engineering from National Technical University of Athens and an MSc in Real Estate Development from Columbia University.",
      name: "Alexandros Toulias",
      role: "Business Development and Sales",
      linkedin: "https://www.linkedin.com/in/alexandros-toulias-a9713414/",
    },
    {
      imgPath: "../../../assets/images/manos.jpg",
      imgAlt: "Manos",
      name: "Emmanouil Avgerinos",
      description:
        "Emmanouil Avgerinos has extensive experience in data analysis and optimization. He is a tenured Associate Professor of Decision Sciences in the area of Operations and Technology at IE Business School of IE University in Madrid. He holds a Bachelor and MEng in Electrical and Computer Engineering from National Technical University of Athens, an MSc in Operations Research from Columbia University and a PhD in Operations Management from UCL.",
      role: "Business and Product Development",
      linkedin: "https://www.linkedin.com/in/manos-avgerinos-a5a83019/",
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
