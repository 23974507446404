import { Component, Input, OnInit } from "@angular/core";
import { BlogPost } from "src/app/interfaces/blog-post";

@Component({
  selector: "revapp-blog-list-item",
  templateUrl: "./blog-list-item.component.html",
  styleUrls: ["./blog-list-item.component.scss"],
})
export class BlogListItemComponent implements OnInit {
  @Input() post: BlogPost;

  constructor() {}

  ngOnInit(): void {}
}
