<h1 class="main-dialog-title font-montserrat-extrabold" mat-dialog-title>
  {{ isSignUp ? "Sign up" : 
      (dialogStep === LoginSignupDialogStep.FORGOT_PASS) ? "Reset Password" : "Log in" }}
</h1>

<div mat-dialog-content>
  <div *ngIf="dialogStep === LoginSignupDialogStep.FORGOT_PASS">
    <form [formGroup]="forgotPassInput">
      <mat-form-field appearance="fill">
        <mat-label>Enter your email to receive reset link</mat-label>
        <input matInput required formControlName="email" type="text" #userName/>
      </mat-form-field>
    </form>
    
    <ul class="brand-list">  
      <li class="brand-two-col-list-item">
        <div class="txt-container-left" (click)="dialogStep = LoginSignupDialogStep.CRED_INPUT">
          Back
        </div>
        <div class="txt-container-right"(click)="onForgotPass(userName.value)">
          Reset Password
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="dialogStep === LoginSignupDialogStep.CRED_INPUT">
    <form [formGroup]="userCredInput">
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput required formControlName="email" type="text" #userName/>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label *ngIf="isSignUp">Create password</mat-label>
        <mat-label *ngIf="!isSignUp">Password</mat-label>
        <input matInput required formControlName="password" type="password" #userPassword/>
      </mat-form-field>
    </form>
    <ul class="brand-list">  
      <li class="brand-two-col-list-item">
        <div class="txt-container-left" (click)="dialogStep = LoginSignupDialogStep.AUTH_CHOICE">
          Back
        </div>
        <div class="txt-container-right"(click)="onEmailChoice(isSignUp,userName.value, userPassword.value)">
          {{ isSignUp ? "Sign up" : "Log in" }}
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="dialogStep === LoginSignupDialogStep.AUTH_CHOICE">
    <ul class="brand-list">
      <li class="brand-list-item" (click)="onGoogleChoice(isSignUp)">
        <div class="icon-container">
          <img
            src="../../../assets/images/svg/GoogleLogo.svg"
            alt="Goolge Logo"
          />
        </div>
        <div class="txt-container">
          Continue with Google
        </div>
      </li>
      <li class="brand-list-item" (click)="dialogStep = LoginSignupDialogStep.CRED_INPUT">
        <div class="icon-container">
        <fa-icon [icon]="faEnvelope"></fa-icon>
        </div>
        <div class="txt-container">
          Continue with Email/Password
        </div>
      </li>
    </ul>
  </div>
</div>
<div mat-dialog-actions>
  <div *ngIf="dialogStep !== LoginSignupDialogStep.FORGOT_PASS">
    <div *ngIf="isSignUp">
      Already have an account ?
      <span class="clickable" role="button" (click)="isSignUp = !isSignUp"
        >Log in</span
      >
    </div>
    <div *ngIf="!isSignUp">
      <div class="forgotpass" *ngIf="dialogStep === LoginSignupDialogStep.CRED_INPUT">
        Forgot your password ? Thats okay, 
      <span class="clickable-forgot" role="button" (click)="dialogStep = LoginSignupDialogStep.FORGOT_PASS">
            click here
        </span>
      </div>
      Don't have an account ?
      <span class="clickable" role="button" (click)="isSignUp = !isSignUp"
        >Sign up</span
      >
    </div>
  </div>
</div>
