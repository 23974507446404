
export enum operationEnum {
  BasePriceSuggetion    = "BasePriceSuggetion",
  PriceCalculation      = "PriceCalculation",
  FullPriceCalculation  = "FullPriceCalculation",
}

export interface PythonPricingAlgoFunctionInput {
  // This can contain anything you want,
  // as long as it's valid JSON
  operation: operationEnum;
  basePrice: number;
  minimumPrice: number;
  maximumPrice: number;
  neighborhood: string;
  nearby_neighborhoods_list: Array<string>;
  direct_competitors_list: Array<string>;
  selected_period: Array<string>;
  precision_digits: number;
  algoRiskStrategy: number;
  tierPriceStrategyEnabled: boolean | null;
  tierPriceStrategyPercentAbsolute: boolean | null;
  tierPriceStrategyArray: Array<number>;
  propertyAvailability: Array<{date: string;  availability: number}>
}