<!-- Page Label -->
<section class="section-page">
  <revapp-page-label title="Frequently asked questions"></revapp-page-label>
</section>

<section class="section-page questions-section">
  <div class="inner-area inner-area-1 display-flex flex-wrap j-space-between wide-center-container">

    <div class="box box-1 box-flex-half">

      <div class="questions-widget" *ngFor="let question of questionsLeft; let index = index;">
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ question.title }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ question.answerPart1 }}</p>
            <p *ngIf="question.answerPart2">{{ question.answerPart2 }}</p>
            <p *ngIf="question.answerPart3">{{ question.answerPart3 }}</p>
            <p *ngIf="question.answerPart4">{{ question.answerPart4 }}</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </div>

    <div class="box box-2 box-flex-half">

      <div class="questions-widget" *ngFor="let question of questionsRight; let index = index;">
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ question.title }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ question.answerPart1 }}</p>
            <p *ngIf="question.answerPart2">{{ question.answerPart2 }}</p>
            <p *ngIf="question.answerPart3">{{ question.answerPart3 }}</p>
            <p *ngIf="question.answerPart4">{{ question.answerPart4 }}</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </div>

  </div>

  <div class="inner-area inner-area-2 mt-50 wide-center-container">
    <p><a class="link underline" [routerLink]="['/contact']"><strong>Contact us</strong></a> if you have more questions</p>
  </div>
</section>