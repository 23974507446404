import { Component } from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";
import { MatSelectChange } from "@angular/material/select";
import { Observable } from "rxjs";
import { RevAppUser } from "src/app/interfaces/rev-app-user";
import { AdminService } from "src/app/services/admin.service";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "revapp-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent {
  users$: Observable<RevAppUser[]> = this.adminService.getAllUsers();
  roles: string[] = this.adminService.getUserRoles();
  displayPrecisionList: number[] = this.adminService.getUserDisplayPrecision();

  constructor(
    private adminService: AdminService,
    private notificationService: NotificationService
  ) {}

  roleChanged(changedEvent: { event: MatRadioChange; uid: string }) {
    this.adminService
      .changeUserRole(changedEvent.event.value, changedEvent.uid)
      .then((res) => {
        this.notificationService.setNotification(
          `User role changed to: ${changedEvent.event.value}`,
          "OK"
        );
      })
      .catch((error) => {
        this.notificationService.setNotification(
          `There was an error changing the role.`,
          "OK",
          error
        );
        console.log("Error changing role:", error);
      });
  }

  precisionChanged(changedEvent: { event: MatSelectChange; uid: string }) {
    this.adminService
      .changeUserDisplayPrecision(changedEvent.event.value, changedEvent.uid)
      .then((res) => {
        this.notificationService.setNotification(
          `User display precision changed to: ${changedEvent.event.value}`,
          "OK"
        );
      })
      .catch((error) => {
        this.notificationService.setNotification(
          `There was an error changing the display precision.`,
          "OK",
          error
        );
        console.log("Error changing display precision:", error);
      });
  }
}
