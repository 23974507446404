<!-- Page Label -->
<section class="section-page">
  <revapp-page-label title="Contact"></revapp-page-label>
</section>

<!-- Intro Card -->
<section class="section-area section-area-1 intro-page-section-card">
  <mat-card>
    <ul class="contact-list">

      <li class="contact-item">
        <div class="icon-container">
          <fa-icon [icon]="faMapMarked"></fa-icon>
        </div>
        <div class="txt-container">
          <h3 class="font-montserrat-black">Office</h3>
          <p>
            Andrea Siggrou Ave. 190, Kallithea 17671, Athens Greece
          </p>
        </div>
      </li>
      <li class="contact-item">
        <div class="icon-container">
          <fa-icon [icon]="faMobileAlt"></fa-icon>
        </div>
        <div class="txt-container">
          <h3 class="font-montserrat-black">Phone</h3>
          <p>+30 694.543.7946</p>
          <p>+30 694.650.7104</p>
        </div>
      </li>
      <li class="contact-item">
        <div class="icon-container">
          <fa-icon [icon]="faEnvelope"></fa-icon>
        </div>
        <div class="txt-container">
          <h3 class="font-montserrat-black">Email</h3>
          <p><a href="mailto:info@getrevapp.com">info@getrevapp.com</a></p>
        </div>
      </li>
    </ul>
  </mat-card>
</section>

<!-- Schedule trial Section -->
<section class="section-area section-area-2 schedule-trial-section">
  <div class="inner-area inner-area-1">
    <div class="box box-1 text-center pb-150">
      <a
        href="https://calendly.com/revappteam/revapp-intro-call"
        mat-icon-button
        class="btn-orange schedule-btn"
        target="_blank"
      >Schedule a trial</a>
    </div>
  </div>
</section>
