import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { MatDialog } from "@angular/material/dialog";
import { faQuestion, faUser, faStream } from "@fortawesome/free-solid-svg-icons";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserService } from "src/app/services/user.service";
import { LoginSignupDialogComponent } from "../login-signup-dialog/login-signup-dialog.component";
import firebase from "firebase/app";
import { Router } from "@angular/router";
import { CommonDialogService } from "../common-dialog/common-dialog.service";
import { CommonDialogType } from "../common-dialog/common-dialog.interface";
import { RevAppUser } from "src/app/interfaces/rev-app-user";
@Component({
  selector: "revapp-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  routeDestination = "";
  faQuestion = faQuestion;
  faUser = faUser;
  faStream = faStream;
  user: firebase.User;
  user$: Observable<firebase.User>;
  revAppUser$: Observable<RevAppUser>;
  destroy$: Subject<void> = new Subject();
  isBurgerMenuOpen: boolean = false;

  constructor(
    public fauth: AngularFireAuth,
    private userService: UserService,
    public dialog: MatDialog,
    private _router: Router,
    private _commonDialogSrv: CommonDialogService,
    private _renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.getUser();
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      this.routeDestination = user ? "/home" : "";
    });
    this.revAppUser$ = this.userService.getRevAppUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private openDialog(action): void {
    const dialogRef = this.dialog.open(LoginSignupDialogComponent, {
      width: "500px",
      data: { action },
    });
  }

  onLoginSignUp(action: string): void {
    this.openDialog(action);
  }

  logout(): void {
    this.fauth
      .signOut()
      .then(() => {
        this.userService.setUser(null);
        this._router.navigate(["/intro"]);
        this._commonDialogSrv.open(
          CommonDialogType.SUCCESS,
          "Success",
          "You have successfully logged out!"
        );
      })
      .catch((e) => {
        this._commonDialogSrv.open(
          CommonDialogType.WARNING,
          "Warning",
          "An error occurred while logging out!"
        );
        console.error(e);
      });
  }

  burgerClicked(): void {
    if (!this.isBurgerMenuOpen) {
      this._renderer.addClass(document.body, "hide-scrollbar");
    } else {
      this._renderer.removeClass(document.body, "hide-scrollbar");
    }
  }

  toggleBurgerMenu(): void {
    this.burgerClicked();
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }
}
