import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { PricingModel } from "../interfaces/pricing-model";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  PropertyAttributes,
  PropertyAttributesClass,
} from "../interfaces/property-attributes";
import { DateAndPriceParameters } from "../interfaces/date-and-price-parameters";
import { PricingResult, StateService } from "./state.service";
import { PropertyCRUDService } from "./property-crud.service";
import { CommonDialogService } from "../components/common-dialog/common-dialog.service";
import { WebhotelierService } from "src/app/services/webhotelier.service";
import { RoomAvailabilities } from "functions/src/interfaces/RoomAvailabilities";
import { CommonDialogType } from "../components/common-dialog/common-dialog.interface";
import { PythonAlgoService } from "src/app/services/python-algo.service";
import { 
  operationEnum, 
  PythonPricingAlgoFunctionInput,
} from "functions/src/interfaces/PricingAlgoInput";
import { AngularFireFunctions } from "@angular/fire/functions";
import { PricingResultClass } from "functions/src/interfaces/PricingResultInterface";

export enum PricingStatus {
  Inactive = "Inactive",
  SendingDataToFirebase = "SendingDataToFirebase",
  DataWrittenSuccessfully = "DataWrittenSuccessfully",
  ErrorWritingData = "ErrorWritingData",
  SendingDataToAlgorithm = "SendingDataToAlgorithm",
  ResultsReceivedSuccessfully = "ResultsReceivedSuccessfully",
  ErrorReceivingResults = "ErrorReceivingResults",
}

export enum GenericDataSavingStatus {
  Inactive = "Inactive",
  SendingDataToFirebase = "SendingDataToFirebase",
  DataWrittenSuccessfully = "DataWrittenSuccessfully",
  ErrorWritingData = "ErrorWritingData",
}

@Injectable({
  providedIn: "root",
})
export class FormService {
  private callableSavePricingResultToFirestore: CallableFunction;
  private callableGetCustomPricingResultFromFirestore: CallableFunction;

  allProperties$: Observable<PropertyAttributes[]>;
  allPropertiesStatic: PropertyAttributes[];

  public algorithmStatus: BehaviorSubject<PricingStatus> = new BehaviorSubject<PricingStatus>(
    PricingStatus.Inactive
  );

  public dataSavingStatus: BehaviorSubject<GenericDataSavingStatus> = new BehaviorSubject<GenericDataSavingStatus>(
    GenericDataSavingStatus.Inactive
  );

  public pricingSavingStatus: BehaviorSubject<GenericDataSavingStatus> = new BehaviorSubject<GenericDataSavingStatus>(
    GenericDataSavingStatus.Inactive
  );

  public basePriceAlgoStatus: BehaviorSubject<GenericDataSavingStatus> = new BehaviorSubject<GenericDataSavingStatus>(
    GenericDataSavingStatus.Inactive
  );

  enableCheckPricesBtn$: Subject<boolean> = new Subject();

  constructor(
    public afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private propertyCRUDService: PropertyCRUDService,
    private stateService: StateService,
    private _commonDialogSrv: CommonDialogService,
    private pythonAlgoService: PythonAlgoService,
    private webhotelierService: WebhotelierService
  ) {
    this.callableSavePricingResultToFirestore = this.fns.httpsCallable(
      "savePricingResult"
    );
    this.callableGetCustomPricingResultFromFirestore = this.fns.httpsCallable(
      "getChangedPricingResult"
    );

    this.allProperties$ = this.propertyCRUDService.getAllProperties();
    this.allProperties$.subscribe((res: PropertyAttributes[]) => { this.allPropertiesStatic = res} );
  }

  getAlgorithmStatus(): Observable<PricingStatus> {
    return this.algorithmStatus.asObservable();
  }

  getBasePriceStatus(): Observable<GenericDataSavingStatus> {
    return this.basePriceAlgoStatus.asObservable();
  }

  getPricingWritingToFirebaseStatus(): Observable<GenericDataSavingStatus> {
    return this.pricingSavingStatus.asObservable();
  }

  // NEW FUNCTION
  savePropertyDataToFirebase(
    propertyAttributes: PropertyAttributes,
    userId: string
  ): void {
    this.dataSavingStatus.next(GenericDataSavingStatus.SendingDataToFirebase);
    this.stateService.setActivePropertyAttributes(propertyAttributes);
    this.saveProperty(propertyAttributes);
  }

  saveProperty(propertyAttributes: PropertyAttributes) {
    this.propertyCRUDService
      .saveNewProperty(propertyAttributes)
      .then((res) => {
        this.stateService.setActivePropertyAttributes({
          ...propertyAttributes,
          propertyId: res.id,
        });
        this.dataSavingStatus.next(
          GenericDataSavingStatus.DataWrittenSuccessfully
        );
        this.enableCheckPricesBtn$.next(true);
        this._commonDialogSrv.open(
          CommonDialogType.SUCCESS,
          "Success",
          'Property has been created! Press the "MY PROPERTIES" button to see your property list or press the "CHECK PRICES" to set property prices.'
        );
      })
      .catch((error) => {
        this.dataSavingStatus.next(GenericDataSavingStatus.ErrorWritingData);
        console.error("Error adding", error);
        this._commonDialogSrv.open(
          CommonDialogType.WARNING,
          "Warning",
          "An error occurred while adding your property!"
        );
      });
  }

  // NEW FUNCTION
  saveDateAndPriceParametersToFirebaseAndRunPricingAlgo(
    dateAndPriceParameters: DateAndPriceParameters,
    propertyId: string,
    userId: string
  ): void {
    this.algorithmStatus.next(PricingStatus.SendingDataToFirebase);

    dateAndPriceParameters.timestampOfRequest = Date.now();

    this.afs
      .collection("userData")
      .doc(userId)
      .collection("properties")
      .doc(propertyId)
      .collection("pricings")
      .add(dateAndPriceParameters)
      .then((res) => {
        this.algorithmStatus.next(PricingStatus.DataWrittenSuccessfully);
        this.algorithmStatus.next(PricingStatus.SendingDataToAlgorithm);
        console.log("|---- PricingParameters res ----|", { res });
        this.sendDataForPricingNewWay(
          dateAndPriceParameters,
          propertyId,
          res.id,
          userId
        );
      })
      .catch((error) => {
        this.algorithmStatus.next(PricingStatus.ErrorWritingData);
        console.error("Error adding", error);
      });
  }

  runPricingAlgoToSuggestBasePrice(
    dateAndPriceParameters: DateAndPriceParameters,
    propertyId: string,
    userId: string
  ): number {
    this.basePriceAlgoStatus.next(
      GenericDataSavingStatus.SendingDataToFirebase
    );

    dateAndPriceParameters.timestampOfRequest = Date.now();

    return this.sendDataForSuggestingBasePrice(
      dateAndPriceParameters,
      propertyId,
      userId
    );
  }

  findMaxNumberOfDaysOfMonth(month: string, year: string): number {
    if (
      month === "01" ||
      month === "03" ||
      month === "05" ||
      month === "07" ||
      month === "08" ||
      month === "1" ||
      month === "3" ||
      month === "5" ||
      month === "7" ||
      month === "8" ||
      month === "10" ||
      month === "12"
    ) {
      return 31;
    } else {
      if (month === "2" || month === "02") {
        return this.leapYear(parseInt(year)) ? 29 : 28;
      } else {
        return 30;
      }
    }
  }

  leapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  findNextDate(date: string): string {
    const dateValues: string[] = date.split("-");
    const dayStart: number = parseInt(dateValues[2]);
    const monthStart: number = parseInt(dateValues[1]);
    const yearStart: number = parseInt(dateValues[0]);

    let nextDay: number;
    let nextMonth: number = monthStart;
    let nextYear: number = yearStart;
    if (
      dayStart + 1 <=
      this.findMaxNumberOfDaysOfMonth(dateValues[1], dateValues[0])
    ) {
      nextDay = dayStart + 1;
    } else {
      // Go to next month
      nextDay = 1;
      if (monthStart + 1 <= 12) {
        nextMonth = monthStart + 1;
      } else {
        nextMonth = 1;
        nextYear = yearStart + 1;
      }
    }

    const nextDate: string = `${nextYear}-${nextMonth
      .toString()
      .padStart(2, "0")}-${nextDay.toString().padStart(2, "0")}`;

    return nextDate;
  }

  getPaddedDate(date: string): string {
    const dateValues: string[] = date.split("/");
    const day: number = parseInt(dateValues[0]);
    const month: number = parseInt(dateValues[1]);
    const year: number = parseInt(dateValues[2]);
    const paddedDate: string = `${year}-${month
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    return paddedDate;
  }

  construction_selected_period(startDate: string, endDate: string): string[] {
    const datesArray: string[] = [];
    let nextDate: string = this.getPaddedDate(startDate as string);
    datesArray.push(nextDate);

    const paddedEnd: string = this.getPaddedDate(endDate as string);

    let failSafe: number = 0;
    while (paddedEnd !== nextDate && failSafe <= 365) {
      nextDate = this.findNextDate(nextDate);
      datesArray.push(nextDate);
      failSafe++;
    }

    return datesArray;
  }

  sendDataForSuggestingBasePrice(
    dateAndPriceParameters: DateAndPriceParameters,
    propertyId: string,
    userId: string
  ): number {
    const pricingModel: PricingModel = {
      ...dateAndPriceParameters,
      ...this.stateService.getActivePropertyAttributesStatic(),
    };

    // Construct selected_period dates
    const datesArray: string[] = this.construction_selected_period(
      dateAndPriceParameters.startDate as string,
      dateAndPriceParameters.endDate as string
    );

    const activeProperty: PropertyAttributesClass = this.stateService.getActivePropertyAttributesStatic();
    const pythonFunctionInput: PythonPricingAlgoFunctionInput = {
      basePrice: dateAndPriceParameters.basePrice,
      minimumPrice: dateAndPriceParameters.minimumPrice,
      maximumPrice: dateAndPriceParameters.maximumPrice,
      neighborhood: activeProperty.neighborhood,
      nearby_neighborhoods_list: activeProperty.nearbyNeighborhoodsList,
      direct_competitors_list: activeProperty.directCompetitorsList,
      selected_period: datesArray,
      operation: operationEnum.BasePriceSuggetion,
      precision_digits: dateAndPriceParameters.displayPrecision,
      algoRiskStrategy: dateAndPriceParameters.algoRiskStrategy,
      tierPriceStrategyEnabled: false,
      tierPriceStrategyPercentAbsolute: false,
      tierPriceStrategyArray: [],
      propertyAvailability: [],
    };

    let suggestedBasePrice: number = 0.0;

    this.pythonAlgoService
      .callPythonCloudFunction(pythonFunctionInput)
      .then((suggestedBasePriceResult: any) => {
        suggestedBasePrice = suggestedBasePriceResult["base_price"].price;
        console.log("|---- suggestedBasePrice ----|", { suggestedBasePrice });
        this.stateService.setActiveSuggestedBasePrice(suggestedBasePrice);
        this.basePriceAlgoStatus.next(
          GenericDataSavingStatus.DataWrittenSuccessfully
        );
      })
      .catch((error) => {
        console.error("Cloud Function error", error);
        this.basePriceAlgoStatus.next(GenericDataSavingStatus.ErrorWritingData);
      });
    return suggestedBasePrice;
  }

  async sendDataForPricingNewWay(
    dateAndPriceParameters: DateAndPriceParameters,
    propertyId: string,
    pricingId: string,
    userId: string
  ): Promise<void> {
    //this.router.navigate(["/check-prices"]);
    const pricingModel: PricingModel = {
      ...dateAndPriceParameters,
      ...this.stateService.getActivePropertyAttributesStatic(),
    };

    // Construct selected_period dates
    const datesArray: string[] = this.construction_selected_period(
      dateAndPriceParameters.startDate as string,
      dateAndPriceParameters.endDate as string
    );

    const activeProperty: PropertyAttributesClass = this.stateService.getActivePropertyAttributesStatic();
    let propertyAvailabilityPercent: RoomAvailabilities = [];
    if(dateAndPriceParameters.tierPriceStrategyEnabled && activeProperty.numberOfRooms) {
      let totalPropertyRooms = 0;
      let propertyAvailability: RoomAvailabilities = [];
      
      await this.webhotelierService
        .getPropertyPerformancePerDay({
          username: activeProperty.propertyUser,
          password: activeProperty.propertyPass,
          property: activeProperty.propertyName,
          from:this.getPaddedDate(dateAndPriceParameters.startDate as string),
          to:this.getPaddedDate(dateAndPriceParameters.endDate as string),
        })
        .then((res) => {
          totalPropertyRooms = totalPropertyRooms + ((activeProperty.numberOfRooms === undefined || activeProperty.numberOfRooms === null) ? 0 : activeProperty.numberOfRooms);
          if(!propertyAvailability.length) {
            propertyAvailability = res;
          } else {
            for(var i=0; i<res.length; ++i) {
              for(var j=0; j<propertyAvailability.length; ++j) {
                if(res[i].date === propertyAvailability[j].date) {
                  propertyAvailability[j].availability = propertyAvailability[j].availability + res[i].availability;
                }
              }
            }  
          }
        })
        .catch(() => {
          console.log("Error in getRoomAvailabilitiesWebHotelier")
        });

      for (const avail of propertyAvailability){
        const date = avail.date;
        const availability = (Math.round((1-(avail.availability/totalPropertyRooms) + Number.EPSILON) * 100) / 100);
        propertyAvailabilityPercent.push( {date, availability} );
      }
      console.log("propertyAvailability", propertyAvailabilityPercent);  
    }

    const pythonFunctionInput: PythonPricingAlgoFunctionInput = {
      basePrice: dateAndPriceParameters.basePrice,
      minimumPrice: dateAndPriceParameters.minimumPrice,
      maximumPrice: dateAndPriceParameters.maximumPrice,
      neighborhood: activeProperty.neighborhood,
      nearby_neighborhoods_list: activeProperty.nearbyNeighborhoodsList,
      direct_competitors_list: activeProperty.directCompetitorsList,
      selected_period: datesArray,
      operation: operationEnum.PriceCalculation,
      precision_digits: dateAndPriceParameters.displayPrecision,
      algoRiskStrategy: dateAndPriceParameters.algoRiskStrategy,
      tierPriceStrategyEnabled: dateAndPriceParameters.tierPriceStrategyEnabled,
      tierPriceStrategyPercentAbsolute: dateAndPriceParameters.tierPriceStrategyPercentAbsolute,
      tierPriceStrategyArray: dateAndPriceParameters.tierPriceStrategyArray,
      propertyAvailability: propertyAvailabilityPercent,
    };
    return this.pythonAlgoService
      .callPythonCloudFunction(pythonFunctionInput)
      .then((pricingResult: PricingResult) => {
        console.log("|---- PriceParameters res 1 ----|", { pricingResult });
        this.pricingSavingStatus.next(
          GenericDataSavingStatus.SendingDataToFirebase
        );
        this.callableSavePricingResultToFirestore({
          userId,
          propertyId,
          pricingResult,
          pricingId,
        })
          .toPromise()
          .then(() => {
            this.pricingSavingStatus.next(
              GenericDataSavingStatus.DataWrittenSuccessfully
            );
          })
          .catch((error) => {
            console.error("Error saving results to firebsae", error);
            this.pricingSavingStatus.next(
              GenericDataSavingStatus.ErrorWritingData
            );
          });

        this.callableGetCustomPricingResultFromFirestore({
          userId,
          propertyId,
          pricingResult,
        })
          .toPromise()
          .then((customPricingResult: PricingResult) => {
            this.stateService.setActiveResults(pricingResult);
            console.log("|---- PriceParameters res 2 ----|", {
              customPricingResult,
            });
            this.stateService.setActiveCustomResults(customPricingResult);
            this.algorithmStatus.next(
              PricingStatus.ResultsReceivedSuccessfully
            );
          })
          .catch((error) => {
            console.error("Cloud Function error", error);
            this.algorithmStatus.next(PricingStatus.ErrorReceivingResults);
          });
      })
      .catch((error) => {
        console.error("Cloud Function error", error);
        this.algorithmStatus.next(PricingStatus.ErrorReceivingResults);
      });
  }

  getFormattedDate(date: Date): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  async getCustomPricingResult(
    propertyId: string,
    userId: string,
    pricingResult: PricingResultClass
  ) : Promise<PricingResultClass> {
    let customPricingResultReturn:PricingResultClass = new PricingResultClass;

    await this.callableGetCustomPricingResultFromFirestore({
      userId,
      propertyId,
      pricingResult,
    })
      .toPromise()
      .then((customPricingResult: PricingResult) => {
        customPricingResultReturn = customPricingResult;
      })
      .catch((error) => {
        console.error("Cloud Function error", error);
      });

  return customPricingResultReturn;
  }
}
