import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { BlogPost } from "src/app/interfaces/blog-post";
import { BlogCrudService } from "src/app/services/blog-crud.service";

@Component({
  selector: "revapp-intro-page",
  templateUrl: "./intro-page.component.html",
  styleUrls: ["./intro-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntroPageComponent implements OnInit {
  mainTitle = " Optimize your room revenues, automatically.";
  mainSubTitle =
    "Offering hotels and vacation rentals, what the big chains use, experts' and data driven decisions.";
  secondaryTitle = "Redeem your free trial months!";

  blogPosts: Observable<
    BlogPost[]
  > = this.blogCrudService.getLastThreeBlogPosts();

  constructor(private blogCrudService: BlogCrudService) {}

  ngOnInit(): void {}
}
