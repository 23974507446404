<section class="title-container section-area">
  <h1 class="main-dialog-title font-montserrat-extrabold" mat-dialog-title>
    Price Breakdown for
    <span class="display-block">
      {{ data.dayObj.day }} {{ monthNames[+data.block.month] }}
      {{ data.block.year }}
    </span>
  </h1>
</section>
<div mat-dialog-content>
  <section class="base-price-container section-area">
    <h4 class="font-montserrat-semibold mb-15">Base price</h4>
    <p class="details">
      {{ data.basePrice | currency: data.currency:"symbol-narrow": displayPrecisionStr }}
    </p>
  </section>
  <section class="market-dynamics-container section-area colored-section">
    <h4 class="font-montserrat-semibold mb-15">Market dynamics</h4>
    <p class="details">
      <span>{{ marketDynamicsPercentage | number: displayPrecisionStr }}%,</span>
      <span>{{
        marketDynamicsAmount | currency: data.currency:"symbol-narrow":displayPrecisionStr
      }}</span>
    </p>
  </section>
  <section class="user-adjustment-container section-area">
    <h4 class="font-montserrat-semibold mb-15">Min/max price correction</h4>
    <p class="details">
      <span>{{ minMaxPriceCorrectionPercentage | number: displayPrecisionStr }}%,</span>
      <span>{{
        minMaxPriceCorrectionAmount
          | currency: data.currency:"symbol-narrow":displayPrecisionStr
      }}</span>
    </p>
  </section>
  <section class="market-dynamics-container section-area colored-section">
    <h4 class="font-montserrat-semibold mb-15">Advanced settings Adjustment</h4>
    <p class="details">
      <span>{{ advancedStrategiesCorrectionPercentage | number: displayPrecisionStr }}%,</span>
      <span>{{
        advancedStrategiesCorrectionAmount | currency: data.currency:"symbol-narrow":displayPrecisionStr
      }}</span>
    </p>
  </section>
  <section class="market-dynamics-container section-area">
    <h4 class="font-montserrat-semibold mb-15">User adjustment</h4>
    <p class="details">
      <span>{{ userAdjustmentPercentage | number: displayPrecisionStr }}%,</span>
      <span>{{
        userAdjustmentAmount | currency: data.currency:"symbol-narrow":displayPrecisionStr
      }}</span>
    </p>
  </section>
  <section class="form-container section-area">
    <form [formGroup]="form">
      <div class="form-row display-flex align-items-center">
        <h2 class="final-price-title">Final Price</h2>
        <span class="final-price-currency-symbol">{{ currencySymbol }}</span>
        <input
          #priceInput
          class="price-input"
          required
          formControlName="finalPriceControl"
          type="number"
        />
      </div>
      <div class="form-row">
        <mat-checkbox
          color="primary"
          formControlName="editPriceControl"
          (change)="onEditPriceChange($event)"
          >EDIT PRICE</mat-checkbox
        >
      </div>
      <div class="form-row">
        <p class="suggested-msg">
          Suggested Price
          {{
            data.dayObj.suggestedPrice
              | currency: data.currency:"symbol-narrow":displayPrecisionStr
          }}
        </p>
        <p class="reset-price">
          <mat-slide-toggle
            (change)="resetPrice()"
            [checked]="resetFinalPriceEnabled"
            color="primary"
          >
            Reset to suggested price
          </mat-slide-toggle>
        </p>
      </div>
    </form>
  </section>
</div>
<section class="button-container section-area">
  <div mat-dialog-actions>
    <button
      mat-icon-button
      class="calendar-dialog-btn cancel-btn"
      (click)="onCloseModal()"
    >
      CANCEL
    </button>
    <button
      mat-icon-button
      class="calendar-dialog-btn update-btn"
      [disabled]="
        (!form.get('editPriceControl').value ||
          !form.get('finalPriceControl').value) &&
        !enableUpdateButton
      "
      (click)="onUpdateEntry()"
    >
      UPDATE
    </button>
  </div>
</section>
