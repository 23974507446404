<footer id="footer" class="">
  <div class="wide-center-container">
    <section class="top-footer-row display-flex j-space-between">
      <div class="box">
        <div class="img-container logo-container mb-30">
          <img
            src="../../../assets/images/RevApp-logo-draft.png"
            alt="RevApp footer logo"
          />
        </div>
        <div class="text-container w-400 mb-30">
          <address>
            <p>Andrea Siggrou Ave. 190, Kallithea 17671,</p>
            <p>Athens Greece</p>
            <p>
              Info:<span class="phone">+30 694.543.7946</span
              ><span class="phone">+30 694.650.7104</span>
            </p>
            <p><a href="mailto:info@getrevapp.com">info@getrevapp.com</a></p>
          </address>
        </div>
      </div>

      <div class="box">
        <div id="about-list" class="footer-menu-list-wrapper">
          <h3>ABOUT</h3>
          <ul>
            <li class="list-item">
              <a [routerLink]="['/team']"> The team </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="box">
        <div id="product-list" class="footer-menu-list-wrapper">
          <h3>PRODUCT</h3>
          <ul>
            <li class="list-item">
              <a [routerLink]="['/product']"> The Product </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="box">
        <div id="support-list" class="footer-menu-list-wrapper">
          <h3>SOCIAL MEDIA</h3>
          <ul class="display-flex j-space-between social-list">
            <li>
              <a
                href="https://www.linkedin.com/company/revappdynamic/"
                target="_blank"
              >
                <div class="icon-wrapper">
                  <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="bottom-footer-row display-flex j-space-between">
      <div class="box box-1 align-self-center">
        <div class="copy-rights-widget">
          <p>
            &copy;REVAPP | All Rights Reserved -
            <a class="terms-link" [routerLink]="['/terms-of-service']"
              >Privacy - Terms of Use</a
            >
          </p>
        </div>
      </div>
      <div class="box box-2 py-20">
        <ul class="sponsors-list display-flex j-space-between mb-15">
          <li>
            <a href="https://www.capsuletaccelerator.gr/" target="_blank"
              ><img
                src="../../../assets/images/capsuleT.png"
                alt="Capsule T logo"
            /></a>
          </li>
          <li>
            <a href="https://www.eitdigital.eu/" target="_blank"
              ><img
                src="../../../assets/images/landscape_blue-on-white.png"
                alt="EIT Digital logo"
            /></a>
          </li>
          <li>
            <a href="https://www.eitdigital.eu/" target="_blank"
              ><img
                src="../../../assets/images/EIT.png"
                alt="EIT Digital logo 2  "
            /></a>
          </li>
        </ul>

        <ul class="sponsors-list display-flex j-space-between">
          <li>
            <a href="https://www.theegg.gr/el" target="_blank"
              ><img src="../../../assets/images/egg.png" alt="The egg logo"
            /></a>
          </li>
          <li>
            <a href="https://www.nbg.gr/en/nbgseeds/" target="_blank"
              ><img
                src="../../../assets/images/nbg-business-seeds.jpg"
                alt="NBG Business Seeds"
            /></a>
          </li>
          <li>
            <a href="https://filrougecapital.com/" target="_blank"
              ><img
                src="../../../assets/images/frc_black.jpg"
                alt="Fil Rouge Capital"
            /></a>
          </li>
          <li>
            <a href="https://ec.europa.eu/info/funding-tenders/funding-opportunities/funding-programmes/overview-funding-programmes/european-structural-and-investment-funds_en" target="_blank"
              ><img
                src="../../../assets/images/esif.jpg"
                alt="ESIF Financial instruments"
            /></a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</footer>
