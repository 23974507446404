import { PricingResult } from "functions/src/interfaces/PricingResultInterface";

export interface PricingDayObject {
  id: string;
  day: number;
  month: number;
  year: number;
  changed: boolean;
  price?: number | string;
  suggestedPrice?: number | string;
  userPrice?: number | string;
}

export interface PricingDayObjectExtended {
  id: string;
  day: number | string;
  month: number | string;
  year?: number;
  changed: boolean;
  price?: number;
  suggestedPrice?: string;
  userPrice?: number;
  correction?: string;
  trend?: string;
  advancedStrategies?: string;
}

// Get month diff from two dates, ignoring days
export const monthDiff = (dateFrom: Date, dateTo: Date): number => {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

// Get number of month blocks
export const getNumOfMonthBlocks = (dateFrom: Date, dateTo: Date): number => {
  return monthDiff(dateFrom, dateTo) + 1;
};

// Map prices to days ( simple object list )
export const mapPricesToDates = (
  pricingResult: PricingResult,
  customPricingResult: PricingResult
): PricingDayObject[] => {
  console.log(customPricingResult);
  return Object.entries(pricingResult).map((v) => ({
    id: `${+v[0].split("/")[0]}-${+v[0].split("/")[1]}-${+v[0].split("/")[2]}`,
    day: +v[0].split("/")[0],
    month: +v[0].split("/")[1],
    year: +v[0].split("/")[2],
    changed: customPricingResult && customPricingResult[v[0]] != null,
    price:
      customPricingResult && customPricingResult[v[0]] != null
        ? customPricingResult[v[0]].price
        : v[1].price,
    suggestedPrice: v[1].price,
    trend: v[1].trend,
    correction: v[1].correction,
    advancedStrategies: v[1].advancedStrategies,
    userPrice:
      customPricingResult && customPricingResult[v[0]] != null
        ? customPricingResult[v[0]].price
        : 0,
  }));
};

export const calcDaysDiff = (dateFrom: Date, dateTo: Date): number => {
  if (!dateFrom || !dateTo) {
    return 0;
  }
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = dateFrom.getTime();
  const secondDate = dateTo.getTime();

  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};
