import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PropertyAttributes } from "../interfaces/property-attributes";
import { RevAppUser } from "../interfaces/rev-app-user";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  user: RevAppUser;
  usersCollection: AngularFirestoreCollection<RevAppUser>;
  constructor(private afs: AngularFirestore) {
    this.usersCollection = this.afs.collection<RevAppUser>("userData");
  }

  getAllUsers(): Observable<RevAppUser[]> {
    return this.usersCollection.valueChanges();
  }

  getUserRoles(): string[] {
    const roles = ["super_admin", "admin", "plain", "subscribed"];
    return roles;
  }

  getUserDisplayPrecision(): number[] {
    const displayPrecisionList = [0, 1, 2];
    return displayPrecisionList;
  }

  changeUserRole(role: string, uid: string) {
    return this.usersCollection.doc(uid).update({ role: role });
  }

  changeUserDisplayPrecision(displayPrecision: number, uid: string) {
    return this.usersCollection.doc(uid).update({ displayPrecision: displayPrecision });
  }

  getAllPropertiesOfUser(uid: string): Observable<PropertyAttributes[]> {
    return this.afs
      .collection("userData")
      .doc(uid)
      .collection("properties", (ref) => ref.orderBy("timestamp", "desc"))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as PropertyAttributes;
            const id = a.payload.doc.id;
            return { ...data, propertyId: id };
          })
        )
      );
  }
}
