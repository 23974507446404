import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WebhotelierService } from "src/app/services/webhotelier.service";

@Component({
  selector: "revapp-web-hotelier-input-dialog",
  templateUrl: "./web-hotelier-input-dialog.component.html",
  styleUrls: ["./web-hotelier-input-dialog.component.scss"],
})
export class WebHotelierInputDialogComponent implements OnInit {
  webHotelierForm: FormGroup;
  error: boolean = false;
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<WebHotelierInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private _fb: FormBuilder,
    private webhotelierService: WebhotelierService
  ) {}

  ngOnInit(): void {
    this.webHotelierForm = this._fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      property: [null, [Validators.required]],
    });
  }

  submit() {
    this.loading = true;
    this.webhotelierService
      .getPropertyRoomsWebHotelier({
        username: this.webHotelierForm.get("username").value,
        password: this.webHotelierForm.get("password").value,
        property: this.webHotelierForm.get("property").value,
      })
      .then((res) => {
        this.loading = false;
        if (res) {
          this.dialogRef.close();
          this.error = false;
        } else {
          this.error = true;
        }
      })
      .catch(() => {
        this.loading = false;
        this.error = true;
      });
  }
}
