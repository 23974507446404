<div *ngIf="post" class="blog-list-item">
  <img
    *ngIf="post.imageUrl && post.imageUrl.length > 0"
    [ngStyle]="{ 'background-image': 'url(' + post.imageUrl + ')' }"
  />
  <img
    *ngIf="!post.imageUrl || post.imageUrl.length === 0"
    class="no-image"
    [ngStyle]="{
      'background-image':
        'url(https://firebasestorage.googleapis.com/v0/b/rev-app-dynamic.appspot.com/o/RevAppOtherLogo.jpeg?alt=media&token=b18ae7b1-2b84-4c2f-9801-c79372190c46)'
    }"
  />
  <p class="date">{{ post.timestamp | date: "MMM d, y" }}</p>
  <p class="title">{{ post.title }}</p>
  <p class="full-text" *ngIf="post && post.paragraphs && post.paragraphs[0]">
    {{ post.paragraphs[0] | slice: 0:220 }}
    <ng-container *ngIf="post.paragraphs[0].length > 220"
      >&nbsp;...</ng-container
    >
  </p>
  <p
    class="link"
    tabindex="0"
    role="link"
    [routerLink]="['/blog-post/' + post.postId]"
  >
    Read more...
  </p>
</div>
