import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import {
  faEnvelope,
  faMapMarked,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "revapp-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent implements OnInit {
  faMapMarked = faMapMarked;
  faMobileAlt = faMobileAlt;
  faEnvelope = faEnvelope;
  constructor() {}

  ngOnInit(): void {}
}
