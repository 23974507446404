import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { BlogPost } from "src/app/interfaces/blog-post";
import { BlogCrudService } from "src/app/services/blog-crud.service";

@Component({
  selector: "revapp-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
  blogPosts: Observable<BlogPost[]> = this.blogCrudService.getAllBlogPosts();
  constructor(private blogCrudService: BlogCrudService) {}

  ngOnInit(): void {}
}
