<li>
  <div class="photo">
    <img *ngIf="user.photoURL" [src]="user.photoURL" />
    <div *ngIf="!user.photoURL" class="placeholder"></div>
  </div>
  <div class="details">
    <p>Display Name: {{ user.displayName }}</p>
    <p>Email: {{ user.email }}</p>
  </div>
  <div class="displayPrecision">
    <label>Display Precision</label>
    <mat-radio-group 
      aria-labelledby="radio-label"
      [(ngModel)]="user.displayPrecision"
      (change)="precisionChanged.emit({ event: $event, uid: user.uid })"
    >
      <mat-radio-button [value]="0">
        0
      </mat-radio-button>
      <mat-radio-button [value]="1">
        1
      </mat-radio-button>
      <mat-radio-button [value]="2">
        2
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="role">
    <label id="radio-label">User role</label>
    <mat-radio-group
      aria-labelledby="radio-label"
      [(ngModel)]="user.role"
      (change)="roleChanged.emit({ event: $event, uid: user.uid })"
    >
      <mat-radio-button
        [disabled]="role === 'super_admin'"
        *ngFor="let role of roles"
        [value]="role"
      >
        {{ role }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</li>
