import { Component, Inject, OnInit } from "@angular/core";
import { getCurrencySymbol } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "price-tiers-advanced-menu",
  templateUrl: "./price-tiers-advanced-menu.component.html",
  styleUrls: ["./price-tiers-advanced-menu.component.scss"],
})

export class PriceTiersAdvancedMenuComponent implements OnInit {
  priceAdjustmentTierForm: FormGroup;

  tierPricePercentAbsolute: boolean = false;
  numberOfTiers: number = 4;
  selectedTierValues: number[] = null;
  previousTiers: number = 0;
  selectedCurrency = "EUR";
  currencySymbol = "";


  constructor(
    public dialogRef: MatDialogRef<PriceTiersAdvancedMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      tierPricePercentAbsolute: boolean,
      selectedTierValues: number[],
    },
    private _fb: FormBuilder,
  ) { }

  ngOnInit(): void {

    const { tierPricePercentAbsolute, selectedTierValues } = this.data;
    this.selectedTierValues = selectedTierValues;
    this.tierPricePercentAbsolute = tierPricePercentAbsolute;

    this.priceAdjustmentTierForm = this._fb.group({
      adjustmentType: [(this.tierPricePercentAbsolute ? "0" : "1"), [Validators.required]],
      numberOfTiers: [this.selectedTierValues.length.toString(), [Validators.required]]
    });

    this.onPropertySelectionChange({value:this.selectedTierValues.length});
    for (var i : number = 0; i < this.selectedTierValues.length; ++i) {
      const val = Number(this.selectedTierValues[i].toFixed(tierPricePercentAbsolute? 4 : 2))*(tierPricePercentAbsolute ? 100 : 1);
      this.priceAdjustmentTierForm.controls['priceAdjustmentTier_' + i].setValue(val.toFixed(2));
    }

    this.currencySymbol = getCurrencySymbol(this.selectedCurrency, "narrow");
  }
   

  onPropertySelectionChange(ob) {
    let currentTiers : number = Number(ob.value);

    if (this.previousTiers > currentTiers) {
      for (var i : number = currentTiers; i < this.previousTiers; ++i) {
        this.priceAdjustmentTierForm.removeControl('priceAdjustmentTier_' + i);
      }
      if (this.selectedTierValues.length != currentTiers) {
        for (var i : number = this.previousTiers; i >= currentTiers; --i) {
          this.selectedTierValues.splice(i,1);
        }
      }
    } else {
      for (var i : number = this.previousTiers; i < currentTiers; ++i) {
        this.priceAdjustmentTierForm.addControl(
          'priceAdjustmentTier_' + i,
          this._fb.control(
            { disabled: i==0, value: "0.00" }, Validators.required));
      }
      if (this.selectedTierValues.length != currentTiers) {
        for (var i : number = this.selectedTierValues.length; i < currentTiers; ++i) {
          this.selectedTierValues.push(0);
        }
      }
    }

    this.numberOfTiers = currentTiers;
    this.previousTiers = currentTiers;
  }

    submit() {

      this.tierPricePercentAbsolute = this.priceAdjustmentTierForm.get('adjustmentType').value === '0';
      for (var i : number = 0; i < this.selectedTierValues.length; ++i) {
        this.selectedTierValues[i] = Number((this.priceAdjustmentTierForm.get('priceAdjustmentTier_' + i).value / ( this.tierPricePercentAbsolute ? 100 : 1)).toFixed(this.tierPricePercentAbsolute? 4 : 2));
      }

      this.dialogRef.close({ data: {
        status:'success',
        tierPriceStrategyEnabled: true,
        tierPricePercentAbsolute: this.tierPricePercentAbsolute,
        tierPriceStrategyArray: this.selectedTierValues,
      }
    });
  }
}
