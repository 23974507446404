import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { MatSelectChange } from "@angular/material/select";

import { UserService } from "src/app/services/user.service";
import { FormService } from "src/app/services/form.service";
import firebase from "firebase/app";

import {} from "google-maps";
import { Loader } from "@googlemaps/js-api-loader";
import {
  faArrowRight,
  faArrowLeft,
  faCloudUploadAlt,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import {
  PropertyAttributes,
  PropertyAttributesClass,
} from "src/app/interfaces/property-attributes";
import { StateService } from "src/app/services/state.service";
import { PropertyCRUDService } from "src/app/services/property-crud.service";
import { CommonDialogService } from "src/app/components/common-dialog/common-dialog.service";

@Component({
  selector: "revapp-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit, AfterViewInit, OnDestroy {
  DEFAULT_IMAGE_URL = "../../../assets/images/default_property.jpg";

  faWindowClose = faWindowClose;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faCloudUploadAlt = faCloudUploadAlt;
  basicPropertyOptionsForm: FormGroup;
  facilitiesFormGroup: FormGroup;
  amenitiesFormGroup: FormGroup;
  previewFormGroup: FormGroup;
  starsList = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];
  totalScoreList = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];
  facilitiesData = [
    { value: "checkin247", name: "24/7 Check In" },
    { value: "reception247", name: "24/7 Reception" },
    { value: "aiportShuttle", name: "Airport Shuttle" },
    { value: "bar", name: "Bar" },
    { value: "beachFront", name: "Beach Front" },
    { value: "cityCenter", name: "City Center" },
    { value: "elevator", name: "Elevator" },
    { value: "gym", name: "Gym" },
    { value: "landmarks", name: "Landmarks" },
    { value: "luggageDropOff", name: "Luggage Drop Off" },
    { value: "parking", name: "Parking" },
    { value: "petsAllowed", name: "Pets Allowed" },
    { value: "pool", name: "Pool" },
    { value: "restaurant", name: "Restaurant" },
    { value: "tubeStation10mins", name: "Tube Station (<10mins)" },
  ];
  amenitiesData = [
    { value: "airconditioning", name: "Air Conditioning" },
    { value: "balcony", name: "Balcony" },
    { value: "breakfast", name: "Breakfast" },
    { value: "hairDryer", name: "Hair Dryer" },
    { value: "iron", name: "Iron" },
    { value: "kitchen", name: "Kitchen" },
    { value: "shampoo", name: "Shampoo" },
    { value: "towels", name: "Towels" },
    { value: "tv", name: "TV" },
    { value: "view", name: "View" },
    { value: "washingMachine", name: "Washing Machine" },
    { value: "wifi", name: "Wifi" },
  ];
  propertySummary: string;
  destroy$ = new Subject<void>();
  selectedFacilities: { value: string; name: string }[] = [];
  selectedAmenities: { value: string; name: string }[] = [];
  imgUploadSrc = this.DEFAULT_IMAGE_URL;
  selectedFile: File = null;
  isCheckPricesBtnDisabled = true;
  hasImage = false;

  loader: Loader = new Loader({
    apiKey: "AIzaSyBbt5XpS597iauM-_b4tL8imNC7Lv_4MSQ",
    version: "weekly",
    libraries: ["places"],
  });
  propertyTypeOptions: { value: number; label: string }[] = [
    {
      value: null,
      label: "--",
    },
    {
      value: 1,
      label: "Apartment",
    },
    {
      value: 2,
      label: "Apartment Hotel",
    },
    {
      value: 3,
      label: "Hostel",
    },
    {
      value: 4,
      label: "Hotel",
    },
    {
      value: 5,
      label: "Resort",
    },
  ];
  user$: Observable<firebase.User>;
  user: firebase.User;
  activePropertyItem: PropertyAttributes;
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private stateService: StateService,
    private formService: FormService,
    private _crudSrv: PropertyCRUDService,
    private _commonDialogSrv: CommonDialogService
  ) {
    this.user$ = this.userService.getUser();
    this.user = this.userService.getUserStatic();
  }

  ngOnInit(): void {
    this._initForms();
    this._initFormSubscriptions();
    this._initSubscriptions();
    this.activePropertyItem = this.stateService.getActivePropertyAttributesStatic();
    if (this.activePropertyItem) {
      this.isCheckPricesBtnDisabled = false;
      this._populateBasicPropertyOptionsForm(this.activePropertyItem);
      this._populateCheckBoxesForms(this.activePropertyItem);
      this._populatePreviewForm(this.activePropertyItem);
    }
  }

  ngAfterViewInit(): void {
    this._initGoogleAutocomplete();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _initForms(): void {
    this.basicPropertyOptionsForm = this._formBuilder.group({
      propertyTypeControl: [
        { value: null, disabled: false },
      ],
      starsControl: [
        { value: null, disabled: true },
      ],
      locationControl: [
        { value: null, disabled: false }, 
        Validators.required
      ],
      renovationYearControl: [
        { value: null, disabled: false },
        [
          Validators.min(1950),
          Validators.max(new Date().getFullYear() + 10),
        ],
      ],
      guestControl: [
        { value: null, disabled: false },
        [Validators.min(1)],
      ],
      roomsControl: [
        { value: null, disabled: false },
        [Validators.min(1)],
      ],
      floorControl: [
        { value: null, disabled: false },
        [Validators.min(0)],
      ],
      totalScoreControl: [
        { value: null, disabled: false },
      ],
      postalCode: [null],
      locality: [null],
    });

    this.facilitiesFormGroup = this._formBuilder.group({
      facilities: new FormArray([]),
    });

    this.amenitiesFormGroup = this._formBuilder.group({
      amenities: new FormArray([]),
    });

    this.previewFormGroup = this._formBuilder.group({
      propertyImageUrlControl: [{ value: "", disabled: false }],
      propertyNameControl: [{ value: "", disabled: false }],
    });

    this._addCheckboxes(this.facilitiesData, this.facilitiesFormArray);
    this._addCheckboxes(this.amenitiesData, this.amenitiesFormArray);
  }

  private _initFormSubscriptions(): void {
    this.basicPropertyOptionsForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe((formObj) => {
        const {
          propertyTypeControl,
          guestControl,
          roomsControl,
          starsControl,
        } = formObj;
        const _type = this.propertyTypeOptions.find(
          (obj) => obj.value === propertyTypeControl
        );
        this.propertySummary = [
          `${_type.label === "--" ? "" : _type.label}`,
          `${_type.value === 4 ? starsControl + " star(s)" : ""}`,
          `${guestControl === undefined || guestControl === null ?  "" : guestControl + " guest(s)" }`,
          `${roomsControl === undefined || roomsControl === null ?  "" : roomsControl + " room(s)" }`,
        ]
          .filter((text) => !!text)
          .join(" | ");
      });

    this.facilitiesFormGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe((formObj) => {
        const { facilities } = formObj;
        this.selectedFacilities = facilities
          .map((v, i) => (v ? this.facilitiesData[i] : null))
          .filter((v) => v !== null);
      });

    this.amenitiesFormGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe((formObj) => {
        const { amenities } = formObj;
        this.selectedAmenities = amenities
          .map((v, i) => (v ? this.amenitiesData[i] : null))
          .filter((v) => v !== null);
      });
    this.previewFormGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe((formObj) => {
        if (
          formObj.propertyImageUrlControl &&
          formObj.propertyImageUrlControl.length > 0
        ) {
          this.hasImage = true;
          this.imgUploadSrc = formObj.propertyImageUrlControl;
        } else {
          this.hasImage = false;
          this.imgUploadSrc = this.DEFAULT_IMAGE_URL;
        }
      });
  }

  private _initSubscriptions(): void {
    this.formService.enableCheckPricesBtn$
      .pipe(takeUntil(this.destroy$))
      .subscribe((bool) => {
        this.isCheckPricesBtnDisabled = !bool;
      });
  }

  private _populateBasicPropertyOptionsForm(
    activePropertyItem: PropertyAttributes
  ): void {
    const {
      propertyType,
      location,
      renovationYear,
      maxNumberOfGuests,
      numberOfRooms,
      floor,
      totalScore,
      postalCode,
      locality,
    } = activePropertyItem;
    this.basicPropertyOptionsForm
      .get("propertyTypeControl")
      .patchValue(propertyType);
    this.basicPropertyOptionsForm.get("locationControl").patchValue(location);
    this.basicPropertyOptionsForm
      .get("renovationYearControl")
      .patchValue(renovationYear);
    this.basicPropertyOptionsForm
      .get("guestControl")
      .patchValue(maxNumberOfGuests);
    this.basicPropertyOptionsForm.get("roomsControl").patchValue(numberOfRooms);
    this.basicPropertyOptionsForm.get("floorControl").patchValue(floor);
    this.basicPropertyOptionsForm
      .get("totalScoreControl")
      .patchValue(totalScore);
    this.basicPropertyOptionsForm.get("postalCode").patchValue(postalCode);
    this.basicPropertyOptionsForm.get("locality").patchValue(locality);
    this.basicPropertyOptionsForm.markAsDirty();
  }

  private _populateCheckBoxesForms(
    activePropertyItem: PropertyAttributes
  ): void {
    const checkedFacilities = this.facilitiesData.map(
      (obj) => !!activePropertyItem[obj.value]
    );

    const checkedAmenities = this.amenitiesData.map(
      (obj) => !!activePropertyItem[obj.value]
    );

    this.facilitiesFormGroup.get("facilities").patchValue(checkedFacilities);
    this.amenitiesFormGroup.get("amenities").patchValue(checkedAmenities);
  }

  private _populatePreviewForm(activePropertyItem: PropertyAttributes): void {
    this.imgUploadSrc = activePropertyItem.imageUrl
      ? activePropertyItem.imageUrl
      : this.imgUploadSrc;
    this.previewFormGroup
      .get("propertyNameControl")
      .patchValue(activePropertyItem.name);
    this.previewFormGroup
      .get("propertyImageUrlControl")
      .patchValue(activePropertyItem.imageUrl);
    this.hasImage =
      activePropertyItem.imageUrl !==
      "../../../assets/images/default_property.jpg";
  }

  private _initGoogleAutocomplete(): void {
    if (document.querySelectorAll(`[data-autocomplete="property-location"]`)) {
      const autocompletePropertyLocationInput: HTMLInputElement = document.querySelectorAll(
        `[data-autocomplete="property-location"]`
      )[0] as HTMLInputElement;
      if (window.google && google && google.maps) {
        this.loadAutocomplete(autocompletePropertyLocationInput);
      } else {
        this.loader.load().then(() => {
          this.loadAutocomplete(autocompletePropertyLocationInput);
        });
      }
    }
  }

  private _addCheckboxes(list: any[], formArray: FormArray): void {
    list.forEach(() => formArray.push(new FormControl(false)));
  }

  get facilitiesFormArray() {
    return this.facilitiesFormGroup.controls.facilities as FormArray;
  }

  get amenitiesFormArray() {
    return this.amenitiesFormGroup.controls.amenities as FormArray;
  }

  onPropertyTypeSelect(event: MatSelectChange): void {
    const { starsControl } = this.basicPropertyOptionsForm.controls;
    if (event.value === 4) {
      // if it is hotel
      starsControl.enable();
    } else {
      starsControl.patchValue(null);
      starsControl.disable();
    }
  }

  private loadAutocomplete(
    autocompletePropertyLocationInput: HTMLInputElement
  ) {
    const autocompletePropertyLocation = new google.maps.places.Autocomplete(
      autocompletePropertyLocationInput
    );
    autocompletePropertyLocation.setFields([
      "address_components",
      "geometry",
      "icon",
      "name",
    ]);
    autocompletePropertyLocation.addListener("place_changed", () => {
      const place = autocompletePropertyLocation.getPlace();
      this.basicPropertyOptionsForm.controls["locationControl"].setValue(
        autocompletePropertyLocationInput.value
      );
      if (place.address_components) {
        place.address_components.forEach(
          (addressComponent: google.maps.GeocoderAddressComponent) => {
            if (
              addressComponent.types &&
              addressComponent.types.includes("postal_code")
            ) {
              this.basicPropertyOptionsForm.controls["postalCode"].setValue(
                addressComponent.long_name
              );
            }
            if (
              addressComponent.types &&
              addressComponent.types.includes("locality")
            ) {
              this.basicPropertyOptionsForm.controls["locality"].setValue(
                addressComponent.long_name
              );
            }
          }
        );
      }
    });
  }

  private _valuesFromBasicPropertyOptionsForm(): any {
    const {
      locationControl,
      postalCode,
      locality,
      propertyTypeControl,
      guestControl,
      floorControl,
      roomsControl,
      totalScoreControl,
      renovationYearControl,
    } = this.basicPropertyOptionsForm.value;
    return {
      location: locationControl,
      postalCode,
      locality,
      propertyType: propertyTypeControl,
      maxNumberOfGuests: guestControl,
      floor: floorControl,
      numberOfRooms: roomsControl,
      totalScore: totalScoreControl,
      renovationYear: renovationYearControl,
    };
  }

  private _getCheckBoxesValues(list: { value: string; name: string }[]): any {
    return list
      .map((obj) => obj.value)
      .reduce((acc, cur) => ({ ...acc, [cur]: true }), {});
  }

  private _createFormRequest(): { value: PropertyAttributes } {
    let value = new PropertyAttributesClass();
    value = {
      ...value,
      ...this._valuesFromBasicPropertyOptionsForm(),
      ...this._getCheckBoxesValues(this.selectedFacilities),
      ...this._getCheckBoxesValues(this.selectedAmenities),
      name: this.previewFormGroup.get("propertyNameControl").value,
      imageUrl: this.previewFormGroup.get("propertyImageUrlControl").value
        ? this.previewFormGroup.get("propertyImageUrlControl").value
        : ((this.activePropertyItem !== undefined && this.activePropertyItem !== null &&
          this.activePropertyItem.imageUrl!==undefined && this.activePropertyItem.imageUrl !== null )
        ? this.activePropertyItem.imageUrl
        : ""),
    };

    return {
      value,
    };
  }

  onSave() {
    const form = this._createFormRequest();
    form.value.userId = this.user.uid;
    form.value.timestamp = Date.now();
    console.log("|---- on Save Action ----|", { form });
    if (this.user && !this.activePropertyItem) {
      this.formService.savePropertyDataToFirebase(form.value, this.user.uid);
      return;
    }
    if (this.user && this.activePropertyItem) {
      form.value.userId = this.user.uid;
      form.value.timestamp = Date.now();
      form.value.propertyId = this.activePropertyItem.propertyId;
      const message: string ='Property has been updated! Press the "MY PROPERTIES" button to see your property list or press the "CHECK PRICES" to set property prices.'
      this._crudSrv.saveUpdatedProperty(form.value, this.user.uid, message);
      return;
    }
  }

  showFacilityValues() {
    return this.facilitiesFormGroup.getRawValue();
  }

  showAmenityValues() {
    return this.amenitiesFormGroup.getRawValue();
  }
}
