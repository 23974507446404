<h1 mat-dialog-title>WebHotelier login</h1>
<p *ngIf="error" class="error">There was an error. Please try again. :(</p>
<div mat-dialog-content>
  <form [formGroup]="webHotelierForm">
    <mat-form-field appearance="fill">
      <mat-label>Username</mat-label>
      <input matInput required formControlName="username" type="text" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Password</mat-label>
      <input matInput required formControlName="password" type="password" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Property ID</mat-label>
      <input matInput required formControlName="property" type="text" />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <mat-spinner *ngIf="loading" diameter="24"></mat-spinner>
  <ng-container *ngIf="!loading">
    <button mat-button color="warn" (click)="dialogRef.close()">Cancel</button>
    <button mat-button color="primary" (click)="submit()" cdkFocusInitial>
      Get data
    </button>
  </ng-container>
</div>
