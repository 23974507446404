import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { PropertyAttributes } from "src/app/interfaces/property-attributes";
import { RevAppUser } from "src/app/interfaces/rev-app-user";
import { AdminService } from "src/app/services/admin.service";
import { StateService } from "src/app/services/state.service";

@Component({
  selector: "revapp-user-property",
  templateUrl: "./user-property.component.html",
  styleUrls: ["./user-property.component.scss"],
})
export class UserPropertyComponent implements OnInit {
  users$: Observable<RevAppUser[]> = this.adminService.getAllUsers();
  selectedUser: RevAppUser = null;
  selectedUserProperties$: Observable<PropertyAttributes[]> | null;
  selectedUserProperty: PropertyAttributes = null;
  constructor(
    private adminService: AdminService,
    private router: Router,
    private _stateService: StateService
  ) {}

  ngOnInit(): void {}

  loadProperties(uid: string) {
    this.selectedUserProperties$ = this.adminService.getAllPropertiesOfUser(
      uid
    );
  }

  public onButtonClick(): void {
    this._stateService.setActivePropertyAttributes(this.selectedUserProperty);
    this.router.navigate([
      "/competitors",
      {
        propertyId: this.selectedUserProperty.propertyId,
        userId: this.selectedUser.uid,
      },
    ]);
  }
}
