import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";

import { TeamComponent } from "./views/team/team.component";
import { ContactComponent } from "./views/contact/contact.component";
import { HelpCenterComponent } from "./views/help-center/help-center.component";
import { ProductComponent } from "./views/product/product.component";
import { IntroPageComponent } from "./views/intro-page/intro-page.component";
import { HomeComponent } from "./product/home/home.component";
import { FormComponent } from "./product/form/form.component";
import { CheckPricesComponent } from "./product/check-prices/check-prices.component";
import { AuthGuard } from "./auth/auth.guard";
import { AdminComponent } from "./views/admin/admin.component";
import { CompetitorsComponent } from "./views/competitors/competitors.component";
import { UserListComponent } from "./views/admin/user-list/user-list.component";
import { UserPropertyComponent } from "./views/admin/user-property/user-property.component";
import { BlogComponent } from "./views/blog/blog.component";
import { NewBlogPostComponent } from "./views/admin/new-blog-post/new-blog-post.component";
import { PostComponent } from "./views/blog/post/post.component";
import { TermsComponent } from "./views/terms/terms.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/intro",
    pathMatch: "full",
  },
  {
    path: "intro",
    component: IntroPageComponent,
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { roles: ["subscribed", "super_admin"] },
  },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: { roles: ["super_admin"] },
    children: [
      { path: "", redirectTo: "users", pathMatch: "full" },
      { path: "users", component: UserListComponent },
      { path: "user-property", component: UserPropertyComponent },
      { path: "new-blog-post", component: NewBlogPostComponent },
    ],
  },
  {
    path: "form",
    component: FormComponent,
    canActivate: [AuthGuard],
    data: { roles: ["subscribed", "super_admin"] },
  },
  {
    path: "check-prices",
    component: CheckPricesComponent,
    canActivate: [AuthGuard],
    data: { roles: ["subscribed", "super_admin"] },
  },
  {
    path: "competitors",
    component: CompetitorsComponent,
    canActivate: [AuthGuard],
    data: { roles: ["super_admin"] },
  },
  {
    path: "team",
    component: TeamComponent,
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  {
    path: "terms-of-service",
    component: TermsComponent,
  },
  {
    path: "blog",
    component: BlogComponent,
  },
  {
    path: "blog-post/:id",
    component: PostComponent,
  },
  {
    path: "help-center",
    component: HelpCenterComponent,
  },
  {
    path: "product",
    component: ProductComponent,
  },
  { path: "**", redirectTo: "" },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: "enabled",
  relativeLinkResolution: "legacy",
};

@NgModule({
  imports: [RouterModule.forRoot(routes, { ...routerOptions })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
