import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BlogPost } from "src/app/interfaces/blog-post";
import { BlogCrudService } from "src/app/services/blog-crud.service";

@Component({
  selector: "revapp-new-blog-post",
  templateUrl: "./new-blog-post.component.html",
  styleUrls: ["./new-blog-post.component.scss"],
})
export class NewBlogPostComponent implements OnInit {
  newBlogPost: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private blogCrudService: BlogCrudService
  ) {}

  ngOnInit(): void {
    this.newBlogPost = this._fb.group({
      date: [null],
      title: [null],
      paragraphs: [null],
      links: [null],
      imageUrl: [null],
    });
  }

  submit() {
    const paragraphFull = this.newBlogPost.value["paragraphs"] as string;
    let paragraphsArray = paragraphFull.split(/\n/);
    let linksArray = this.newBlogPost.value["links"]?.split(",");
    paragraphsArray = paragraphsArray.map((line: string) => {
      if (line.length > 0) return line;
    });
    if (linksArray && linksArray.length > 0) {
      linksArray = linksArray.map((link: string) => {
        if (link.length > 0 && link.trim().length > 0) {
          return link.trim();
        }
      });
    }
    let timestamp = new Date().getTime().toString();

    if (this.newBlogPost.value["date"]) {
      timestamp = new Date(this.newBlogPost.value["date"]).getTime().toString();
    }

    const newBlogPost: BlogPost = {
      timestamp: timestamp,
      title: this.newBlogPost.value["title"] as string,
      paragraphs: paragraphsArray,
      links: linksArray,
      userId: "",
      imageUrl: this.newBlogPost.value["imageUrl"] as string,
      approved: false,
    };

    this.blogCrudService.saveNewBlogPost(newBlogPost).then((res) => {
      this.newBlogPost.reset();
    });
  }
}
