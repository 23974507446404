import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  userProfile$: Observable<any>;

  constructor(
    private _afs: AngularFirestore,
    private _router: Router,
    private _userSrv: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const roles = next.data && next.data.roles;
    const UID = this._userSrv.getUserStatic()
      ? this._userSrv.getUserStatic().uid
      : "";

    if (!UID) {
      this._router.navigate(["/intro"]);
      return false;
    }

    this.userProfile$ = this._afs.collection("userData").doc(UID).get();

    return this.userProfile$.pipe(
      take(1),
      map((userProfileSnapshot) => {
        const role =
          userProfileSnapshot && userProfileSnapshot.data()
            ? userProfileSnapshot.data().role
            : null;
        if (role && roles.includes(role)) {
          return true;
        }
        this._router.navigate(["/intro"]);
        return false;
      })
    );
  }
}
