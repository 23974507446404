import { CurrencyPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { filter, take } from "rxjs/operators";
import { monthNames } from "src/app/constants/monthNames";
import {
  getNumOfMonthBlocks,
  mapPricesToDates,
  PricingDayObject,
  PricingDayObjectExtended,
} from "src/app/constants/utils";
import { ICalendarBlock, IDateLimit } from "src/app/interfaces/calendar-model";
import { FormService, PricingStatus } from "src/app/services/form.service";
import { PropertyCRUDService } from "src/app/services/property-crud.service";
import { weekDaysShort } from "../../constants/weekDays";
import { CalendarDialogComponent } from "../calendar-dialog/calendar-dialog.component";

@Component({
  selector: "revapp-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  providers: [CurrencyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent {
  @Input() displayPrecision: number;
  @Input() propertyItemId: string;
  @Input() set dateLimits(datesObj: IDateLimit) {
    if (!datesObj) {
      return;
    }
    const {
      fromDate,
      toDate,
      pricingResult,
      customPricingResult,
      basePrice,
      currency,
    } = datesObj;
    if (!fromDate || !toDate || fromDate > toDate || !pricingResult) {
      return;
    }
    this.basePrice = basePrice;
    this.selectedCurrency = currency;
    this._mappedPricesToDates = mapPricesToDates(
      pricingResult,
      customPricingResult
    );
    console.log({ _mappedPricesToDates: this._mappedPricesToDates });
    this._createCalendarBlocks(fromDate, toDate, this._mappedPricesToDates);
  }
  monthNames = monthNames;
  weekDaysShort = weekDaysShort;
  calendarBlocks: ICalendarBlock[] = [];
  selectedDay = "";
  dateIdForFirestore: string = "";
  pricingResultDateId: string = "";
  _mappedPricesToDates: PricingDayObject[] = [];
  basePrice: number;
  selectedCurrency = "EUR";

  public algorithmStatus$: Observable<PricingStatus> =
    this.formService.getAlgorithmStatus();

  constructor(
    private _dialog: MatDialog,
    private _currencyPipe: CurrencyPipe,
    private formService: FormService,
    private propertyCrudService: PropertyCRUDService
  ) {}

  private _createCalendarBlocks(
    fromDate: Date,
    toDate: Date,
    mappedPricesToDates: PricingDayObject[]
  ): void {
    const _arrayMonth = [];
    const numOfMonthBlocks = getNumOfMonthBlocks(fromDate, toDate);

    _arrayMonth.length = numOfMonthBlocks;
    _arrayMonth.fill(null);

    this.calendarBlocks = _arrayMonth.map((v, i) => {
      const _month = fromDate.getMonth() + i;
      const _year = fromDate.getFullYear();
      const numOfDays = `${new Date(_year, _month + 1, 0).getDate()}`;

      let startDay = "";
      if (i === 0) {
        startDay = `${new Date(_year, _month, fromDate.getDate()).getDay()}`;
      } else {
        startDay = `${new Date(_year, _month).getDay()}`;
      }

      //Final step
      const _currentLoopingMonth = new Date(_year, _month).getMonth();
      const _currentLoopingYear = new Date(_year, _month).getFullYear();
      const days = mappedPricesToDates.filter((d) => {
        if (
          d.month === _currentLoopingMonth + 1 &&
          d.year === _currentLoopingYear
        ) {
          return d;
        }
      });

      return {
        id: `${new Date(_year, _month).getMonth()}-${new Date(
          _year,
          _month
        ).getFullYear()}`,
        month: `${new Date(_year, _month).getMonth()}`,
        year: `${new Date(_year, _month).getFullYear()}`,
        numOfDays,
        days,
        startDay,
        emptyDays: Array(+startDay)
          .fill(null)
          .map((x, i) => i + 1),
      };
    });
  }

  private _openDialog(data: {
    block: any;
    basePrice?: number;
    currency?: string;
    dayObj: PricingDayObjectExtended;
  }): void {
    console.log("|----- data ----|", { data });
    const dialogRef = this._dialog.open(CalendarDialogComponent, {
      data: {
        ...data,
        propertyItemId: this.propertyItemId,
        dateIdForFirestore: this.dateIdForFirestore,
        pricingResultDateId: this.pricingResultDateId,
        displayPrecision: this.displayPrecision,
      },
      width: "400px",
      panelClass: "custom-dialog-wrapper",
    });
    dialogRef
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe((value: { finalPrice: number | string; resetPrice: boolean }) => {
        let { finalPrice } = value;
        const priceResetToGeneratedSoDelete = value.resetPrice;

        if (priceResetToGeneratedSoDelete) {
          finalPrice = data.dayObj.suggestedPrice
        }

        this.propertyCrudService.updateSuggestedPrice(
          this.propertyItemId,
          this.dateIdForFirestore,
          finalPrice,
          this.pricingResultDateId,
          priceResetToGeneratedSoDelete
        );
        const datePriceElement = document.querySelector(
          ".calendar-day.selected .date-price"
        ) as HTMLParagraphElement;
        switch (this.displayPrecision) {
          case 0: {
            datePriceElement.innerText = this._currencyPipe.transform(
              finalPrice,
              this.selectedCurrency,
              "symbol",
              "1.0-0"
            );
            break;
          }
          case 1: {
            datePriceElement.innerText = this._currencyPipe.transform(
              finalPrice,
              this.selectedCurrency,
              "symbol",
              "1.1-1"
            );
            break;
          }
          case 2: 
          default:{
            datePriceElement.innerText = this._currencyPipe.transform(
              finalPrice,
              this.selectedCurrency,
              "symbol",
              "1.2-2"
            );
            break;
          }
        }

        if (typeof finalPrice === "string") {
          finalPrice = parseFloat(finalPrice);
        }

        data.dayObj.userPrice = finalPrice;
        data.dayObj.price = finalPrice;
        if (priceResetToGeneratedSoDelete) {
          this._switchChangedToToggle(data.dayObj, false);
          data.dayObj.changed = false;
        } else {
          this._switchChangedToToggle(data.dayObj, true);
          data.dayObj.changed = true;
        }
      });
  }

  private _switchChangedToToggle(
    dayObj: PricingDayObjectExtended,
    toggle: boolean
  ): void {
    this.calendarBlocks.forEach((block) => {
      block.days.forEach((day) => {
        if (day.id === dayObj.id) {
          day.changed = toggle;
        }
      });
    });
  }

  public onShowCalendarDialog(obj: {
    block: any;
    basePrice?: number;
    currency?: string;
    dayObj: PricingDayObjectExtended;
  }): void {
    this.selectedDay = [obj.dayObj.day, obj.block.month, obj.block.year].join(
      "-"
    );

    this.dateIdForFirestore = [
      obj.dayObj.year,
      typeof obj.dayObj.month === "string"
        ? parseInt(obj.dayObj.month).toString().padStart(2, "0")
        : obj.dayObj.month.toString().padStart(2, "0"),
      typeof obj.dayObj.day === "string"
        ? parseInt(obj.dayObj.day).toString().padStart(2, "0")
        : obj.dayObj.day.toString().padStart(2, "0"),
    ].join("_");

    this.pricingResultDateId = [
      typeof obj.dayObj.day === "string"
        ? parseInt(obj.dayObj.day).toString().padStart(2, "0")
        : obj.dayObj.day.toString().padStart(2, "0"),
      typeof obj.dayObj.month === "string"
        ? parseInt(obj.dayObj.month).toString().padStart(2, "0")
        : obj.dayObj.month.toString().padStart(2, "0"),
      obj.dayObj.year,
    ].join("/");

    obj.basePrice = this.basePrice;
    obj.currency = this.selectedCurrency;
    this._openDialog(obj);
  }

  public monthId(index: number, monthBlock: ICalendarBlock): string | null {
    return monthBlock ? monthBlock.id : null;
  }

  public dayId(index: number, dayObj: PricingDayObject): string | null {
    return dayObj ? dayObj.id : null;
  }
}
