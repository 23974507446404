import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AngularFirestore, DocumentReference } from "@angular/fire/firestore";
import { CommonDialogType } from "../components/common-dialog/common-dialog.interface";
import { CommonDialogService } from "../components/common-dialog/common-dialog.service";
import { NotificationService } from "src/app/services/notification.service";
import { PropertyAttributes } from "../interfaces/property-attributes";
import { StateService } from "./state.service";
import { UserService } from "./user.service";
import { pruneUndefined } from "../util-functions/utils";
import { map } from "rxjs/operators";
import { CompetitorsAndNeighboringAreas } from "../views/competitors/competitors.component";
import firebase from "firebase/app";
import "firebase/firestore";
@Injectable({
  providedIn: "root",
})
export class PropertyCRUDService {
  constructor(
    public afs: AngularFirestore,
    private stateService: StateService,
    private router: Router,
    private userService: UserService,
    private _commonDialogSrv: CommonDialogService,
    private _notificationService: NotificationService
  ) {}

  saveNewProperty(
    propertyAttributes: PropertyAttributes
  ): Promise<DocumentReference> {
    if (this.userService.getUserStatic()) {
      return this.afs
        .collection("userData")
        .doc(this.userService.getUserStatic().uid)
        .collection("properties")
        .add(propertyAttributes);
    } else {
      return Promise.reject("No user");
    }
  }

  addCompetitorsAndNeighborToProperty(
    competitorsAndNeighboringAreas: CompetitorsAndNeighboringAreas,
    propertyId: string,
    userId: string
  ) {
    if (this.userService.getUserStatic()) {
      return this.afs
        .collection("userData")
        .doc(userId)
        .collection("properties")
        .doc(propertyId)
        .update({
          directCompetitorsList: competitorsAndNeighboringAreas.competitors,
          nearbyNeighborhoodsList:
            competitorsAndNeighboringAreas.neighboringAreas,
        });
    } else {
      return Promise.reject("No user");
    }
  }

  saveArrayOfProperties(
    propertyAttributesArray: PropertyAttributes[],
    username: string,
    password: string,
    property: string
  ): Promise<any> {
    if (this.userService.getUserStatic()) {
      const batch = this.afs.firestore.batch();
      for (let propertyAttributesIndex in propertyAttributesArray) {
        const propertiesRef = this.afs
          .collection("userData")
          .doc(this.userService.getUserStatic().uid)
          .collection("properties")
          .doc().ref;
        batch.set(propertiesRef, {
          ...propertyAttributesArray[propertyAttributesIndex],
          timestamp: Date.now(),
          userId: this.userService.getUserStatic().uid,
          propertyId: propertiesRef.id,
          propertyName: property,
          propertyUser: username,
          propertyPass: password,
        });
      }
      return batch.commit();
    } else {
      return Promise.reject("No user");
    }
  }

  duplicateProperty(propertyAttributes: PropertyAttributes) {
    // Duplication just adds one more in the list
    // The list will refresh automatically thanks to Firebase
    propertyAttributes.name = `Copy of ${propertyAttributes.name}`;
    propertyAttributes.timestamp = Date.now();
    propertyAttributes.propertyId = null;
    this.saveNewProperty(propertyAttributes)
      .then((res) => {
        res.id;
        console.log("Duplicated");
        this._commonDialogSrv.open(
          CommonDialogType.SUCCESS,
          "Success",
          "Property has been duplicated!"
        );
      })
      .catch((error) => {
        this._commonDialogSrv.open(
          CommonDialogType.WARNING,
          "Warning",
          "Property did not duplicate!"
        );
        console.log("Did not duplicate");
      });
  }

  getSingleProperty(
    propertyId: string,
    userId: string
  ): Observable<PropertyAttributes> {
    return this.afs
      .collection("userData")
      .doc(userId)
      .collection("properties")
      .doc(propertyId)
      .valueChanges() as Observable<PropertyAttributes>;
  }

  getActiveProperty(): Observable<PropertyAttributes> {
    return this.afs
      .collection("userData")
      .doc(this.userService.getUserStatic().uid)
      .collection("properties")
      .doc(this.stateService.getActivePropertyAttributesStatic().propertyId)
      .valueChanges() as Observable<PropertyAttributes>;
  }

  getAllProperties(): Observable<PropertyAttributes[]> {
    return this.afs
      .collection("userData")
      .doc(this.userService.getUserStatic().uid)
      .collection("properties", (ref) => ref.orderBy("timestamp", "desc"))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as PropertyAttributes;
            const id = a.payload.doc.id;
            return { ...data, propertyId: id };
          })
        )
      );
  }

  editProperty(propertyAttributes: PropertyAttributes, userId?: string) {
    // userId for ???
    this.stateService.setActivePropertyAttributes(propertyAttributes);
    this.router.navigate(["/form"]);
  }

  saveUpdatedProperty(
    propertyAttributes: PropertyAttributes,
    userId: string,
    message: string,
    notify?: boolean
  ) {
    pruneUndefined(propertyAttributes);
    this.afs
      .collection("userData")
      .doc(userId)
      .collection("properties")
      .doc(propertyAttributes.propertyId)
      .update({ ...propertyAttributes })
      .then(() => {
        this.stateService.setActivePropertyAttributes(propertyAttributes);
        if (notify === undefined || !notify) {
          this._commonDialogSrv.open(
            CommonDialogType.SUCCESS,
            "Success",
            message
          );
        } else {
          this._notificationService.setNotification(message);
        }
      })
      .catch((error) => {
        console.log("Could not update:", error);
        this._commonDialogSrv.open(
          CommonDialogType.WARNING,
          "Warning",
          "Property did not update!"
        );
      });
  }

  deleteProperty(propertyId: string) {
    this.afs
      .collection("userData")
      .doc(this.userService.getUserStatic().uid)
      .collection("properties")
      .doc(propertyId)
      .delete()
      .then((res) => {
        console.log("Deleted", {
          res,
          propertyId,
          uid: this.userService.getUserStatic().uid,
        });
        this.stateService.setActivePropertyAttributes(null);
        this._commonDialogSrv.open(
          CommonDialogType.SUCCESS,
          "Success",
          "Property has been deleted!"
        );
        this._commonDialogSrv.close().subscribe(() => {
          if (this.router.url === "/home") {
            return;
          }
          this.router.navigate(["/home"]);
        });
      })
      .catch((error) => {
        console.log("Did not delete");
        this._commonDialogSrv.open(
          CommonDialogType.WARNING,
          "Warning",
          "Property did not delete!"
        );
      });
  }

  deleteLastUserSuggestedPrice(
    propertyId: string,
    firestoreDateId: string,
    pricingResultDateId: string
  ) {
    this.afs
      .collection("userData")
      .doc(this.userService.getUserStatic().uid)
      .collection("properties")
      .doc(propertyId)
      .collection("results")
      .doc(firestoreDateId)
      .update({
        lastSuggestedPrice: firebase.firestore.FieldValue.delete(),
      })
      .then(() => {
        console.log("Reset price to generated one!");
        this.stateService.updatePriceOnSpecificDate(pricingResultDateId, null);
        this._commonDialogSrv.open(
          CommonDialogType.SUCCESS,
          "Success",
          "Price has been reset!"
        );
      })
      .catch((error) => {
        console.error("Did not delete", error);
        this._commonDialogSrv.open(
          CommonDialogType.WARNING,
          "Warning",
          "Price did not reset!"
        );
      });
  }

  updateSuggestedPrice(
    propertyId: string,
    firestoreDateId: string,
    suggestedPrice: number | string,
    pricingResultDateId: string,
    priceResetToGeneratedSoDelete: boolean
  ) {
    if (typeof suggestedPrice === "string") {
      suggestedPrice = parseFloat(suggestedPrice);
    }
    if (priceResetToGeneratedSoDelete) {
      this.deleteLastUserSuggestedPrice(
        propertyId,
        firestoreDateId,
        pricingResultDateId
      );
    } else {
      this.afs
        .collection("userData")
        .doc(this.userService.getUserStatic().uid)
        .collection("properties")
        .doc(propertyId)
        .collection("results")
        .doc(firestoreDateId)
        .update({
          lastSuggestedPrice: suggestedPrice,
        })
        .then(() => {
          return this.afs
            .collection("userData")
            .doc(this.userService.getUserStatic().uid)
            .collection("properties")
            .doc(propertyId)
            .collection("results")
            .doc(firestoreDateId)
            .collection("suggestedPrices")
            .doc(Date.now().toString())
            .set({
              price: suggestedPrice,
            });
        })
        .then(() => {
          console.log("Price updated successfully");
          this.stateService.updatePriceOnSpecificDate(
            pricingResultDateId,
            suggestedPrice as number
          );
        })
        .catch((error) => {
          console.log(
            "There was an error writing the suggested price to firebase",
            error
          );
        });
    }
  }

  loadNeighboringAreas(): Observable<string[]> {
    console.log("this was called");
    return this.afs
      .collection("neighborhoods")
      .valueChanges()
      .pipe(
        map((actions: { name: string; timestamp: string }[]) => {
          console.log(actions);
          return actions.map((a) => {
            return a.name;
          });
        })
      ) as Observable<string[]>;
  }

  loadCompetitors(): Observable<string[]> {
    return this.afs
      .collection("hotels")
      .valueChanges()
      .pipe(
        map((actions: { name: string; timestamp: string }[]) => {
          console.log("action", actions);
          return actions.map((a) => {
            return a.name;
          });
        })
      ) as Observable<string[]>;
  }
}
