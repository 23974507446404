<h1 mat-dialog-title>WebHotelier AutoSync Details</h1>
<p *ngIf="error" class="error">There was an error. Please try again. :(</p>
<p *ngIf="webHotelierSyncForm.get('onePaxPriceAdjustment').errors?.max" class="error">One-pax Price Adjustment cannot be higher than zero</p>
<p *ngIf="webHotelierSyncForm.get('extraAdult').errors?.min" class="error">Extra Adult Price Adjustment cannot be lower than zero</p>
<p *ngIf="webHotelierSyncForm.get('extraChild').errors?.min" class="error">Extra Child Price Adjustment cannot be lower than zero</p>
<div mat-dialog-content>
  <p class="info">
    {{ updateAutosync 
      ? "You are updating autosync functionality, it is needed to perform a successful sync at least once first." 
      : "In order to start the autosync functionality, it is needed to perform a successful sync at least once first." 
    }}</p>
  <form [formGroup]="webHotelierAutoSyncForm">
    <mat-form-field appearance="fill">
      <mat-label>Autosync Type (hover for help)</mat-label>
      <select matNativeControl matTooltip="Info about the action"
      required formControlName="autosyncType">
        <option value="rolling" title="AutoSync will run each day for the selected period until it reaches the start day. Then the syncing will continue each day for X number of days ahead, as in the initial price check">Rolling Period</option>
        <option value="fixed" title="AutoSync will be run only for the selected period, set during price check. When period passed, there won't be syncing any more">Fixed Period</option>
      </select>
    </mat-form-field>
  </form>


  <p class="mat-caption">Login Credentials</p>
  <form [formGroup]="webHotelierCredForm">
    <mat-form-field appearance="fill">
      <mat-label>Username</mat-label>
      <input matInput required formControlName="username" type="text" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Password</mat-label>
      <input matInput required formControlName="password" type="password" />
    </mat-form-field>
  </form>
    
  <p class="mat-caption">Extra Pricing Details</p>
  <p class="info-additional-price">Price charge for each additional adult or child above 2 adults and 0 child. Also, charge for 1-pax rate</p>
  <form [formGroup]="webHotelierSyncForm">
    <mat-form-field appearance="fill">
      <mat-label>Price adjustment for 1 pax</mat-label>
      <input matInput required formControlName="onePaxPriceAdjustment" type="number" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Price for each extra adult</mat-label>
      <input matInput required formControlName="extraAdult" type="number" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Price for each extra child</mat-label>
      <input matInput required formControlName="extraChild" type="number" />
    </mat-form-field>
  </form>

<p class="mat-caption">Base Room Type</p>
<p class="info-additional-price">Use this property as base room for other properties and provide price adjustments for them</p>
<form [formGroup]="webHotelierBaseRoomForm">
  <mat-checkbox #checkbox formControlName="baseRoomCheck" (change)="showBasePropertyList($event)" >Use property as base for others.</mat-checkbox>
  <mat-form-field *ngIf="checkbox.checked" style="flex-direction: row;" appearance="fill">
    <mat-label>Price Adjustment type</mat-label>
    <select matNativeControl required formControlName="baseRoomAdjustmentType" (change)="resetBasePropertyListValues($event)">
      <option value="absolute">Absolute Price</option>
      <option value="percentage">Price Percentage</option>
    </select>
  </mat-form-field>
  <mat-form-field *ngIf="checkbox.checked" style="flex-direction: row;" appearance="fill">
    <mat-label>Select which properties to update</mat-label>
    <mat-select [(ngModel)]="selectedUserProperties" formControlName="selectedUserPropertiesControl" 
    [compareWith]="propertyComparisonFunction" (selectionChange)="onPropertySelectionChange($event)" multiple>
      <mat-select-trigger>
        {{selectedUserProperties != null && selectedUserProperties.length ? selectedUserProperties.length + ' properties selected' : ''}}
      </mat-select-trigger>
      <mat-option *ngFor="let property of allProperties$ | async" [value]="property" style="white-space: normal;line-height: normal;">{{ property.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field title="{{ property.name }}" appearance="fill" style="flex-direction: row;"
    *ngFor="let property of selectedUserProperties; index as i ">
    <mat-label>{{ (property.name).split(" - ").length > 1 ? (property.name).split(" - ")[1] : property.name }}</mat-label>
    <input *ngIf="webHotelierBaseRoomForm.get('baseRoomAdjustmentType').value=='percentage'" matInput formControlName="priceAdjustment_{{ property.propertyId }}" type="number" min="-100" max="100" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.5" >
    <span *ngIf="webHotelierBaseRoomForm.get('baseRoomAdjustmentType').value=='percentage'" matSuffix>%</span>
    <input *ngIf="webHotelierBaseRoomForm.get('baseRoomAdjustmentType').value=='absolute'" matInput formControlName="priceAdjustment_{{ property.propertyId }}" type="number">
    <span *ngIf="webHotelierBaseRoomForm.get('baseRoomAdjustmentType').value=='absolute'" matSuffix>{{currencySymbol}}</span> 
  </mat-form-field>
</form>
</div>

<div mat-dialog-actions>
  <ng-container *ngIf="loading">
    <div class="content-text">
      <mat-spinner diameter="24"></mat-spinner>
      <p> Syncing prices for... <br> {{syncPropertyName}} </p>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <button mat-button color="warn" (click)="dialogRef.close({ data: 'cancel' })">Cancel</button>
    <button mat-button color="primary" (click)="submit()" cdkFocusInitial>
      Start AutoSync Prices
    </button>
  </ng-container>
</div>
