<!-- Page Label -->
<section class="section-page">
  <revapp-page-label title="Terms of Service"></revapp-page-label>
</section>

<section class="section-area section-area-1 intro-page-section-card">
  <mat-card>
    <div class="terms-container">
      <h2>(1) Introduction</h2>
      <p>
        These terms and conditions govern your use of our website; by using our
        website, you accept these terms and conditions in full. If you disagree
        with any part of these terms and conditions, do not use our website.
      </p>
      <h2>(2) Intellectual property rights</h2>
      <p>
        Unless otherwise stated, we or our licensors own the intellectual
        property rights in the website and material on the website. Subject to
        the license below, all our intellectual property rights are reserved.
      </p>
      <h2>(3) License to use website</h2>
      <p>
        You may view, download for caching purposes only, and print pages from
        the website, provided that: (a) you must not republish material from
        this website (including republication on another website), or reproduce
        or store material from this website in any public or private electronic
        retrieval system - except for paying customers who may store API/Report
        data privately; (b) you must not reproduce, duplicate, copy, sell,
        resell, or otherwise exploit our website or material on our website for
        a commercial purpose, without our consent; Resale of data from RevApp is
        strictly prohibited - reselling access to RevApp data on other platforms
        will result in legal action against you.
      </p>
      <h2>(4) Accounts</h2>
      <p>
        You must register with a valid email that we can send you email to. You
        must not used 'parked domains' or obfuscate your identity to prevent us
        from verifying your account is not fraudulent. You must not register
        duplicate accounts.
      </p>
      <h2>(5) Limitations of liability</h2>
      <p>
        You acknowledge that it would be unreasonable to hold us liable in
        respect of this website and the information on this website.
      </p>
      <p>
        Whilst we endeavour to ensure that the information on this website is
        correct, we do not warrant its completeness or accuracy; nor do we
        commit to ensuring that the website remains available or that the
        material on this website is kept up-to-date.
      </p>
      <p>
        To the maximum extent permitted by applicable law we exclude all
        representations, warranties and conditions (including, without
        limitation, the conditions implied by law of satisfactory quality,
        fitness for purpose and the use of reasonable care and skill).
      </p>
      <p>
        Our liability is limited and excluded to the maximum extent permitted
        under applicable law. We will not be liable for any direct, indirect or
        consequential loss or damage arising under these terms and conditions or
        in connection with our website; and use of our software tool, whether
        arising in tort, contract, or otherwise - including, without limitation,
        any loss of profit, contracts, business, goodwill, data, income, revenue
        or anticipated savings.
      </p>
      <p>
        However, nothing in these terms and conditions shall exclude or limit
        our liability for fraud, for death or personal injury caused by our
        negligence, or for any other liability which cannot be excluded or
        limited under applicable law.
      </p>

      <h2>(6) Restricted access</h2>
      <p>
        Access to certain areas of our website is restricted. We reserve the
        right to restrict access to areas of our website, or indeed our whole
        website, at our discretion.
      </p>
      <p>
        If we provide you with a user ID and password to enable you to access
        restricted areas of our website or other content or services, you must
        ensure that that user ID and password is kept confidential. You accept
        responsibility for all activities that occur under your user ID or
        password.
      </p>
      <p>We may disable your user ID and password at our sole discretion.</p>
      <h2>(7) Payment for services</h2>
      <p>
        Annual payments can be made via Electronic Funds Transfer/Wire transfer
        upon request.
      </p>
      <p>
        You may cancel your account at any time. You accept that you if you no
        longer require the service it is your responsibility to cancel your
        plan. Cancellation functions are available via your login.
      </p>
      <p>
        You cannot get a refund if you change your mind. Your rights for payment
        of services are protected under Greek consumer law if your purchase is
        faulty or doesn't provide what is promised.
      </p>
      <h2>(a) Advanced, Basic, Pro & API services.</h2>
      <p>
        All accounts/plans are provided on a subscription basis. Upon signing up
        for your account we will continue to charge you credit card for the
        agreed monthly or yearly value for the corresponding account until you
        tell us to stop. You may cancel your account at any time. You accept
        that if you no longer require the service it is your responsibility to
        cancel your plan. Cancellation functions are available via your login.
      </p>
      <h2>
        (b) Plug n Play, Early Bird, Proactive Expert and Pro or any other given
        name of the plans Fair Use
      </h2>
      <p>
        All the above accounts/plans provide unlimited access to our database
        within fair use restrictions. If we determine your usage is not for your
        own commercial use or are generated reports with a high frequency we may
        cancel and disable your account without warning. Please contact us first
        if you believe this might be the case. Creating many variations of super
        large reports for massive data export is prohibited. The maximum
        dashboard report size is 3500000 results.
      </p>
      <h2>(8) RevApp.biz Web Site and [all addresses].</h2>
      <p>
        You must not use our website in any way that causes, or may cause,
        damage to the website or impairment of the availability or accessibility
        of the website.
      </p>
      <p>
        You must not use our website in any way which is unlawful, illegal,
        fraudulent or harmful, or in connection with any unlawful, illegal,
        fraudulent or harmful purpose or activity.
      </p>
      <p>
        You must not use our website or website content to copy, publish or send
        mass mailings or spam. By creating and account and downloading lists,
        you agree not to send commercial electronic messages in bulk (spam).
      </p>
      <p>
        You must not use our website to copy, publish or send material which is
        illegal or unlawful, or material which could give rise to legal action
        under International and other applicable law. All material you copy,
        publish or send via our website must not be defamatory, obscene,
        indecent, hateful, discriminatory or inflammatory; such material must
        not infringe any person's intellectual property rights or rights of
        confidence, impinge upon any person's privacy, or constitute incitement
        to commit a crime; further, material must not be misleading, deceptive,
        sexually explicit, threatening, abusive, harassing or menacing.
      </p>
      <p>
        We reserve the right to edit or remove any material posted upon our
        website.
      </p>
      <p>
        We may take such action as we deem appropriate to deal with the posting
        of unsuitable material, including suspending or cancelling your account,
        restricting your access to our website, or commencing legal proceedings
        against you.
      </p>
      <p>
        You must not re-sell or reproduce any of the contents of this website
        without our permission.
      </p>
      <p>
        In respect of all material that you post on our website, you grant to us
        a worldwide, irrevocable, non-exclusive, royalty-free license to use,
        reproduce, adapt, publish, translate and distribute such material in any
        media, together with the right to sub-license such rights.
      </p>
      <h2>(9) Accuracy of Information</h2>
      <p>
        RevApp.biz and its owner will not be liable for any losses or damage
        that may result from use of the website as a consequence of any
        inaccuracies in, or any omissions from, the information, which it may
        contain.
      </p>
      <p>
        We have made reasonable efforts to ensure that the information is
        accurate at the time of inclusion. The information on this website could
        include technical inaccuracies or typographical errors. However, the
        information may be out of date at the time of access by the visitor. We
        are not liable for any error or omission in, or any failure to update,
        the information on this site and any decisions based on the information
        are the sole responsibility of the visitor.
      </p>
      <p>
        We reserve the right to make changes to this website at any time without
        notice.
      </p>
      <h2>(10) Variation</h2>
      <p>
        We may revise these terms and conditions from time-to-time. Please check
        this page regularly to ensure you are familiar with the current version.
      </p>
      <h2>(11) Entire agreement</h2>
      <p>
        These terms and conditions , together with our privacy policy constitute
        the entire agreement between you and us in relation to your use of our
        website, and supersede all previous agreements in respect of your use of
        this website.
      </p>
      <h2>(12) Law and jurisdiction</h2>
      <p>
        This notice will be governed by and construed in accordance with Greek
        law, and any disputes relating to this notice shall be subject to the
        exclusive jurisdiction of the courts of Greece.
      </p>
      <h2>(13) Contact Details</h2>
      <p>
        If you have any questions about terms and conditions, please write to us
        by email to atoulias@revapp.biz
      </p>
      <h2>(14) Not Investment Advice</h2>
      <p>
        The information on the website contains general information about web
        technology usage of publicly and privately traded companies. References
        to specific securities throughout this website are for informational
        purposes only and should not be considered as recommendations for
        purchase or sale.
      </p>
    </div>
  </mat-card>
</section>
