<!-- Page Label -->
<section class="section-page">
  <revapp-page-label title="The Product"></revapp-page-label>
</section>

<!-- Intro Card -->
<section class="intro-page-section-card">
  <mat-card class="extra-surround">
    <h2 class="font-montserrat-extrabold secondary-title text-center">
      Empowering hotel owners and vacation rental operators to thrive!
    </h2>
  </mat-card>
</section>

<!-- Quote Section -->
<section class="section-area quote-area">
  <div class="inner-area inner-area-1">
    <div class="box box-1">
      <div class="quote-widget font-montserrat-black">
        <div class="inner-wrapper">
          <span><fa-icon [icon]="faQuoteLeft"></fa-icon></span>
          <h3>
            RevApp combines science and in-depth industry knowledge to help you
            save time and maximize your room revenues.
          </h3>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- History Section -->
<section class="section-area section-history py-150">
  <div class="inner-area inner-area-1 wide-center-container">
    <div class="box box-1 box-history">
      <mat-card class="history-widget extra-surround">
        <h3 class="font-montserrat-extrabold secondary-title">History</h3>
        <p>The origins of the product are very humble and not fancy at all.</p>
        <p>
          Alex (our cofounder) was managing a couple of apart-hotels. The main
          problem he was facing was the mispricing of his rooms; and the timely
          process for keeping up with the daily market changes and updating the
          prices in the PMS. This is when he contacted his grad-school friend,
          Emmanouil, a professor at IE Business School to help him. Thus, the
          algorithm was initially created as a proprietary tool to help Alex
          efficiently manage the room prices at his apart-hotels.
        </p>
        <p>
          Since then, both the algorithm and the product have been utterly
          evolved to save time and increase revenues for the hotel owners.
        </p>
        <p>
          Its flawless set up takes a few minutes and unlocks unparalleled
          possibilities!
        </p>
      </mat-card>
    </div>
  </div>
</section>

<!-- Full Control Section -->
<section class="section-area section-full-control">
  <div class="inner-area inner-area-1 wide-center-container display-flex">
    <div class="box box-1 box-full-control py-80">
      <mat-card class="full-control-widget extra-surround">
        <h3 class="font-montserrat-extrabold secondary-title">Full Control!</h3>
        <p>
          Do not you agree with algorithm recommendations? Change them! You can
          easily interfere and change any recommended prices if you do not feel
          comfortable. This, also, reinforces our algorithm since it will
          remember your behavior and make adjustable suggestions in the future.
        </p>
      </mat-card>
    </div>
    <div class="box box-2 box-full-control-img">
      <div class="full-control-img-widget"></div>
    </div>
  </div>
</section>

<!-- Steps Section -->
<section class="section-area steps-area pt-400 pb-300">
  <div class="inner-area inner-area-1">
    <div class="box box-1">
      <div class="title-widget">
        <h3 class="font-montserrat-black">How it works</h3>
      </div>
    </div>
  </div>
  <div class="inner inner-area-2">
    <div class="box box-1 mt-100 steps-list-bg">
      <div class="wide-center-container">
        <ul class="steps-list">
          <!-- list item -->
          <li class="steps-list-item">
            <div class="img-container">
              <img
                src="../../../assets/images/Automatic-Property.png"
                alt="Automatic Property Step"
              />
            </div>
            <div class="title-container">
              <span class="step-number font-montserrat-black">1</span>
              <h4 class="font-montserrat-black">Automatic property import</h4>
            </div>
            <div class="points-container">
              <ul class="points">
                <li>API connection or</li>
                <li>Manual entry</li>
              </ul>
            </div>
          </li>

          <li class="steps-list-item">
            <div class="img-container">
              <img
                src="../../../assets/images/Autocatic-room.png"
                alt="Autocatic Room Step"
              />
            </div>
            <div class="title-container">
              <span class="step-number font-montserrat-black">2</span>
              <h4 class="font-montserrat-black">
                Automatic room price generation
              </h4>
            </div>
            <div class="points-container">
              <ul class="points">
                <li>Property & Room Characteristics</li>
                <li>Market dynamics</li>
                <li>Seasonality</li>
              </ul>
              <ul class="points">
                <li>Competitors’ behavior</li>
                <li>Owner’s strategy</li>
              </ul>
            </div>
          </li>

          <li class="steps-list-item">
            <div class="img-container">
              <img
                src="../../../assets/images/PMS-Sync.png"
                alt="PMS Sync Step"
              />
            </div>
            <div class="title-container">
              <span class="step-number font-montserrat-black">3</span>
              <h4 class="font-montserrat-black">PMS synchronization</h4>
            </div>
            <div class="points-container">
              <ul class="points">
                <li>Automatic sync or</li>
                <li>
                  Just press the “sync”<br />button if you have done<br />manual
                  changes!
                </li>
              </ul>
            </div>
          </li>

          <li class="steps-list-item">
            <div class="img-container">
              <img
                src="../../../assets/images/Room-Sold.png"
                alt="Room Sold Step"
              />
            </div>
            <div class="title-container">
              <span class="step-number font-montserrat-black">4</span>
              <h4 class="font-montserrat-black">Room booked!</h4>
            </div>
            <div class="points-container">
              <ul class="points">
                <li>Sit back and relax!</li>
                <li>Spend time in other operations</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- Pricing Section -->
<section id="pricing" name="pricing" class="section-area pricing-area mt-70">
  <div class="inner-area inner-area-1">
    <div class="box box-1">
      <div class="title-widget">
        <h3 class="font-montserrat-black">Pricing</h3>
      </div>
    </div>
  </div>
  <div class="inner-area inner-area-2 wide-center-container mt-70">
    <div class="box box-1">
      <div class="pricing-list">
        <div class="pricing-list-item-container">
          <div class="pricing-list-item">
            <div class="top-section">
              <div class="price-primary-title">
                <span class="font-montserrat-extrabold price">&euro;45</span
                ><span>/month</span>
              </div>
              <p>
                Annual Subscription for up to 50 rooms.<br />
                Then +€3/month for every additional room.
              </p>
              <h2 class="price-second-title font-montserrat-extrabold">
                Plug n Play
              </h2>
            </div>
            <div class="bottom-section">
              <p class="price-top-features">Top Features</p>
              <ul class="price-top-features-list">
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>NO PMS Integration</p>
                </li>
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>
                    Access to RevApp calendar with daily room rates' updates
                  </p>
                </li>
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Updates for one month in advance</p>
                </li>
              </ul>
              <div class="price-button-container">
                <button mat-icon-button [routerLink]="['/contact']">
                  Get Started
                </button>
                <a [routerLink]="['/contact']">or start free trial*</a>
              </div>
            </div>
          </div>
        </div>

        <div class="pricing-list-item-container">
          <div class="pricing-list-item main-pricing">
            <div class="top-section">
              <div class="price-primary-title">
                <span class="font-montserrat-extrabold price">&euro;89</span
                ><span>/month</span>
              </div>
              <p>
                Annual Subscription for up to 50 rooms.<br />
                Then +€3/month for every additional room.
              </p>
              <h2 class="price-second-title font-montserrat-extrabold">
                Early Bird
              </h2>
            </div>
            <div class="bottom-section">
              <p class="price-top-features">Top Features</p>
              <ul class="price-top-features-list">
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Auto sync with your PMS/Channel Manager</p>
                </li>
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Daily room rates updates</p>
                </li>
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Updates for three months in advance</p>
                </li>
              </ul>
              <div class="price-button-container">
                <button mat-icon-button [routerLink]="['/contact']">
                  Get Started
                </button>
                <a [routerLink]="['/contact']">or start free trial*</a>
              </div>
            </div>
          </div>
        </div>

        <div class="pricing-list-item-container">
          <div class="pricing-list-item main-pricing">
            <div class="top-section">
              <div class="price-primary-title">
                <span class="font-montserrat-extrabold price">&euro;119</span
                ><span>/month</span>
              </div>
              <p>
                Annual Subscription for up to 50 rooms.<br />
                Then +€3/month for every additional room.
              </p>
              <h2 class="price-second-title font-montserrat-extrabold">
                Proactive Expert
              </h2>
            </div>
            <div class="bottom-section">
              <p class="price-top-features">Top Features</p>
              <ul class="price-top-features-list">
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Auto sync with your PMS/Channel Manager</p>
                </li>
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Daily room rates updates</p>
                </li>
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Updates for twelve months in advance</p>
                </li>
              </ul>
              <div class="price-button-container">
                <button mat-icon-button [routerLink]="['/contact']">
                  Get Started
                </button>
                <a [routerLink]="['/contact']">or start free trial*</a>
              </div>
            </div>
          </div>
        </div>

        <div class="pricing-list-item-container">
          <div class="pricing-list-item pro-list-item">
            <div class="top-section">
              <h2 class="price-second-title font-montserrat-extrabold">Pro</h2>
            </div>
            <div class="bottom-section">
              <p class="price-top-features">Top Features</p>
              <ul class="price-top-features-list">
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>On demand revenue manager!</p>
                </li>
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Auto sync with your PMS/Channel Manager</p>
                </li>
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Daily room rates updates</p>
                </li>
                <li>
                  <span><fa-icon [icon]="faCheckCircle"></fa-icon></span>
                  <p>Updates for twelve months in advance</p>
                </li>
              </ul>
              <div class="price-button-container">
                <button mat-icon-button [routerLink]="['/contact']">
                  Contact us
                </button>
                <a [routerLink]="['/contact']">or start free trial*</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="price-footnote-asterisk">
        <p>
          *1+1 free months. 1st month free trial for all plans and 1 more month
          free for all annual plans!
        </p>
      </div>
    </div>
  </div>
</section>
<section class="section-area section-proof"></section>

<!-- Need Proof Section -->
<section class="section-area section-proof">
  <div class="inner-area inner-area-1">
    <div class="box box-1">
      <revapp-need-proof></revapp-need-proof>
    </div>
  </div>
</section>
