<!-- Page Label -->
<section class="section-page">
  <revapp-page-label title="Team"></revapp-page-label>
</section>

<!-- Intro Card -->
<section class="intro-page-section-card">
  <div class="inner-wrapper">
    <mat-card>
      <p class="mb-25">
        <strong>RevApp</strong> is not only about data science, but most
        importantly about <strong><i>actual industry insights.</i></strong>
      </p>
      <p>
        It all started when Alexandros, our cofounder, while running his
        apart-hotels, faced the huge pricing hassle and missed revenues problem
        due to mispriced rooms. This is when he asked help from his grad-school
        friend, Emmanouil, currently a professor of decision science at IE
        Business School, in order to develop a proprietary algorithm to help him
        optimize his room revenues.
      </p>
    </mat-card>
  </div>
</section>

<!-- Team  -->
<section class="pt-70 team-section">
  <div class="inner-area wide-center-container">
    <div class="box" *ngFor="let member of memberList; let i = index">
      <!-- team member -->
      <article class="team-member-widget">
        <div class="left-col">
          <div class="img-outer-wrapper">
            <div class="img-wrapper">
              <img
                class="member-img"
                [src]="member.imgPath"
                [alt]="member.imgAlt"
              />
              <div class="rounded-dots"></div>
            </div>
            <p class="member-name text-center">{{ member.name }}</p>
            <p class="member-role text-center">{{ member.role }}</p>
            <div class="social-icon text-center">
              <a href="{{ member.linkedin }}" target="_blank">
                <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="right-col">
          <mat-card>
            <p>{{ member.description }}</p>
          </mat-card>
        </div>
      </article>
    </div>
  </div>
</section>

<!-- RevApp Additional Info -->
<section class="additional-info-section">
  <mat-card>
    <div class="inner-wrapper">
      <p class="mb-50">
        <span class="info-bold">RevApp</span> has been supported within the EIT
        Digital Venture Program 2020 and has been co-founded by EIT Digital. The
        EIT Digital Venture Program 2020 is an ARISE Europe activity. Our
        company is also part of the 8th cycle of EGG Incubation Program, as well
        as has successfully participated in the 3rd cycle of CapsuleT Travel &
        Hospitality Accelerator.
      </p>
      <div class="info-links">
        <p>
          <strong>EIT Digital homepage:</strong
          ><a href="https://www.eitdigital.eu" target="_blank"
            >https://www.eitdigital.eu</a
          >
        </p>
        <p>
          <strong>EIT Digital Venture Program:</strong
          ><a href="https://www.eitdigital.eu/venture-program/" target="_blank"
            >https://www.eitdigital.eu/venture-program/</a
          >
        </p>
        <p>
          <strong>EIT Digital ARISE Europe:</strong
          ><a href="https://www.eitdigital.eu/arise/" target="_blank"
            >https://www.eitdigital.eu/arise/</a
          >
        </p>
        <p>
          <strong>EIT website homepage:</strong
          ><a href="https://eit.europa.eu" target="_blank"
            >https://eit.europa.eu</a
          >
        </p>
        <p>
          <strong>EIT in your language:</strong
          ><a href="https://eit.europa.eu/in-your-language" target="_blank"
            >https://eit.europa.eu/in-your-language</a
          >
        </p>
        <p>
          <strong>EIT RIS:</strong
          ><a
            href="https://eit.europa.eu/our-activities/outreach/eit-regional-innovation-scheme-ris"
            target="_blank"
            >https://eit.europa.eu/our-activities/outreach/eit-regional-innovation-scheme-ris</a
          >
        </p>
      </div>
    </div>
  </mat-card>
</section>
