import { Injectable } from "@angular/core";
import { PricingModel } from "../interfaces/pricing-model";
import { BehaviorSubject, Observable } from "rxjs";
import { DateAndPriceParameters } from "../interfaces/date-and-price-parameters";
import { PropertyAttributes } from "../interfaces/property-attributes";
import { CompetitorsAndNeighboringAreas } from "../views/competitors/competitors.component";

export interface PricingResult {
  [key: string]: {
    price: number;
    order: number;
    trend?: number;
    correction?: number;
    advancedStrategies?: number;
  };
}

@Injectable({
  providedIn: "root",
})
export class StateService {
  activePropertyAttributesStatic: PropertyAttributes;
  activePropertyAttributes: BehaviorSubject<PropertyAttributes> =
    new BehaviorSubject<PropertyAttributes>(null);

  activePricingModelStatic: PricingModel;
  activePricingModel: BehaviorSubject<PricingModel> =
    new BehaviorSubject<PricingModel>(null);

  activeResultsStatic: PricingResult;
  activeResults: BehaviorSubject<PricingResult> =
    new BehaviorSubject<PricingResult>(null);

  activeCustomResultsStatic: PricingResult;
  activeCustomResults: BehaviorSubject<PricingResult> =
    new BehaviorSubject<PricingResult>(null);

  activeCheckPriceParametersStatic: DateAndPriceParameters;
  activeCheckPriceParameters: BehaviorSubject<DateAndPriceParameters> =
    new BehaviorSubject<DateAndPriceParameters>(null);

  activeSuggestedBasePrice: number;
  activeSuggestedBasePriceSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);

  competitorsAndNeighboringAreas: CompetitorsAndNeighboringAreas;
  competitorsAndNeighboringAreasSubject: BehaviorSubject<CompetitorsAndNeighboringAreas> =
    new BehaviorSubject<CompetitorsAndNeighboringAreas>(null);

  constructor() {}

  setActivePropertyAttributes(activePropertyAttributes: PropertyAttributes) {
    this.activePropertyAttributes.next(activePropertyAttributes);
    this.activePropertyAttributesStatic = activePropertyAttributes;
  }

  getActivePropertyAttributes(): Observable<PropertyAttributes> {
    return this.activePropertyAttributes.asObservable();
  }

  getActivePropertyAttributesStatic(): PropertyAttributes {
    return this.activePropertyAttributesStatic;
  }

  setActivePricingModel(pricingModel: PricingModel) {
    this.activePricingModel.next(pricingModel);
    this.activePricingModelStatic = pricingModel;
  }

  getActivePricingModel(): Observable<PricingModel> {
    return this.activePricingModel.asObservable();
  }

  setActiveCompetitorsAndNeighboringAreas(
    competitorsAndNeighboringAreas: CompetitorsAndNeighboringAreas
  ) {
    this.competitorsAndNeighboringAreasSubject.next(
      competitorsAndNeighboringAreas
    );
    this.competitorsAndNeighboringAreas = competitorsAndNeighboringAreas;
  }

  getActiveCompetitorsAndNeighboringAreas(): Observable<CompetitorsAndNeighboringAreas> {
    return this.competitorsAndNeighboringAreasSubject.asObservable();
  }

  getActivePricingModelStatic(): PricingModel {
    return this.activePricingModelStatic;
  }

  setActiveResults(activeResults: PricingResult) {
    this.activeResults.next(activeResults);
    this.activeResultsStatic = activeResults;
  }

  setActiveCustomResults(activeCustomResults: PricingResult) {
    this.activeCustomResults.next(activeCustomResults);
    this.activeCustomResultsStatic = activeCustomResults;
  }

  setActiveCheckPriceParameters(
    activeCheckPriceParameters: DateAndPriceParameters
  ) {
    this.activeCheckPriceParameters.next(activeCheckPriceParameters);
    this.activeCheckPriceParametersStatic = activeCheckPriceParameters;
  }

  updatePriceOnSpecificDate(pricingResultDateId: string, price: number) {
    if (!this.activeCustomResultsStatic) {
      this.activeCustomResultsStatic = {};
    }
    if (!this.activeCustomResultsStatic[pricingResultDateId]) {
      this.activeCustomResultsStatic[pricingResultDateId] = {} as {
        price: number;
        order: number;
        trend?: number;
        correction?: number;
        advancedStrategies?: number;
      };
      // update order as well
      if (this.activeResultsStatic[pricingResultDateId]) {
        this.activeCustomResultsStatic[pricingResultDateId].order = this.activeResultsStatic[pricingResultDateId].order
      }
    }

    if (price) {
      this.activeCustomResultsStatic[pricingResultDateId].price = price;
    } else {
      delete this.activeCustomResultsStatic[pricingResultDateId];
    }

    this.setActiveCustomResults(this.activeCustomResultsStatic);
  }

  getActiveResults(): Observable<PricingResult> {
    return this.activeResults.asObservable();
  }

  getActiveResultsStatic(): PricingResult {
    return this.activeResultsStatic;
  }

  getActiveCustomResults(): Observable<PricingResult> {
    return this.activeCustomResults.asObservable();
  }

  getActiveCustomResultsStatic(): PricingResult {
    return this.activeCustomResultsStatic;
  }

  getActiveCheckPriceParameters(): Observable<DateAndPriceParameters> {
    return this.activeCheckPriceParameters.asObservable();
  }

  getActiveCheckPriceParametersStatic(): DateAndPriceParameters {
    return this.activeCheckPriceParametersStatic;
  }

  setActiveSuggestedBasePrice(price: number) {
    this.activeSuggestedBasePrice = price;
    this.activeSuggestedBasePriceSubject.next(price);
  }

  getActiveSuggestedBasePrice(): Observable<number> {
    return this.activeSuggestedBasePriceSubject.asObservable();
  }

  getActiveSuggestedBasePriceStatic(): number {
    return this.activeSuggestedBasePrice;
  }
}
