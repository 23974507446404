import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonDialogData, mapDialogCssClass } from './common-dialog.interface';

@Component({
  selector: 'revapp-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {

  dialogTitle = '';
  dialogType = '';
  dialogContent = '';
  dialogTypeCssClass = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CommonDialogData
  ) {}

  ngOnInit(): void {
    const { dialogTitle,dialogType, dialogContent  } = this.data;
    this.dialogTitle = dialogTitle;
    this.dialogType = dialogType;
    this.dialogContent = dialogContent;
    this.dialogTypeCssClass = mapDialogCssClass[dialogType] || 'default-dialog';
  }

}
