<section class="user-property-container">
  <h1>Choose a user and then choose a property</h1>
  <div class="user-selection">
    <mat-form-field appearance="fill">
      <mat-label>Select a user</mat-label>
      <mat-select
        (selectionChange)="selectedUserProperties$ = null"
        [(value)]="selectedUser"
      >
        <mat-option>None</mat-option>
        <mat-option *ngFor="let user of users$ | async" [value]="user"
          >{{ user.email }} | {{ user.displayName }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <p *ngIf="selectedUser">
      You selected:
      <strong>{{ selectedUser.email }} | {{ selectedUser.displayName }}</strong>
    </p>
    <button
      mat-flat-button
      color="primary"
      (click)="loadProperties(selectedUser.uid)"
    >
      GET PROPERTIES OF SELECTED USER
    </button>
  </div>
  <div class="user-selection">
    <ng-container
      *ngIf="
        selectedUserProperties$ && (selectedUserProperties$ | async);
        else tryOther
      "
    >
      <mat-form-field appearance="fill">
        <mat-label>Select a property of this user</mat-label>
        <mat-select [(value)]="selectedUserProperty">
          <mat-option>None</mat-option>
          <mat-option
            *ngFor="let property of selectedUserProperties$ | async"
            [value]="property"
            >{{ property.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <p *ngIf="selectedUserProperty">
        You selected: <strong>{{ selectedUserProperty.name }}</strong>
      </p>
    </ng-container>
    <ng-template #tryOther>
      <p>No properties. Try selecting (another) user.</p>
    </ng-template>
  </div>

  <button
    mat-flat-button
    color="primary"
    class="competitor-button"
    [disabled]="!selectedUserProperty"
    (click)="onButtonClick()"
  >
    CHECK PRICE | COMPETITORS
  </button>
</section>
