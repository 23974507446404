import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentReference } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CommonDialogService } from "../components/common-dialog/common-dialog.service";
import { BlogPost } from "../interfaces/blog-post";
import { NotificationService } from "./notification.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class BlogCrudService {
  constructor(
    public afs: AngularFirestore,
    private userService: UserService,
    private _commonDialogSrv: CommonDialogService,
    private _notificationService: NotificationService
  ) {}

  getSingleBlogPost(postId: string): Observable<BlogPost> {
    return this.afs
      .collection("blogPosts")
      .doc(postId)
      .valueChanges() as Observable<BlogPost>;
  }

  getAllBlogPosts(): Observable<BlogPost[]> {
    return this.afs
      .collection("blogPosts", (ref) =>
        ref.where("approved", "==", true).orderBy("timestamp", "desc")
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as BlogPost;
            const id = a.payload.doc.id;
            return { ...data, postId: id };
          })
        )
      );
  }

  getLastThreeBlogPosts(): Observable<BlogPost[]> {
    return this.afs
      .collection("blogPosts", (ref) =>
        ref.where("approved", "==", true).orderBy("timestamp", "desc").limit(3)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as BlogPost;
            const id = a.payload.doc.id;
            return { ...data, postId: id };
          })
        )
      );
  }

  saveNewBlogPost(blogPost: BlogPost): Promise<DocumentReference> {
    if (this.userService.getUserStatic()) {
      return this.afs
        .collection("blogPosts")
        .add({ ...blogPost, userId: this.userService.getUserStatic().uid })
        .then((res) => {
          this._notificationService.setNotification(
            "Blog post saved. Go and approve it to make it appear in the blog."
          );
          return res;
        });
    } else {
      return Promise.reject("No user");
    }
  }
}
