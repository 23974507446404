/* Modules */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatExpansionModule } from "@angular/material/expansion";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import { HttpClientModule } from "@angular/common/http";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { MatRadioModule } from "@angular/material/radio";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

/* Components */
import { AppComponent } from "./app.component";
import { FooterComponent } from "./components/footer/footer.component";
import { TeamComponent } from "./views/team/team.component";
import { ContactComponent } from "./views/contact/contact.component";
import { HelpCenterComponent } from "./views/help-center/help-center.component";
import { LoginSignupDialogComponent } from "./components/login-signup-dialog/login-signup-dialog.component";
import { ProductComponent } from "./views/product/product.component";
import { HomeComponent } from "./product/home/home.component";
import { FormComponent } from "./product/form/form.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { RevListComponent } from "./product/home/rev-list/rev-list.component";
import { IntroPageComponent } from "./views/intro-page/intro-page.component";
import { PageLabelComponent } from "./components/page-label/page-label.component";
import { CheckPricesComponent } from "./product/check-prices/check-prices.component";
import { PropertyItemComponent } from "./product/property-item/property-item.component";
import { CalendarComponent } from "./product/calendar/calendar.component";
import { CalendarDialogComponent } from "./product/calendar-dialog/calendar-dialog.component";
import { CommonDialogComponent } from "./components/common-dialog/common-dialog.component";

/* Icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

/* Misc. */
import { environment } from "../environments/environment";
import { NeedProofComponent } from "./components/need-proof/need-proof.component";
import { AdminComponent } from "./views/admin/admin.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { WebHotelierInputDialogComponent } from "./product/home/web-hotelier-input-dialog/web-hotelier-input-dialog.component";
import { WebHotelierInputDialogSyncComponent } from "./product/home/web-hotelier-input-dialog-sync/web-hotelier-input-dialog-sync.component";
import { WebHotelierInputDialogAutoSyncComponent } from "./product/home/web-hotelier-input-dialog-autosync/web-hotelier-input-dialog-autosync.component";
import { PriceTiersAdvancedMenuComponent } from "./product/check-prices/price-tiers-advanced-menu/price-tiers-advanced-menu.component";
import { CompetitorsComponent } from "./views/competitors/competitors.component";
import { UserListComponent } from "./views/admin/user-list/user-list.component";
import { UserPropertyComponent } from "./views/admin/user-property/user-property.component";
import { UserItemComponent } from "./views/admin/user-list/user-item/user-item.component";
import { ChipsAutocompleteComponent } from "./components/chips-autocomplete/chips-autocomplete.component";
import { BlogComponent } from "./views/blog/blog.component";
import { NewBlogPostComponent } from "./views/admin/new-blog-post/new-blog-post.component";
import { PostComponent } from "./views/blog/post/post.component";
import { BlogListItemComponent } from "./views/blog/blog-list-item/blog-list-item.component";
import { TermsComponent } from "./views/terms/terms.component";
import { SafeUrlPipe } from "./util-functions/safe-url.pipe";
import { PdfViewerModule } from "ng2-pdf-viewer";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FormComponent,
    NavbarComponent,
    RevListComponent,
    IntroPageComponent,
    FooterComponent,
    TeamComponent,
    PageLabelComponent,
    ContactComponent,
    HelpCenterComponent,
    LoginSignupDialogComponent,
    ProductComponent,
    CheckPricesComponent,
    PropertyItemComponent,
    CalendarComponent,
    CalendarDialogComponent,
    CommonDialogComponent,
    NeedProofComponent,
    AdminComponent,
    NotificationComponent,
    WebHotelierInputDialogComponent,
    WebHotelierInputDialogSyncComponent,
    WebHotelierInputDialogAutoSyncComponent,
    PriceTiersAdvancedMenuComponent,
    CompetitorsComponent,
    UserListComponent,
    UserPropertyComponent,
    UserItemComponent,
    ChipsAutocompleteComponent,
    BlogComponent,
    NewBlogPostComponent,
    PostComponent,
    BlogListItemComponent,
    TermsComponent,
    SafeUrlPipe,
  ],
  exports: [SafeUrlPipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatChipsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    FontAwesomeModule,
    MatExpansionModule,
    MatStepperModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: "en-GB",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}
