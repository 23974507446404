export const currenciesMap = {
  "AUD":"AUD",
  "CAD":"CAD",
  "CHF":"CHF",
  "CNY":"CNY",
  "EUR":"EUR",
  "GBP":"GBP",
  "INR":"INR",
  "JPY":"JPY",
  "MXN":"MXN",
  "RUB":"RUB",
  "SAR":"SAR",
  "SEK":"SEK",
  "SGD":"SGD",
  "USD":"USD",
}

export const amountRoundToTwoDecimals = (num: number): number => (Math.round((num + Number.EPSILON) * 100) / 100);
export const amountRoundToOneDecimal = (num: number): number => (Math.round((num + Number.EPSILON) * 10) / 10);
