<div *ngIf="post | async as blogPost; else somethingWrong" class="full-post">
  <div class="main-content">
    <img
      *ngIf="blogPost.imageUrl && blogPost.imageUrl.length > 0"
      [ngStyle]="{ 'background-image': 'url(' + blogPost.imageUrl + ')' }"
    />
    <img
      *ngIf="!blogPost.imageUrl || blogPost.imageUrl.length === 0"
      class="no-image"
      [ngStyle]="{
        'background-image':
          'url(https://firebasestorage.googleapis.com/v0/b/rev-app-dynamic.appspot.com/o/RevAppOtherLogo.jpeg?alt=media&token=b18ae7b1-2b84-4c2f-9801-c79372190c46)'
      }"
    />
    <p class="date">{{ blogPost.timestamp | date: "MMM d, y" }}</p>
    <p class="title">{{ blogPost.title }}</p>
    <p class="full-text" *ngFor="let item of blogPost.paragraphs">{{ item }}</p>
    <br />
    <ng-container *ngIf="blogPost.flag === 'hasPDF' && blogPost.pdfLink">
      <object
        [data]="blogPost.pdfLink + '#toolbar=0&navpanes=0' | safeUrl"
        type="application/pdf"
        width="100%"
        height="800px"
      >
        <p>Your web browser doesn't have a PDF plugin 😔.</p>
        <p
          class="pdf-link"
          tabindex="0"
          role="link"
          (click)="navigateOutside(blogPost.pdfLink)"
        >
          click here to view the file.
        </p>
      </object>
    </ng-container>
    <p
      class="link"
      *ngFor="let link of blogPost.links"
      tabindex="0"
      role="link"
      (click)="navigateOutside(link)"
    >
      {{ link }}
    </p>
  </div>
</div>
<ng-template #somethingWrong>
  <p>Something went wrong. Please go back and try another posts</p>
</ng-template>
