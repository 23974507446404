<mat-card class="proof-widget extra-surround">
  <div class="title-container">
    <h2 class="font-montserrat-black">Need proof?</h2>
  </div>
  <div class="description-container">
    <p class="font-montserrat-semibold">
      Redeem your free trial months; and join us today!
    </p>
  </div>
  <div class="button-container">
    <button [routerLink]="['/contact']" mat-icon-button class="btn-orange">
      ASK FOR DEMO
    </button>
  </div>
</mat-card>
