import { Component, OnInit, Input } from "@angular/core";
import { PropertyAttributes } from "src/app/interfaces/property-attributes";
import { RevAppUser } from "src/app/interfaces/rev-app-user";

@Component({
  selector: "revapp-rev-list",
  templateUrl: "./rev-list.component.html",
  styleUrls: ["./rev-list.component.scss"],
})
export class RevListComponent implements OnInit {
  prevListings = [];
  @Input() user: RevAppUser;
  @Input() set previousListings(list: PropertyAttributes[]) {
    if (!list && !Array.isArray(list)) {
      return;
    }
    this.prevListings = [...list];
  }

  constructor() {}

  ngOnInit(): void {}
}
