import { Injectable } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { PropertyAttributes } from "functions/src/interfaces/propertyInterface";
import { PropertyCRUDService } from "./property-crud.service";
import { PricingResult } from "functions/src/interfaces/PricingResultInterface";
import { RoomAvailabilities } from "functions/src/interfaces/RoomAvailabilities";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class WebhotelierService {
  private callableGetPropertyRooms: CallableFunction;
  private callableSetPropertyRoomRates: CallableFunction;
  private callableGetPropertyPerformancePerDay: CallableFunction;

  constructor(
    private fns: AngularFireFunctions,
    private propertyCRUDService: PropertyCRUDService,
    private notificationService: NotificationService
  ) {
    this.callableGetPropertyRooms = this.fns.httpsCallable(
      "getPropertyBatchRoomInfoAndRate"
    );
    this.callableGetPropertyPerformancePerDay = this.fns.httpsCallable(
      "getPropertyPerformancePerDay"
    );
    this.callableSetPropertyRoomRates = this.fns.httpsCallable("postRoomRates");
  }

  getPropertyRoomsWebHotelier(getPropertyRequest: {
    username: string;
    password: string;
    property: string;
  }): Promise<boolean> {
    return this.callableGetPropertyRooms(getPropertyRequest)
      .toPromise()
      .then((hotelierResponse: PropertyAttributes[]) => {
        console.log(
          "WebHotelier Response",
          hotelierResponse,
          JSON.stringify(hotelierResponse)
        );

        return this.propertyCRUDService
          .saveArrayOfProperties(
            hotelierResponse,
            getPropertyRequest.username,
            getPropertyRequest.password,
            getPropertyRequest.property
          )
          .then(() => {
            console.log("Imported data successfully");
            this.notificationService.setNotification(
              "Properties imported successfully"
            );
            return true;
          })
          .catch((error) => {
            console.log("Failed to import data to firestore", error);
            this.notificationService.setNotification(
              "Error importing properties. Please try again.",
              "OK",
              error
            );
            return false;
          });
      })
      .catch((error: any) => {
        console.log("WebHotelier error", error);
        return false;
      });
  }

  getPropertyPerformancePerDay(getPropertyRequest: {
    username: string;
    password: string;
    property: string;
    from: string;
    to: string;
  }): Promise<RoomAvailabilities> {
    return this.callableGetPropertyPerformancePerDay(getPropertyRequest)
      .toPromise()
      .then((hotelierResponse: RoomAvailabilities[]) => {
        return hotelierResponse;
      })
      .catch((error: any) => {
        console.log("WebHotelier error", error);
        return false;
      });
  }

  // runAlgorithmAndSaveResultsForWholeYear() {
  //   this.propertyCRUDService
  //     .getAllProperties()
  //     .pipe(
  //       first(
  //         (propertyAttributesNew: PropertyAttributes[]) =>
  //           propertyAttributesNew && propertyAttributesNew.length > 0
  //       )
  //     )
  //     .subscribe((propertyAttributesArray: PropertyAttributes[]) => {
  //       console.log("Got this:", propertyAttributesArray);
  //       propertyAttributesArray.forEach(
  //         (propertyAttributes: PropertyAttributes) => {
  //           const dateAndPriceParameters = {
  //             minimumPrice: null,
  //             maximumPrice: null,
  //             singleDay: false,
  //             weekResults: false,
  //             monthResults: false,
  //             startDate: "",
  //             endDate: "",
  //             oneDate: false,
  //             timestampOfRequest: Date.now(),
  //           };
  //           // this.formService.saveDateAndPriceParametersToFirebaseAndRunPricingAlgo()
  //         }
  //       );
  //     });
  // }

  setPropertyRoomRatesWebHotelier(setPropertyRateRequest: {
    username: string;
    password: string;
    extRateId: string;
    minNumberOfGuests: number;
    onePaxPriceAdjustment: number;
    extra_adult: number;
    extra_child: number;
    pricingResult: PricingResult;
  },
  notifyOnSuccess: boolean): Promise<boolean> {
    console.log(setPropertyRateRequest)
    return this.callableSetPropertyRoomRates(setPropertyRateRequest)
      .toPromise()
      .then((hotelierSyncResponse: { success: string; status: number }) => {
        console.log(
          "hotelierSyncResponse",
          hotelierSyncResponse,
          JSON.stringify(hotelierSyncResponse)
        );
        if(notifyOnSuccess)
        this.notificationService.setNotification("Synced successfully!");
        return true;
      })
      .catch((error: any) => {
        console.log("hotelier error", error);
        this.notificationService.setNotification(
          "Error syncing. Please try again.",
          "OK",
          error
        );
        return false;
      });
  }
}
