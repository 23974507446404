import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  faArrowDown,
  faArrowUp,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { monthNames } from "src/app/constants/monthNames";
import { PropertyTypes } from "src/app/constants/propertyTypes";
import {
  PropertyAttributes,
  PropertyAttributesClass,
} from "src/app/interfaces/property-attributes";
import { RevAppUser } from "src/app/interfaces/rev-app-user";
import { PropertyCRUDService } from "src/app/services/property-crud.service";
import { StateService } from "src/app/services/state.service";

@Component({
  selector: "revapp-property-item",
  templateUrl: "./property-item.component.html",
  styleUrls: ["./property-item.component.scss"],
})
export class PropertyItemComponent implements OnInit {
  @Input() editPropertyMode: boolean;
  private _propertyItem: PropertyAttributes = new PropertyAttributesClass();
  @Input() set propertyItem(obj: PropertyAttributes) {
    if (!obj) {
      return;
    }
    this._propertyItem = obj;
    this._init();
  }
  @Input() user: RevAppUser;

  monthNames = monthNames;
  faEllipsisV = faEllipsisV;
  faArrowDown = faArrowDown;
  faArrowUp = faArrowUp;
  propertyFeatures = "";
  autosyncFeature = "";
  autosyncTypeDescription = "";
  mainTitle: string;
  imageUrl: string;
  occupancy = 0;
  priceLowLimit = 0;
  priceHighLimit = 0;
  currentMonth: string = monthNames[+new Date().getMonth()];
  currentYear = new Date().getFullYear();
  defaultCurrency = "EUR";

  constructor(
    private router: Router,
    private _crudSrv: PropertyCRUDService,
    private _stateService: StateService
  ) {}

  ngOnInit(): void {
    this._init();
  }

  private _init(): void {
    const {
      maxNumberOfGuests,
      propertyType,
      numberOfRooms,
      floor,
      name,
      imageUrl,
      autosync,
    } = this._propertyItem;
    this.mainTitle = `${name}`;
    this.imageUrl = imageUrl
      ? imageUrl
      : "../../../assets/images/default_property.jpg";
    
    this.propertyFeatures = "";  
    if (maxNumberOfGuests !== undefined && maxNumberOfGuests !== null) {
      this.propertyFeatures += (this.propertyFeatures !== "") ? " | " : "";
      this.propertyFeatures += `${maxNumberOfGuests} guest(s)`;
    }
    if (propertyType !== undefined && propertyType !== null) {
      this.propertyFeatures += (this.propertyFeatures !== "") ? " | " : "";
      this.propertyFeatures += `${PropertyTypes[propertyType]}`;
    }
    if (numberOfRooms !== undefined && numberOfRooms !== null) {
      this.propertyFeatures += (this.propertyFeatures !== "") ? " | " : "";
      this.propertyFeatures += `${numberOfRooms} room(s)`;
    }
    if (floor !== undefined && floor !== null) {
      this.propertyFeatures += (this.propertyFeatures !== "") ? " | " : "";
      this.propertyFeatures += `${floor} floor`;
    }
    if(this.propertyFeatures === "") {
      this.propertyFeatures = "No summary"
    }

    if (autosync && autosync.status) {
      const lastSyncText = autosync && autosync.status && autosync.lastSyncDate ? `Last sync: ${autosync.lastSyncDate}` : "No sync yet" 
      const syncTypeText = autosync && autosync.status && autosync.type === "rolling" ? `Syncing for ${autosync.numberOfDays} day(s)` : `End date: ${autosync.endDate}` 
      this.autosyncTypeDescription = autosync && autosync.status ? ` -- [${autosync.type} period]` : ""
      this.autosyncFeature = `Activated: ${autosync.creationDate} \nStart date: ${autosync.startDate} \n${syncTypeText}\n${lastSyncText}`;
    } else {
      this.autosyncTypeDescription = "";
      this.autosyncFeature = "Inactive";
    }
  }

  public onButtonClick(): void {
    this._stateService.setActivePropertyAttributes(this._propertyItem);
    if (this.editPropertyMode) {
      this.router.navigate(["/form"]);
      return;
    }

    this.router.navigate(["/check-prices"]);
  }

  public onEditButtonClick(): void {
    this._crudSrv.editProperty(this._propertyItem);
  }

  public onCloneButtonClick(): void {
    this._crudSrv.duplicateProperty(this._propertyItem);
  }

  public onDeleteButtonClick(): void {
    this._crudSrv.deleteProperty(this._propertyItem.propertyId);
  }
}
