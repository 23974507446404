import { Component } from "@angular/core";

@Component({
  selector: "revapp-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent {
  constructor() {}
}
