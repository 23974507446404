import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { faCheckCircle, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'revapp-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComponent implements OnInit {

  faCheckCircle = faCheckCircle;
  faQuoteLeft = faQuoteLeft;
  constructor() { }

  ngOnInit(): void {
  }

}
