export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBbt5XpS597iauM-_b4tL8imNC7Lv_4MSQ",
    authDomain: "rev-app-dynamic.firebaseapp.com",
    databaseURL: "https://rev-app-dynamic.firebaseio.com",
    projectId: "rev-app-dynamic",
    storageBucket: "rev-app-dynamic.appspot.com",
    messagingSenderId: "262823444434",
    appId: "1:262823444434:web:713157eda8fdbeb9efd20b",
    measurementId: "G-Z15LEKDJ3C",
  },
};
