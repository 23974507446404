<div class="revapp-wrapper">
  <!-- navbar -->
  <revapp-navbar></revapp-navbar>

  <!--  main-content  -->
  <main id="main-content" class="content">
    <router-outlet></router-outlet>
  </main>

  <!-- revapp-footer -->
  <revapp-footer></revapp-footer>
</div>
<revapp-notification></revapp-notification>
