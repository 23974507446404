<form [formGroup]="newBlogPost">
  <mat-form-field appearance="fill">
    <mat-label
      >Date (optional - if you don't set it will be set
      automatically)</mat-label
    >
    <input matInput formControlName="date" type="text" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Title</mat-label>
    <input matInput formControlName="title" type="text" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Text</mat-label>
    <textarea matInput formControlName="paragraphs" type="text"></textarea>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Links (comma separated)</mat-label>
    <input matInput formControlName="links" type="text" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Image URL</mat-label>
    <input matInput formControlName="imageUrl" type="text" />
  </mat-form-field>
  <button mat-button color="primary" (click)="submit()">
    Create blog post
  </button>
</form>
