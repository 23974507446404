import { Injectable } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { StateService } from "./state.service";
// import { PricingResult } from "functions/src/interfaces/PricingResultInterface";
import { PythonPricingAlgoFunctionInput } from "functions/src/interfaces/PricingAlgoInput";

export interface PricingResult {
  [key: string]: { 
    price: string; 
    order: number; 
    trend?: number; 
    correction?: number; 
    advancedStrategies?: number;
  };
}

@Injectable({
  providedIn: "root",
})
export class PythonAlgoService {
  private callablePythonAlgo: CallableFunction;

  constructor(
    private fns: AngularFireFunctions,
    private stateService: StateService) {
    this.callablePythonAlgo = this.fns.httpsCallable(
      "rev_app_pricing_algo"
    );
  }

  callPythonCloudFunction(pythonFunctionInput: PythonPricingAlgoFunctionInput) {

    return this.callablePythonAlgo(pythonFunctionInput)
      .toPromise()
      .then((pricingResult: PricingResult) => {
        return pricingResult;
      })
      .catch((error) => {
        console.error("PYTHON ERROR", error);
        throw new Error(error);
      });
  }
}
