export interface PricingResult {
    [key: string]: { 
        price: number | string; 
        order: number; 
        trend?: number; 
        correction?: number;
        advancedStrategies?: number;
    }; 
}
export class PricingResultClass implements PricingResult {
    [key: string]: { 
        price: number | string; 
        order: number; 
        trend?: number; 
        correction?: number;
        advancedStrategies?: number;
    }; 
}