<article class="property-item">
  <!-- css background -->
  <div class="img-container" [style.background-image]="'url(' + imageUrl + ')'">
    <div class="property-dots-container" [matMenuTriggerFor]="propertyMenu">
      <fa-icon [icon]="faEllipsisV"></fa-icon>
    </div>
    <mat-menu #propertyMenu="matMenu" xPosition="before">
      <button mat-menu-item (click)="onEditButtonClick()">Edit</button>
      <button mat-menu-item (click)="onCloneButtonClick()">Clone</button>
      <button mat-menu-item (click)="onDeleteButtonClick()">Delete</button>
    </mat-menu>
    <div class="property-item-overlay"></div>
  </div>

  <div class="details-container">
    <div class="title-container">
      <h2>{{ mainTitle }}</h2>
    </div>
    <div class="property-features-container">
      {{ propertyFeatures }}
    </div>
    <div class="property-autosync-container">
      <p class="top">AutoSync {{ autosyncTypeDescription }}</p>
      <p class="description">
        {{ autosyncFeature }}
      </p>
    </div>
    <!-- TODO: tmp hidden -->
    <ng-container *ngIf="false">
      <div class="property-occupancy-container">
        <span class="percent-occupancy">{{ occupancy }}%</span
        ><span>occupancy</span>
      </div>
      <div class="property-prices-limits-container">
        <span class="property-price-icon down"
          ><fa-icon [icon]="faArrowDown"></fa-icon
        ></span>
        <span class="property-low-price price-limit">{{
          priceLowLimit | currency: defaultCurrency:"symbol":"1.2-2"
        }}</span>
        <span class="property-price-icon up"
          ><fa-icon [icon]="faArrowUp"></fa-icon
        ></span>
        <span class="property-high-price price-limit">{{
          priceHighLimit | currency: defaultCurrency:"symbol":"1.2-2"
        }}</span>
      </div>
    </ng-container>
    <div class="property-month-container">
      <span>Current month:</span
      ><span>{{ currentMonth }} {{ currentYear }}</span>
    </div>
    <div class="property-button-container text-center">
      <button mat-icon-button (click)="onButtonClick()">
        {{ editPropertyMode ? "EDIT PROPERTY" : "CHECK PRICES" }}
      </button>
    </div>
  </div>
</article>
