<div class="nav-container">
  <div class="wide-center-container">
    <div>
      <div class="menu-bar menu-desktop">
        <a mat-icon-button class="logo" [routerLink]="[routeDestination]">
          <div class="logo-image">
            <img
              src="../../../assets/images/RevApp-logo-draft.png"
              alt="RevApp logo"
            />
          </div>
        </a>

        <nav class="main-menu-list">
          <a [routerLink]="['/product']" [routerLinkActive]="['active']"
            >PRODUCT</a
          >
          <a [routerLink]="['/product']" [fragment]="'pricing'">PRICING</a>
          <a [routerLink]="['/team']" [routerLinkActive]="['active']">TEAM</a>
          <a [routerLink]="['/contact']" [routerLinkActive]="['active']"
            >CONTACT</a
          >
          <a [routerLink]="['/blog']" [routerLinkActive]="['active']">BLOG</a>
          <a
            *ngIf="(revAppUser$ | async)?.role === 'super_admin'"
            [routerLink]="['/admin']"
            [routerLinkActive]="['active']"
            >ADMIN</a
          >
        </nav>

        <ul class="secondary-menu-list">
          <li *ngIf="user" class="menu-list-item">
            <button mat-icon-button class="btn-blue" [routerLink]="['/home']">
              MY PROPERTIES
            </button>
          </li>
          <li class="menu-list-item" [routerLink]="['/help-center']">
            <div class="icon-wrapper">
              <fa-icon [icon]="faQuestion"></fa-icon>
            </div>
          </li>
          <li
            *ngIf="!user"
            class="menu-list-item"
            [matMenuTriggerFor]="userMenu"
          >
            <div class="icon-wrapper">
              <fa-icon [icon]="faUser"></fa-icon>
            </div>
            <mat-menu #userMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="onLoginSignUp('signup')">
                Sign up
              </button>
              <button mat-menu-item (click)="onLoginSignUp('login')">
                Log in
              </button>
            </mat-menu>
          </li>
          <li *ngIf="!user" class="menu-list-item">
            <button
              mat-icon-button
              class="btn-orange"
              [routerLink]="['/contact']"
            >
              ASK FOR DEMO
            </button>
          </li>
          <li
            *ngIf="user"
            class="menu-list-item"
            [matMenuTriggerFor]="loggedInUserMenu"
          >
            <div class="icon-wrapper">
              <fa-icon [icon]="faUser"></fa-icon>
            </div>
            <mat-menu #loggedInUserMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="logout()">Log out</button>
            </mat-menu>
          </li>
        </ul>
      </div>
      <div class="menu-bar menu-mobile">
        <div id="menuToggle">
          <input
            type="checkbox"
            id="isBurgerMenuOpen"
            name="isBurgerMenuOpen"
            (click)="burgerClicked()"
            [(ngModel)]="isBurgerMenuOpen"
          />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu-burger">
            <li>
              <a
                [routerLink]="['/product']"
                [routerLinkActive]="['active']"
                (click)="toggleBurgerMenu()"
                >PRODUCT</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/team']"
                [routerLinkActive]="['active']"
                (click)="toggleBurgerMenu()"
                >TEAM</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/contact']"
                [routerLinkActive]="['active']"
                (click)="toggleBurgerMenu()"
                >CONTACT</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/blog']"
                [routerLinkActive]="['active']"
                (click)="toggleBurgerMenu()"
                >BLOG</a
              >
            </li>
            <li *ngIf="(revAppUser$ | async)?.role === 'super_admin'">
              <a
                [routerLink]="['/admin']"
                [routerLinkActive]="['active']"
                (click)="toggleBurgerMenu()"
                >ADMIN</a
              >
            </li>
            <li>
              <ul class="secondary-menu-list">
                <li
                  *ngIf="!user"
                  class="menu-list-item"
                  [matMenuTriggerFor]="userMenu"
                >
                  <div class="icon-wrapper">
                    <fa-icon [icon]="faUser"></fa-icon>
                  </div>
                  <mat-menu #userMenu="matMenu" xPosition="before">
                    <button
                      mat-menu-item
                      (click)="toggleBurgerMenu(); onLoginSignUp('signup')"
                    >
                      Sign up
                    </button>
                    <button
                      mat-menu-item
                      (click)="toggleBurgerMenu(); onLoginSignUp('login')"
                    >
                      Log in
                    </button>
                  </mat-menu>
                </li>
                <li
                  *ngIf="user"
                  class="menu-list-item"
                  [matMenuTriggerFor]="loggedInUserMenu"
                >
                  <div class="icon-wrapper">
                    <fa-icon [icon]="faUser"></fa-icon>
                  </div>
                  <mat-menu #loggedInUserMenu="matMenu" xPosition="before">
                    <button
                      mat-menu-item
                      (click)="toggleBurgerMenu(); logout()"
                    >
                      Log out
                    </button>
                  </mat-menu>
                </li>
                <li
                  class="menu-list-item"
                  [routerLink]="['/help-center']"
                  (click)="toggleBurgerMenu()"
                >
                  <div class="icon-wrapper">
                    <fa-icon [icon]="faQuestion"></fa-icon>
                  </div>
                </li>
                <li [routerLink]="['/home']" (click)="toggleBurgerMenu()">
                  <button mat-icon-button class="btn-blue">
                    MY PROPERTIES
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <a
          mat-icon-button
          class="logo"
          [routerLink]="[routeDestination]"
          (click)="isBurgerMenuOpen && toggleBurgerMenu()"
        >
          <div class="logo-image">
            <img
              src="../../../assets/images/RevApp-logo-draft.png"
              alt="RevApp logo"
            />
          </div>
        </a>
        <div class="diplay-flex flex-1"></div>
        <button mat-icon-button class="btn-orange" [routerLink]="['/contact']">
          DEMO
        </button>
      </div>
    </div>
  </div>
</div>
