export interface autoSyncInt {
  status: boolean | null;
  type?: string | null;
  useSuggestedBasePrice?: boolean | null;
  basePrice?: number | null;
  minimumPrice?: number | null;
  maximumPrice?: number | null;
  numberOfDays?: number | null;
  creationDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  precision_digits?: number | null;
  lastSyncDate?: string | null;
  algoRiskStrategy?: number | null;
  tierPriceStrategyEnabled?: boolean | null;
  tierPriceStrategyPercentAbsolute?: boolean | null;
  tierPriceStrategyArray?: Array<number>;
}

export interface PropertyAttributes {
  propertyId?: string | null;
  name?: string | null;
  imageUrl?: string | null;
  useAsBaseRoomPriceAdjustmeType: boolean | null;
  useAsBaseRoomPropertyId: string[] | null;
  useAsBaseRoomExtRateId: string[] | null;
  useAsBaseRoomMinGuests: number[] | null;
  useAsBaseRoomPriceAdjustme: number[] | null;
  userId: string | null;
  timestamp: number | null;
  autosync: autoSyncInt | null;
  location: string | null;
  postalCode: string | null;
  locality: string | null;
  propertyType: number | null;
  maxNumberOfGuests: number | null;
  extRoomCode: string | null;
  extRateId: string | null;
  minNumberOfGuests: number | null;
  floor: number | null;
  numberOfRooms: number | null;
  totalScore: number | null;
  renovationYear: number | null;
  checkin247: boolean | null;
  reception247: boolean | null;
  aiportShuttle: boolean | null;
  bar: boolean | null;
  beachFront: boolean | null;
  cityCenter: boolean | null;
  elevator: boolean | null;
  gym: boolean | null;
  landmarks: boolean | null;
  luggageDropOff: boolean | null;
  parking: boolean | null;
  petsAllowed: boolean | null;
  pool: boolean | null;
  restaurant: boolean | null;
  tubeStation10mins: boolean | null;
  terraceRooftop: boolean | null;
  wellBeing: boolean | null;
  sharedBathroom: boolean | null;
  numberOfSharedBathrooms: number | null;
  numberOfSharedWCs: number | null;
  airconditioning: boolean | null;
  balcony: boolean | null;
  breakfast: boolean | null;
  hairDryer: boolean | null;
  iron: boolean | null;
  kitchen: boolean | null;
  shampoo: boolean | null;
  towels: boolean | null;
  tv: boolean | null;
  view: boolean | null;
  washingMachine: boolean | null;
  wifi: boolean | null;
  numberOfBathrooms: number | null; //??
  numberOfWCs: number | null; // ??
  totalBeds: number | null; // ??
  totalAvailableBeds: number | null; // ??
  sharedBedroom: boolean | null; // ??
  maxNumberOfGuestsInRoom: number | null;
  numberOfSharedBathroomsForRoom: number | null; //??
  numberOfSharedWCsForRoom: number | null; // ??
  numberOfBathroomsInRoom: number | null; //??
  numberOfWCsInRoom: number | null; //??
  totalRooms: number | null;
  totalAvailableRooms: number | null; //??
  propertyName: string | null;
  propertyUser: string | null;
  propertyPass: string | null;
  onePaxPriceAdjustment: number | null;
  extraAdult: number | null;
  extraChild: number | null;
  neighborhood: string | null;
  directCompetitorsList: string[] | null;
  nearbyNeighborhoodsList: string[] | null;
}

export class PropertyAttributesClass implements PropertyAttributes {
  propertyId?: string | null;
  name?: string | null;
  imageUrl?: string | null;
  useAsBaseRoomPriceAdjustmeType: boolean | null;
  useAsBaseRoomPropertyId: string[] | null;
  useAsBaseRoomExtRateId: string[] | null;
  useAsBaseRoomMinGuests: number[] | null;
  useAsBaseRoomPriceAdjustme: number[] | null;
  userId: string | null;
  timestamp: number | null;
  autosync: autoSyncInt | null;
  location: string | null;
  postalCode: string | null;
  locality: string | null;
  propertyType: number | null;
  maxNumberOfGuests: number | null;
  extRoomCode: string | null;
  extRateId: string | null;
  minNumberOfGuests: number | null;
  floor: number | null;
  numberOfRooms: number | null;
  totalScore: number | null;
  renovationYear: number | null;
  checkin247: boolean | null;
  reception247: boolean | null;
  aiportShuttle: boolean | null;
  bar: boolean | null;
  beachFront: boolean | null;
  cityCenter: boolean | null;
  elevator: boolean | null;
  gym: boolean | null;
  landmarks: boolean | null;
  luggageDropOff: boolean | null;
  parking: boolean | null;
  petsAllowed: boolean | null;
  pool: boolean | null;
  restaurant: boolean | null;
  tubeStation10mins: boolean | null;
  terraceRooftop: boolean | null;
  wellBeing: boolean | null;
  sharedBathroom: boolean | null;
  numberOfSharedBathrooms: number | null;
  numberOfSharedWCs: number | null;
  airconditioning: boolean | null;
  balcony: boolean | null;
  breakfast: boolean | null;
  hairDryer: boolean | null;
  iron: boolean | null;
  kitchen: boolean | null;
  shampoo: boolean | null;
  towels: boolean | null;
  tv: boolean | null;
  view: boolean | null;
  washingMachine: boolean | null;
  wifi: boolean | null;
  numberOfBathrooms: number | null; //??
  numberOfWCs: number | null; // ??
  totalBeds: number | null; // ??
  totalAvailableBeds: number | null; // ??
  sharedBedroom: boolean | null; // ??
  maxNumberOfGuestsInRoom: number | null;
  numberOfSharedBathroomsForRoom: number | null; //??
  numberOfSharedWCsForRoom: number | null; // ??
  numberOfBathroomsInRoom: number | null; //??
  numberOfWCsInRoom: number | null; //??
  totalRooms: number | null;
  totalAvailableRooms: number | null; //??
  propertyName: string | null;
  propertyUser: string | null;
  propertyPass: string | null;
  onePaxPriceAdjustment: number | null;
  extraAdult: number | null;
  extraChild: number | null;
  neighborhood: string | null;
  directCompetitorsList: string[] | null;
  nearbyNeighborhoodsList: string[] | null;
}
