<h1>Welcome to Admin page</h1>
<nav>
  <button
    mat-flat-button
    color="accent"
    role="link"
    [routerLink]="['users']"
    [routerLinkActive]="['active']"
  >
    Users
  </button>
  |
  <button
    mat-flat-button
    color="accent"
    role="link"
    [routerLink]="['user-property']"
    [routerLinkActive]="['active']"
  >
    Choose user property
  </button>
  |
  <button
    mat-flat-button
    color="accent"
    role="link"
    [routerLink]="['new-blog-post']"
    [routerLinkActive]="['active']"
  >
    New blog post
  </button>
</nav>
<div class="outlet">
  <router-outlet></router-outlet>
</div>
