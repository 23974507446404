<h1 mat-dialog-title>User Mode Advanced Menu</h1>
<div mat-dialog-content>
  <p class="info-additional-price">Select the number of tiers, type and value of increase for each one compared with the first tier</p>
  
  <form [formGroup]="priceAdjustmentTierForm">
    <mat-form-field appearance="fill">
      <mat-label>Adjustment type</mat-label>
      <mat-select required formControlName="adjustmentType">
        <mat-option value="0" >Percentage above market</mat-option>
        <mat-option value="1" >Absolute values</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Number of tiers</mat-label>
      <mat-select required formControlName="numberOfTiers" (selectionChange)="onPropertySelectionChange($event)">
        <mat-option value="2" >2 tiers</mat-option>
        <mat-option value="3" >3 tiers</mat-option>
        <mat-option value="4" >4 tiers</mat-option>
        <mat-option value="5" >5 tiers</mat-option>
        <mat-option value="6" >6 tiers</mat-option>
        <mat-option value="7" >7 tiers</mat-option>
        <mat-option value="8" >8 tiers</mat-option>
        <mat-option value="9" >9 tiers</mat-option>
        <mat-option value="10" >10 tiers</mat-option>
      </mat-select>
    </mat-form-field>
    <p class="mat-caption">Price Adjustment Values</p>

    <mat-form-field appearance="fill" style="flex-direction: row;"
      *ngFor="let tier of selectedTierValues; index as i ">
      <mat-label>Occupancy {{ ( i * ( 100 / selectedTierValues.length ) ).toFixed(2) }}% - {{ ( ( i + 1 ) * ( 100 / selectedTierValues.length ) ).toFixed(2) }}%</mat-label>
      <input *ngIf="priceAdjustmentTierForm.get('adjustmentType').value==0" matInput formControlName="priceAdjustmentTier_{{ i }}" type="number" min="0" max="100" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.1" >
      <span *ngIf="priceAdjustmentTierForm.get('adjustmentType').value==0" matSuffix>%</span>
      <input *ngIf="priceAdjustmentTierForm.get('adjustmentType').value==1" matInput formControlName="priceAdjustmentTier_{{ i }}" type="number" step="0.1" >
      <span *ngIf="priceAdjustmentTierForm.get('adjustmentType').value==1" matSuffix>{{currencySymbol}}</span>      
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="dialogRef.close({ data: {status:'cancel'} })">Cancel</button>
  <button mat-button color="primary" (click)="submit()" cdkFocusInitial> Ok </button>
</div>