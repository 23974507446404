import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

@Component({
  selector: "revapp-chips-autocomplete",
  templateUrl: "./chips-autocomplete.component.html",
  styleUrls: ["./chips-autocomplete.component.scss"],
})
export class ChipsAutocompleteComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  constructor() {}

  @Input() selectedItemsArray: string[] ;

  @Input() itemsFetchedList: string[];
  @Input() name: string;
  @Output() arrayUpdated: EventEmitter<string[]> = new EventEmitter();

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((name) =>
        name
          ? this._filter(name)
          : this.itemsFetchedList && this.itemsFetchedList.slice()
      )
    );
  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();

    return this.itemsFetchedList.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  // This is not used because addAutocomplete takes over
  add(event: MatChipInputEvent): void {
    const input = event.input;
    // const value = event.value;
    // if ((value || "").trim()) {
    //   this.selectedItemsArray.push(value.trim());
    // }
    if (input) {
      input.value = "";
    }
    // this.arrayUpdated.emit(this.selectedItemsArray);
  }

  addAutocomplete(event: MatAutocompleteSelectedEvent) {
    this.selectedItemsArray.push(event.option.value);
    this.arrayUpdated.emit(this.selectedItemsArray);
  }

  remove(area: string): void {
    const index = this.selectedItemsArray.indexOf(area);
    if (index >= 0) {
      this.selectedItemsArray.splice(index, 1);
    }
    this.arrayUpdated.emit(this.selectedItemsArray);
  }
}
