import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { BlogPost } from "src/app/interfaces/blog-post";
import { BlogCrudService } from "src/app/services/blog-crud.service";

@Component({
  selector: "revapp-post",
  templateUrl: "./post.component.html",
  styleUrls: ["./post.component.scss"],
})
export class PostComponent implements OnInit {
  post: Observable<BlogPost>;
  constructor(
    private blogCrudService: BlogCrudService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params["id"]) {
      this.post = this.blogCrudService.getSingleBlogPost(
        this.activatedRoute.snapshot.params["id"]
      );

      this.post.subscribe((res) => {
        console.log(res);
      });
    }
  }

  navigateOutside(link: string) {
    window.location.href = link;
  }
}
