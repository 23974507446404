<section
  class="section-area section-area-1 wide-center-container pt-70 pb-200 min-h-800"
  *ngIf="params$ | async as params"
>
  <ng-container
    *ngIf="params['propertyId'] && params['userId']; else noPropertyId"
  >
    <div class="inner-area inner-area-2">
      <div class="box box-1 mr-25">
        <div class="form-container">
          <form>
            <div class="form-row">
              <div class="form-col title-container">
                <h1 class="font-montserrat-extrabold main-title">
                  Enter competitors and neighboring areas
                </h1>
              </div>
            </div>

            <div class="form-row mb-30">
              <div class="form-col form-col-4">
                <revapp-chips-autocomplete
                  [itemsFetchedList]="competitorsFetchedList | async"
                  [name]="'Competitors'"
                  [selectedItemsArray] = "competitorsArray"
                  (arrayUpdated)="updateArray($event, 'comp')"
                ></revapp-chips-autocomplete>
                <!-- <mat-form-field appearance="fill">
                  <mat-label>Competitors</mat-label>
                  <mat-chip-list
                    #chipListComp
                    aria-label="Competitors selection"
                  >
                    <mat-chip
                      *ngFor="let competitor of competitorsArray"
                      [selectable]="true"
                      [removable]="true"
                      (removed)="remove(competitor, 'comp')"
                    >
                      {{ competitor }}
                      <mat-icon matChipRemove *ngIf="removable"
                        >cancel</mat-icon
                      >
                    </mat-chip>
                    <input
                      [formControl]="competitorControl"
                      [matAutocomplete]="autoCompetitors"
                      placeholder="New competitor"
                      [matChipInputFor]="chipListComp"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="add($event, 'comp')"
                    />
                    <mat-autocomplete #autoCompetitors="matAutocomplete">
                      <mat-option
                        *ngFor="let option of competitorsFetchedList | async"
                        [value]="option"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-chip-list>
                </mat-form-field> -->
              </div>

              <div class="form-col form-col-4">
                <revapp-chips-autocomplete
                  [itemsFetchedList]="neighboringAreasFetchedList | async"
                  [name]="'Neighboring areas'"
                  [selectedItemsArray] = "neighboringAreasArray"
                  (arrayUpdated)="updateArray($event, 'neig')"
                ></revapp-chips-autocomplete>
                <!-- <mat-form-field appearance="fill">
                  <mat-label>Neighboring areas</mat-label>
                  <mat-chip-list
                    #chipListArea
                    aria-label="Neighboring area selection"
                  >
                    <mat-chip
                      *ngFor="let area of neighboringAreasArray"
                      [selectable]="true"
                      [removable]="true"
                      (removed)="remove(area, 'area')"
                    >
                      {{ area }}
                      <mat-icon matChipRemove *ngIf="removable"
                        >cancel</mat-icon
                      >
                    </mat-chip>
                    <input
                      [formControl]="neighborsControl"
                      [matAutocomplete]="autoNeighbors"
                      placeholder="New area"
                      [matChipInputFor]="chipListArea"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="add($event, 'area')"
                    />
                    <mat-autocomplete #autoNeighbors="matAutocomplete">
                      <mat-option
                        *ngFor="
                          let option of neighboringAreasFetchedList | async
                        "
                        [value]="option"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-chip-list>
                </mat-form-field> -->
              </div>
            </div>

            <div class="form-row">
              <div class="form-col">
                <button
                  class="blue-button"
                  mat-icon-button
                  (click)="onFormSubmit(params['propertyId'], params['userId'])"
                >
                  Submit and continue
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
</section>
<ng-template #noPropertyId>
  <p>There's no property Id. Please go back and select a property.</p>
</ng-template>
<div *ngIf="workInTheBackground">
  <p>Please wait, we're working on your request!</p>
  <mat-spinner diameter="48"></mat-spinner>
</div>
<div *ngIf="errorHappened">
  <p>Something unexpected happened. Please try again!</p>
</div>
